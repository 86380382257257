export const abattementDataIR = {
  6: '0.06',
  7: '0.12',
  8: '0.18',
  9: '0.24',
  10: '0.30',
  11: '0.36',
  12: '0.42',
  13: '0.48',
  14: '0.54',
  15: '0.60',
  16: '0.66',
  17: '0.72',
  18: '0.78',
  19: '0.84',
  20: '0.90',
  21: '0.96',
  22: '1',
  23: '1',
  24: '1',
  25: '1',
  26: '1',
  27: '1',
  28: '1',
  29: '1',
}

export const abattementDataPS = {
  6: '0.0165',
  7: '0.033',
  8: '0.0495',
  9: '0.066',
  10: '0.0825',
  11: '0.0990',
  12: '0.1155',
  13: '0.1320',
  14: '0.1485',
  15: '0.1650',
  16: '0.1815',
  17: '0.1980',
  18: '0.2145',
  19: '0.2310',
  20: '0.2475',
  21: '0.2640',
  22: '0.28',
  23: '0.37',
  24: '0.46',
  25: '0.55',
  26: '0.64',
  27: '0.73',
  28: '0.82',
  29: '0.91',
}

export const taxePV = (pv) => {
  let tax
  if (pv <= 50000) {
    tax = 0
  } else if (pv > 260000) {
    tax = pv * 0.06
  } else if (pv >= 50001 && pv <= 60000) {
    tax = pv * 0.02 - ((60000 - pv) / 20)
  } else if (pv >= 60001 && pv <= 100000) {
    tax = pv * 0.02
  } else if (pv >= 100001 && pv <= 110000) {
    tax = pv * 0.03 - ((110000 - pv) / 10)
  } else if (pv >= 110001 && pv <= 150000) {
    tax = pv * 0.03
  } else if (pv >= 150001 && pv <= 160000) {
    tax = pv * 0.04 - ((160000 - pv) * 0.15)
  } else if (pv >= 160001 && pv <= 200000) {
    tax = pv * 0.04
  } else if (pv >= 200001 && pv <= 210000) {
    tax = pv * 0.05 - ((210000 - pv) * 0.2)
  } else if (pv >= 210001 && pv <= 250000) {
    tax = pv * 0.05
  } else if (pv >= 250001 && pv <= 260000) {
    tax = pv * 0.06 - ((260000 - pv) * 0.25)
  }

  return tax
}

export default abattementDataIR
