import React from 'react'
import { Step } from '../inscription/step'

export const AppartmentRejection = ({ acceptAppartment }) => (
  <div
    className="container-answers fadeIn"
    id="form-apartment-rejection"
  >
    <h1>Vous avez un appartement ?</h1>
    <p>
      Ce type de bien ne permet pas la réalisation d'une opération de promotion immobilière.
    </p>
    <p>
      Toutefois, si vous pensez pouvoir mobiliser l'ensemble de votre copropriété dans la perspective d'une vente globale, alors nous vous invitons à poursuivre votre estimation.
    </p>
    <a onClick={() => acceptAppartment()}>Estimez votre bien</a>
  </div>
)

export const RequestRejection = () => (
  <div
    className="container-answers"
    id="form-api-error"
  >
    <h1>Nous rencontrons un problème technique !</h1>
    <p>
      Nous faisons tout notre possible pour réparer cet incident.
    </p>
    <p>
      Notre équipe a bien reçu vos coordonnées ainsi que les caractéristiques de votre bien. Nous revenons vers vous dans les plus brefs délais pour vous fournir l'estimation de votre bien.
    </p>
    <p><strong>L'équipe City&You</strong></p>
  </div>
)
