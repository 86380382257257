import React, { useState } from 'react'
import moment from 'moment'
import MapboxMap, { Marker } from 'react-mapbox-wrapper'
import { adjustSuperposition,
  CircleProgram,
  camelize,
  defineBounds,
  formatAddress,
  SerialDataTable,
  WarningMissingData
} from './Shared'

const NearPrograms = ({ mapboxToken, mapTypeLight, plot, allPrograms }) => {
  moment.locale('fr')
  const [map, setMap] = useState(null)
  let indexTable = 1

  const onMapLoad = (mapInstance) => {
    setMap(mapInstance)
    mapInstance.addSource('city', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: plot.geometry_to_geojson,
      }
    })

    mapInstance.addLayer({
      id: 'city-outline',
      type: 'line',
      source: 'city',
      layout: {},
      paint: {
        'line-color': '#d95d57',
        'line-width': 2,
      }
    })
  }

  const ProgramMarker = ({ status, index, sqmPrice }) => (
    <div className="d-flex">
      <div className={`marker-index ${status === 'vefa' ? 'marker-index-vefa' : status === 'available' ? 'marker-index-program-available' : 'marker-index-program-sold'}`}>
        {map && index}
      </div>
      <div className="marker-content">
        <span className="bold">{sqmPrice.toLocaleString('fr-FR')}</span> €/m²
      </div>
    </div>
  )

  const anyProgramOrVefa = allPrograms.programs.length > 0 || allPrograms.vefa_programs.length > 0

  const formatProgramsForMarker = () => {
    const markers = []
    if (!anyProgramOrVefa) {
      const fakeMarker = {
        lat: plot.coordinates.lat,
        lon: plot.coordinates.lng,
      }
      const fakeMarkerEast = {
        lat: plot.coordinates.lat,
        lon: plot.coordinates.lng + 0.001,
      }
      const fakeMarkerWest = {
        lat: plot.coordinates.lat,
        lon: plot.coordinates.lng - 0.001,
      }

      markers.push(fakeMarker)
      markers.push(fakeMarkerEast)
      markers.push(fakeMarkerWest)
      return markers
    }

    if (allPrograms.programs.length > 0) {
      allPrograms.programs.map((program, index) => {
        const obj = {
          lat: program.lat,
          lon: program.lon,
          status: program.progress_status === 99 ? 'sold' : 'available',
          indexProgram: index + 1,
          sqmPrice: program.sqm_price
        }
        markers.push(obj)
      })
    }

    if (allPrograms.vefa_programs.length > 0) {
      allPrograms.vefa_programs.map((vefaProgram, index) => {
        const obj = {
          lat: vefaProgram.lat,
          lon: vefaProgram.lon,
          status: 'vefa',
          indexProgram: allPrograms.programs.length + index + 1,
          sqmPrice: vefaProgram.mean_sqm_price_original
        }
        markers.push(obj)
      })
    }
    return markers
  }

  const formattedMarkers = formatProgramsForMarker()
  const bounds = defineBounds(formattedMarkers)
  const markers = adjustSuperposition(formattedMarkers, bounds)
  const newBounds = defineBounds(markers)
  const noNearPrograms = (allPrograms.programs.length === 0 && allPrograms.vefa_programs.length === 0)

  return (
    <div className="align-start pdf-content-inside">
      <div id="near-programs-map" className="mb-12">
        <MapboxMap
          accessToken={mapboxToken}
          coordinates={plot.coordinates}
          bounds={newBounds}
          fitBoundsOptions={{ padding: { top: 40, right: 100, bottom: 40, left: 40 } }}
          onLoad={(e) => onMapLoad(e)}
          mapboxStyle={`mapbox://styles/${mapTypeLight}`}
          preserveDrawingBuffer
        >
          { map && anyProgramOrVefa && markers.map((marker) => (
            <Marker
              key={marker.lat}
              map={map}
              coordinates={{ lat: marker.lat, lng: marker.lon }}
              anchor="left"
            >
              <ProgramMarker
                index={marker.indexProgram}
                status={marker.status}
                sqmPrice={marker.sqmPrice}
              />
            </Marker>
          ))}
        </MapboxMap>
      </div>
      <div className="d-flex gap-12 programs-legend">
        <div><span className="colored-bullet bg-candy-secondary"/>En cours de commercialisation</div>
        <div><span className="colored-bullet bg-light-red-legend"/>Commercialisation terminée</div>
        <div><span className="colored-bullet bg-candy-primary"/>Vente actée</div>
      </div>
      {
        !noNearPrograms ? (
          <SerialDataTable>
            <thead>
              <tr>
                <th className="w-5" />
                <th className="w-15">Nom</th>
                <th className="w-15">Opérateur</th>
                <th className="w-25">Adresse</th>
                <th className="w-10">Stock/Nb lots</th>
                <th className="w-10">Prix</th>
                <th className="w-10">Début com.</th>
                <th className="w-10">Fin com.</th>
              </tr>
            </thead>
            <tbody>
              {allPrograms.programs.map((program) => (
                <tr key={program.id} className="height-40px red-background">
                  <td><CircleProgram className={`index ${program.progress_status === 99 ? 'program-finished' : 'program-ongoing'}`}>{indexTable++}</CircleProgram></td>
                  <td><span className="bold">{program.name}</span></td>
                  <td className="td-container"><div className="multi-line-ellipsis">{program.owner_name}</div></td>
                  <td className="td-container td-address-near-programs">{formatAddress(program.full_address)}</td>
                  <td className="pl-5"><strong>{program.stats_by_typos.available?.moy?.nbr || 0}</strong>/{program.stats_by_typos.all.moy.nbr}</td>
                  <td><span className="bold">{program.sqm_price.toLocaleString('fr-FR')}</span> €/m²</td>
                  <td>{program.first_appear ? camelize(moment(program.first_appear).format('MMM YYYY')) : ''}</td>
                  <td>{program.progress_status === 99 ? (program.last_appear ? camelize(moment(program.last_appear).format('MMM YYYY')) : '') : 'En cours'}</td>
                </tr>
              ))}
              {allPrograms.vefa_programs.map((vefaProgram) => (
                <tr key={vefaProgram.id} className="height-40px blue-background">
                  <td className="w-5"><CircleProgram className="index vefa-programs">{indexTable++}</CircleProgram></td>
                  <td><span className="bold"> - </span></td>
                  <td> - </td>
                  <td className="td-container td-address-near-programs">{formatAddress(vefaProgram.full_address)}</td>
                  <td className="pl-5"><strong>0/</strong>{vefaProgram.stats_by_typos.all.moy.nbr}</td>
                  <td><span className="bold">{vefaProgram.mean_sqm_price_original.toLocaleString('fr-FR')}</span> €/m²</td>
                  <td>{camelize(moment(vefaProgram.first_appear).format('MMM YYYY'))}</td>
                  <td>{camelize(moment(vefaProgram.last_appear).format('MMM YYYY'))}</td>
                </tr>
              ))}
            </tbody>
          </SerialDataTable>
        ) : (
          <WarningMissingData>
            <p>
              <b>Nous n'avons pas trouvé de programme neuf</b><br/>
              dans un rayon de 5 km.
            </p>
          </WarningMissingData>
        )
      }
    </div>
  )
}

export default NearPrograms
