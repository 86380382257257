import React from 'react'
import { BaseTable, renderTr, PlotMap } from './Shared'

const ReportSimulation = ({
  mapboxToken,
  mapTypeSatellite,
  multiPlots,
  plot,
  simulations
}) => {
  const baseUrl = `https://api.mapbox.com/styles/v1/${mapTypeSatellite}/static`
  const width = 580
  const height = 420
  const location = `${plot.coordinates.lng},${plot.coordinates.lat},${plot.zoom}`
  const geometryToGeojson = {
    type: 'Feature',
    geometry: plot.geometry_to_geojson,
    properties: {
      'stroke': '#d95d57',
      'stroke-width': 2,
      'fill-opacity': 0
    }
  }
  const geojsonString = JSON.stringify(geometryToGeojson)
  const encodedGeoJSON = `geojson(${encodeURIComponent(geojsonString)})`
  const mapboxStaticImageUrl = `${baseUrl}/${encodedGeoJSON}/${location}/${width}x${height}@2x?access_token=${mapboxToken}`

  const uzFloors = plot.zone_info.map(uz => Math.floor(uz.heightMax / 3 || 0))
  const maxFloors = plot.zone_info.length > 0 ? Math.max(...uzFloors) : 0
  const floorsRange = maxFloors > 0 ? [...Array(maxFloors + 1).keys()] : []
  floorsRange.shift()
  const withS = () => (multiPlots ? 's' : '')

  const renderElement = (x, simul) => {
    let element = null
    if (simul.plu_height_max) {
      const floors = Math.floor(simul.plu_height_max / 3)
      if (maxFloors === floors) {
        element = x < floors ? '100' : `${simul.last_level_ratio}`
      } else if (maxFloors > floors) {
        if (x < floors) {
          element = '100'
        } else if (x === floors) {
          element = `${simul.last_level_ratio}`
        }
      }
    }
    return element
  }

  return (
    <div className="d-flex flex-column pdf-content-inside">
      <div className="page-2-col align-start">
        <div>
          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Emprise et gabarit</th></tr>
            </thead>
            <tbody>
              {renderTr('Zones :', plot.zone_info, 'name', '')}
              {renderTr(`Nbre de parcelle${withS()} :`, simulations, 'land_registry_id_short', '', (vl) => vl.length)}
              {renderTr(`Surface parcelle${withS()} :`, simulations, 'area', 'm²')}
              <tr className="empty" />
              {renderTr('Bande constructible :', plot.zone_info, 'bc', 'm')}
              {renderTr('% ES autorisée BC principale:', simulations, 'plu_floor_max_1', '%', (vl) => vl * 100)}
              {renderTr('% ES autorisée BC secondaire :', simulations, 'plu_floor_max_2', '%', (vl) => vl * 100)}
              {renderTr('ES max autorisée :', simulations, 'sqm_emprise_max', 'm²')}
              <tr className="empty" />
              {renderTr("Longueur d'alignement :", simulations, 'alignement', 'm', (vl) => Math.round(vl))}
              <tr className="empty" />
              {renderTr('Nbre de niveaux autorisé au PLU :', simulations, 'plu_height_max', '', (vl) => Math.floor(vl / 3))}
              { floorsRange.map((x, index) => (
                <tr key={`floors-distribution-${index}`}>
                  <td>{ x === 1 ? 'RDC' : `R+${x - 1}` }</td>
                  { simulations.map((simul, i) => <td key={`${index}-${i}`}>{renderElement(x, simul) || '-' }&nbsp;%</td>)}
                </tr>
              ))}
              <tr className="empty" />
              {renderTr('Surface brute :', simulations, 'raw_area', 'm²', (vl) => Math.round(vl).toLocaleString('fr-FR'))}
            </tbody>
          </BaseTable>

          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Surfaces calculées</th></tr>
            </thead>
            {plot.zone_info.length > 2 && (
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '17%' }} />
                <col style={{ width: '17%' }} />
                <col style={{ width: '17%' }} />
              </colgroup>
            )}
            {plot.zone_info.length === 2 && (
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
              </colgroup>
            )}
            <tbody>
              <tr>
                <td>Ratio SDP :</td>
                <td>0.778</td>
              </tr>
              {renderTr('Total SDP :', simulations, 'spt', 'm²', (vl) => Math.round(vl).toLocaleString('fr-FR'))}
              <tr className="empty" />
              <tr>
                <td>Ratio surface habitable :</td>
                <td>0.93</td>
              </tr>
              {renderTr('Total surface habitable :', simulations, 'spt', 'm²', (vl) => Math.round(vl * 0.93).toLocaleString('fr-FR'))}
              <tr className="empty" />
              <tr>
                <td>Taille moy. d'un logt (Shab) :</td>
                <td>60 <span className="no-bold">m²</span></td>
              </tr>
              <tr>
                <td>Nbre de logts :</td>
                { simulations.map((simul, index) => (
                  simul.spt ? <td key={`${simul.spt}-3`}>{Math.floor((simul.spt * 0.93) / simul.mean_living_area)}</td> : <td className="no-bold">-</td>
                ))}
              </tr>
            </tbody>
          </BaseTable>

          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Sous-sol</th></tr>
            </thead>
            {plot.zone_info.length > 2 && (
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
              </colgroup>
            )}
            {plot.zone_info.length === 2 && (
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
              </colgroup>
            )}
            <tbody>
              <tr>
                <td>Nbre moy. de places exigées/logt :</td>
                <td>1 <span className="no-bold">pl./lgt.</span></td>
              </tr>
              <tr>
                <td>Nbre moy. de places exigées:</td>
                { simulations.map((simul) => (
                  simul.spt ? <td key={`${simul.mean_parking_lots_per_housing}-2`}>{Math.ceil(simul.mean_parking_lots_per_housing * ((simul.spt * 0.93) / simul.mean_living_area))}</td> : <td className="no-bold">-</td>
                ))}
              </tr>
              <tr className="empty" />
              {renderTr('% de pleine terre :', simulations, 'ept', '', (vl) => Math.round(vl))}
              {renderTr('Surface de pleine terre :', simulations, 'ept_area', 'm²', (vl) => Math.round(vl))}
              <tr className="empty" />
              <tr>
                <td>Surface disponible / niveau :</td>
                { simulations.map((simul) => (
                  simul.ept === null ? <td className="no-bold">-</td> : <td key={simul.area}>{simul.area - parseInt((simul.ept / 100) * simul.area, 10)}<span> m²</span></td>
                ))}
              </tr>
              <tr>
                <td>Nbre place par niveau :</td>
                { simulations.map((simul) => (
                  simul.ept === null ? <td className="no-bold">-</td> : <td key={simul.area}>{Math.round((simul.area - parseInt((simul.ept / 100) * simul.area, 10)) / simul.parking_lot_area)}</td>
                ))}
              </tr>
            </tbody>
          </BaseTable>
        </div>

        <PlotMap url={mapboxStaticImageUrl} className="mb-12" />
      </div>
    </div>
  )
}

export default ReportSimulation
