import React, { useEffect } from 'react'
import { useForm } from '../../../context/form.context'
import { useStep } from '../../../context/step.context'
import { TitleSimpleForm } from '../../SimpleForm/TitleSimpleForm'
import { CardForm } from '../../FormOnboarding/CardForm'
import { Errors } from '../../SimpleForm/error'

export const AnswerProperty = ({
  data, category, select, index
}) => {
  const { errors } = useForm()
  const { incrementWithErrors, increment } = useStep()

  const turnLogoNormal = () => {
    if (document.getElementsByClassName('logo-navbar').length > 0) {
      document.getElementsByClassName('logo-navbar')[0].classList.remove('logo-navbar--reversed')
    }
  }

  useEffect(() => turnLogoNormal(), [])

  return (
    <div
      className="container-answers fadeIn"
      data-testid="answerProperty"
      id="form-step-0-2"
    >
      <TitleSimpleForm
        itemIndex={index}
        title="Quelle est la nature de votre bien ?"
      />
      <div className="ctn-card-list-form">
        {data.dataForm.properties.map((e, i) => (
          <CardForm
            selected={category === i}
            stateValue="category"
            select={() => {
              select(i === category ? null : i, 'category')
              setTimeout(() => increment(), 500)
            }}
            img={e.img}
            imgSelected={e.imgSelected}
            title={e.title}
            index={i}
            key={i}
          />
        ))}
      </div>
      {errors.category.includes('kindOfProperties')
      && (
        <Errors
          message="Ce champ est requis."
        />
      )}
    </div>
  )
}
