export const data = [
  {
    title: 'Comment City&You estime la valeur de mon bien ?',
    answer: 'Dans un premier temps, nous étudions à l’aide d’outils informatiques et de partenaires architectes ce qu’il est possible de construire sur votre terrain au regard des documents d’urbanisme. Ensuite, nous identifions les prix de vente sur le marché du neuf sur votre secteur.<br><br> À partir de ces analyses, nous réalisons le bilan financier qui détermine <strong>le prix d’achat que peut vous proposer un promoteur immobilier</strong>. Nous comparons ce prix à la valeur actuelle de votre bien afin de calculer la plus-value possible.'
  },
  {
    title: "Dois-je m'engager avec City&You pour connaître l'estimation de mon bien et de la plus-value potentielle ?",
    answer: "La communication de l'estimation de la valeur de votre bien est faite sans engagement. Par simple inscription, vous obtenez une estimation gratuite en ligne.<br><br>Nous étudions ensuite le potentiel de votre bien et prenons contact avec vous pour comprendre votre projet de vente. Lors de notre première rencontre nous vous présentons nos études et nos estimations.<br><br>Suite à nos premiers échanges, nous nous engageons ensemble à travers un contrat de mandat de vente qui nous permet d'organiser pour vous la consultation de promoteurs pertinents au regard du projet possible sur votre terrain."
  },
  {
    title: 'Comment City&You sélectionne les promoteurs immobiliers ?',
    answer: 'Nous animons un réseau de promoteurs immobiliers dont nous connaissons <strong>le sérieux et l’engagement</strong>.<br><br>En premier lieu, l’opérateur est choisi sur sa capacité à réaliser le type (bureau, logement, commerce, activité ou mixte !) et la taille d’opération envisagés : <strong>chaque opérateur immobilier a ses spécificités de marché, d’expertise et de métier</strong>. Certains opérateurs s’adossent à une foncière qui leur permet d’investir dans tout ou partie du projet et cela peut être un vrai plus pour sécuriser la sortie de l’opération. D’autres sont par exemple plus spécialisés dans la rénovation de bâtiments existants.<br><br><strong>La connaissance du contexte local</strong> est également un facteur important dans le choix de l’opérateur : vendre un logement nécessite notamment de bien connaître le marché local.<br><br>Enfin, la solidité financière de l’opérateur est une vigilance supplémentaire dans les critères de choix.'
  },
  {
    title: 'Comment City&You sélectionne la meilleure offre parmi celles proposées ?',
    answer: "Lorsque nous recevons les offres des opérateurs immobiliers, nous réalisons une analyse que nous vous remettons. <strong>C'est alors vous qui choissisez la meilleure offre</strong> : celle qui correspond le mieux à vos critères en matière de prix, de délais de vente, de clauses et conditions suspensives.<br><br>D’une manière générale, <strong>plus vous êtes pressé de vendre plus vous reportez le risque du projet sur l’opérateur immobilier</strong> et moins celui-ci est susceptible de vous acheter votre bien au prix fort.<br><br>En effet, <strong>le prix ne peut pas être l’unique critère d’analyse.</strong> Les conditions suspensives portant sur l’obtention du permis de construire et sur la réalisation des études de sol sont essentielles, d’autres moins : nous vous aidons à identifier celles qui apparaissent acceptables au regard de votre projet de vente.<br><br>Enfin, nous veillons à ce que vous soient proposées <strong>des clauses de retour à meilleur fortune</strong> si l’opérateur construit plus de surface que prévu ou s’il vend mieux que prévu son opération."
  },
  {
    title: 'Est-il plus intéressant de vendre à un promoteur ?',
    answer: "Lorsque le règlement d’urbanisme de votre ville autorise la réalisation d’opération immobilière sur votre terrain, la vente à un promoteur immobilier peut vous permettre de <strong>vendre votre bien à un prix supérieur à celui du marché de l’ancien</strong>.<br><br>En étudiant le potentiel constructible et réaliser un bilan promoteur, nous serons en capacité de vous préciser la plus-value à laquelle vous pouvez prétendre.<br><br>Sachez que certains biens en copropriété tels que les appartements ne permettent pas la réalisation d'opération de promotion immobilière."
  },
  {
    title: 'Comment City&You négocie à vos côtés avec le promoteur immobilier ?',
    answer: 'Le secret d’une négociation réussie résulte de l’anticipation des points forts et des points faibles d’un dossier.<br><br>En amont de la consultation des promoteurs, les équipes de City&You étudient les possibilités qu’offre votre terrain. Cette analyse permet d’avoir une première approche de ce qu’il est possible d’obtenir comme prix d’achat du terrain et d’identifier ce qu’il sera important d’indiquer dans la promesse de vente (sujets techniques particulièrement).<br><br>De par ses connaissances du marché et des prérogatives du promoteur, City&You analyse les éléments quantitatifs et qualitatifs de chaque offre remise. Les équipes seront donc en mesure de pouvoir conseiller le propriétaire sur les points de négociation à mener.<br><br>Pour exemple : si le marché de la vente est particulièrement porteur, il sera judicieux de négocier un retour à meilleur fortune sur le prix de vente, ou encore si un sujet technique est identifié en amont, alors il sera important de l’anticiper dans la rédaction des documents contractuels.'
  },
  {
    title: 'Pourquoi dois-je me faire accompagner ?',
    answer: 'Pour réussir votre négociation avec un promoteur ou un investisseur, il vous faut bien connaître les étapes, les contraintes et les logiques financières d’un projet immobilier. Les délais de réalisation d’une opération se situent généralement entre 12 et 18 mois et les moments de discussion avec le promoteur sont nombreux.<br><br> Se faire accompagner par nos experts, c’est l’assurance d’un projet de vente serein et maîtrisé.'
  },
  {
    title: 'Comment City&You garantit la tenue des engagements pris lors de l’offre d’achat puis de la promesse de vente ?',
    answer: 'La promesse de vente signée entre le promoteur et le propriétaire vendeur précise l’ensemble des conditions de la vente, parmi les principales : le prix de vente, les délais et les conditions suspensives.<br><br>City&You veille à ce que le promoteur tienne les engagements qu’il a pris vis-à-vis du propriétaire. Ainsi, si le promoteur cherche à renégocier le prix d’achat convenu, nous sommes à vos côtés pour analyser la légitimité de la demande au regard des conditions suspensives signées. Par exemple, lorsque le promoteur met à jour des contraintes de sols pour la réalisation de l’opération à posteriori de la promesse de vente, nous vous aidons à vérifier que les montants de la renégociation sont justifiés.<br><br>S’agissant des délais, nous assurons les échanges avec le promoteur pour la tenue du calendrier établi (réalisation des études de sols, dépôt du permis de construire, date de signature) afin que l’acte de vente soit signé dans les temps.<br><br>Enfin, nous suivons la levée progressive des conditions suspensives et le respect des retours à meilleur fortune par un suivi de l’opération auprès du promoteur immobilier et de ses partenaires. Nous vous tenons informé régulièrement des avancées.'
  },
  {
    title: 'City&You est-il un promoteur immobilier ?',
    answer: "NON. <strong>City&You est l'expert qui se place à vos côtés</strong> pour optimiser et sécuriser la vente de votre bien à des opérateurs immobiliers (promoteurs, investisseurs,...).<br><br>Beaucoup d’opérations ne voient jamais le jour, car les négociations de départ ne sont pas faites dans de bonnes conditions avec des gens sérieux. Notre objectif est que vous vendiez dans les meilleures conditions et que les opérations se concrétisent."
  },
  {
    title: 'Quelles différences avec la vente par un agent immobilier ?',
    answer: "Lorsque vous faites appel à un agent immobilier vous vendez votre bien sur le marché de l’ancien, c'est à un particulier dans le but de l'habiter ou de le louer. Pour ce type de vente, de nombreuses visites sont nécessaires et le délai est généralement de 6 mois entre le moment où vous signez avec l’agent immobilier et le moment où vous passez chez le notaire. <br><br>Dans le cadre de la vente à un promoteur immobilier, vous vendez à un professionnel de l'immobilier. Celui-ci va chercher à transformer votre bien (construction, réhabilitation, surélévation,...) dans le but d'exploiter tout le potentiel immobilier autorisé par la collectivité. Dans ce type de vente, les délais sont plus longs (12 à 18 mois) mais le prix de vente peut être significativement supérieur."
  },
  {
    title: 'Quels sont les frais à ma charge ?',
    answer: '<strong>Aucun frais n’est à votre charge</strong>. Nous rémunérons notre activité par la perception d’honoraires pris en charge intégralement par les opérateurs immobiliers au moment de l’acte de vente et sur la base d’un pourcentage du prix de vente.<br><br><strong>Ainsi, nos intérêts sont les mêmes que ceux du propriétaire :</strong> réaliser la vente au meilleur prix.'
  },
  {
    title: "Qu'est ce que le mandat de vente ?",
    answer: "Comme dans la vente par un agent immobilier, vous nous autorisez à rechercher pour vous des acheteurs potentiels par un mandat de vente. Ce contrat nous permet de consulter les opérateurs immobiliers et de vous remettre des offres d'achat négociées."
  },
  {
    title: 'Quels partenaires mobilise City&You et à la charge de qui ?',
    answer: "City&You dispose d'un réseau de professionnels que nous mobilisons pour garantir notre mission : expertiser le potentiel de votre bien, consulter les opérateurs pertinents et sécuriser la concrétisation du projet immobilier.<br><br>City&You se réserve le choix de mobiliser les partenaires au cas par cas selon les spécificités du projet : architecte pour vérifier la capacité constructive, bureau d'étude technique pour étudier un point technique spécifique, avocat ou juriste pour vous apporter des éclairages sur une complexité identifiée.<br><br>Ces partenaires sont sollicités à la charge de City&You qui reste propriétaire des études qu'il commandite. "
  },
  {
    title: "Et si j'ai déjà une maison construite sur mon terrain ?",
    answer: 'L’activité d’un promoteur immobilier consiste à construire un nouvel immeuble. Pour construire celui-ci, le promoteur doit bien souvent démolir les constructions existantes. Cependant, il lui arrive de réhabiliter ou de surélever des bâtiments existants quand cela est pertinent.<br><br> Aussi, la présence d’une maison sur un terrain n’est pas un frein à la réalisation d’une opération de promotion immobilière.'
  },
  {
    title: 'Et si mes voisins sont également vendeurs ?',
    answer: 'Bonne nouvelle ! Plus le projet est grand et plus cela permettra au promoteur de réaliser des économies d’échelle et de dégager ainsi plus de valeur pour vous acheter vos biens.<br><br> En somme, plus vous êtes de voisins, mieux vous vendrez votre bien. <strong>Et plus vous jouez collectif, mieux vous négocierez avec les promoteurs</strong>.'
  },
  {
    title: "Quand vais-je toucher l'argent de la vente ?",
    answer: "La durée d’une vente à un promoteur se situe généralement entre 12 et 18 mois, car il est nécessaire d’avoir obtenu les autorisations administratives pour acheter le terrain. Par conséquent, l’argent de la vente est versé au moment de la signature de l’acte authentique chez le notaire.<br><br>Pour certains biens, il est envisageable d'étudier des alternatives plus rapide quand un très fort potentiel est identifié. En effet, nous savons mobiliser des partenaires en capacité de faire des offres fermes sans conditions."
  },
  {
    title: 'La confidentialité de mes données est-elle garantie ?',
    answer: 'OUI. Nous vous garantissons une confidentialité totale de vos données personnelles. <br><br>Conformément à la nouvelle réglementation européenne sur la protection des données personnelles (RGPD), aucune transmission de vos données à des tiers ne peut être réalisée sans solliciter préalablement votre consentement.'
  }
]
