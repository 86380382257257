const proButtons = document.getElementsByClassName('pro-tool-card')
const screen = document.getElementById('pro-tools-screen')

function removeClassByPrefix(node, prefix) {
  var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
  node.className = node.className.replace(regx, '');
  return node;
}

const removeActiveButtons = () => {
  Array.prototype.forEach.call(proButtons, el => {
    removeClassByPrefix(el, 'pro-tool-card--')
  })
}

const removeCurrentScreen = () => {
  removeClassByPrefix(screen, 'pro-screen--')
}

if (proButtons) {
  Array.prototype.forEach.call(proButtons, (el) => {
    el.addEventListener('click', () => {
      if (!el.classList.contains('pro-tool-card--active')) {
        removeActiveButtons()
        el.classList.add('pro-tool-card--active')
        removeCurrentScreen()
        const id = el.id.slice(-1)
        screen.classList.add(`pro-screen--${id}`)
      }
    })
  })
}
