export const selectStyle = {
  control: base => ({
    ...base,
    zIndex: 2,
    fontFamily: 'Gilroy',
    border: '1px solid #dbe0e4',
    borderRadius: '28px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '282px',
    height: '53px',
    width: '282px !important',
    marginBottom: '40px',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: '#31325f',
      boxShadow: 'none !important'
    },
    '&:active': {
      borderColor: '#31325f'
    }
  }),
  placeholder: base => ({
    ...base,
    height: '24px',
    opacity: 0.8,
    fontFamily: 'Gilroy',
    fontSize: '18px',
    fontStyle: 'italic',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#868898',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  singleValue: base => ({
    ...base,
    height: '24px',
    width: '100%',
    fontFamily: 'Gilroy',
    fontSize: '18px',
    fontStyle: 'normal',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#000',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  indicatorsContainer: base => ({
    ...base,
    svg: {
      fill: '#373737'
    }
  }),
  menu: base => ({
    ...base,
    width: '352px',
    animation: 'none',
    borderRadius: '0 !important',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      animation: 'none !important',
      fontFamily: 'Gilroy',
      color: '#31325f',
      fontWeight: isSelected ? 'bold' : 'normal',
      fontSize: '16px',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      width: '352px !important',
      borderRadius: '0 !important',
      ':active': {
        ...styles[':active'],
        backgroundColor: 'white',
        animation: 'none !important',
        borderRadius: '0 !important',
      },
      ':hover': {
        ...styles[':active'],
        backgroundColor: '#31325f',
        fontWeight: 'bold',
        color: 'white',
        animation: 'none !important',
        borderRadius: '0 !important',
      }
    }
  }
}

export const selectStyleMobile = {
  control: base => ({
    ...base,
    zIndex: 2,
    fontFamily: 'Gilroy',
    border: '1px solid #dbe0e4',
    borderRadius: '28px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    height: '53px',
    width: '100% !important',
    marginBottom: '20px',
    backgroundColor: 'white',
    '&:hover': {
      borderColor: '#31325f',
      boxShadow: 'none !important'
    },
    '&:active': {
      borderColor: '#31325f'
    }
  }),
  placeholder: base => ({
    ...base,
    height: '24px',
    opacity: 0.8,
    fontFamily: 'Gilroy',
    fontSize: '14px !important',
    fontStyle: 'italic',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#868898',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  singleValue: base => ({
    ...base,
    height: '24px',
    width: '100%',
    fontFamily: 'Gilroy',
    fontSize: '14px',
    fontStyle: 'normal',
    letterSpacing: '-0.5px',
    textAlign: 'left',
    color: '#000',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  indicatorsContainer: base => ({
    ...base,
    svg: {
      fill: '#373737'
    }
  }),
  menu: base => ({
    ...base,
    width: '100% !important',
    animation: 'none !important',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      animation: 'none !important',
      fontFamily: 'Gilroy',
      color: '#31325f',
      fontWeight: isSelected ? 'bold' : 'normal',
      fontSize: '14px !important',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      width: '100% !important',
      borderRadius: '0 !important',
      ':active': {
        ...styles[':active'],
        backgroundColor: 'white',
        animation: 'none !important',
      },
      ':hover': {
        ...styles[':active'],
        backgroundColor: '#31325f',
        fontWeight: 'bold',
        color: 'white',
        animation: 'none !important',
        borderRadius: '0 !important',
      }
    }
  }
}
