import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Sidebar from './Sidebar'
import Main from './Main'
import Results from './Results'
import Extra from './Extra'
import ContactForm from './ContactForm'
import ReactTooltip from 'react-tooltip'
import { device } from '../../stylesheets/device/device'
import moment from 'moment'
import theme from '../../stylesheets/theme'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'moment/locale/fr'
import FAQIcon from '../../images/desktop/calculator/FAQIcon.png'
import BlogIllu from '../../images/desktop/calculator/BlogIllu.png'
import abattementDataIR, { abattementDataPS, taxePV } from './data'

const answersDefault = {
  propertyType: { value: null, label: null },
  address: { complete: false, content: '' },
  abattement_exceptionnel: false,
  isPrimaryHouse: null,
  propDevSelling: null,
  frenchResident: null,
  socialSecurityOutsideFrance: null,
  soleOwner: null,
  share: 0,
  ownershipOrigin: { value: null, label: null },
  buyingDate: null,
  sellingDate: null,
  buyingPrice: '',
  buyingFees: '',
  constructionFees: '',
  sellingPriceEstimate: '',
  sellingPricePropDev: '',
  sellingPrice: ''
}

const answersTest = {
  address: { complete: true, content: "121 Rue Manin, Paris" },
  abattement_exceptionnel: true,
  buyingDate: moment("20030401"),
  buyingFees: "",
  buyingPrice: "118 910",
  constructionFees: "",
  frenchResident: "true",
  isPrimaryHouse: "false",
  ownershipOrigin: { value: 1, label: "Achat" },
  propDevSelling: "true",
  propertyType: { value: 1, label: "Maison" },
  sellingDate: moment("20211101"),
  sellingPrice: "",
  sellingPriceEstimate: "350 000",
  sellingPricePropDev: "400 000",
  share: 0,
  socialSecurityOutsideFrance: null,
  soleOwner: "true"
}

const resultsDefault = {
  taxableGain: '-',
  abattementIr: '-',
  abattementPs: '-',
  taxableGainIr: '-',
  taxableGainPs: '-',
  ir: '-',
  ps: '-',
  surtaxe: '-',
  totalTax: '-',
  netGain: '-',
  abattementExceptionnel: null,
  ownershipDuration: null
}

const extraDefault = {
  faq: {
    h3: 'Avez-vous pensé vendre votre bien à un promoteur immobilier ?',
    p1: "Chez City&You nous accompagnons les propriétaires pour valoriser au mieux leur bien en vendant à un opérateur immobilier (investisseur, promoteur, foncière). Des études de faisabilité à l’acte de vente, nous sommes à vos côtés.",
    p2: "Faites évaluer gratuitement le potentiel de votre bien !",
    span: "Plus de détails sur nos services ?",
    a: {
      text: "Consultez notre FAQ",
      href: "https://www.cityandyou.com/en-savoir-plus"
    }
  },
  blog: {
    h3: 'Saviez-vous que vous pourriez réduire significativement votre impôt sur la plus-value ?',
    p1: "Votre bien se situe en zone A et Abis du dispositif Pinel. À ce titre et sous certaines conditions, vous pouvez bénéficier d’une réduction importante de votre impôt sur la plus-value immobilière en vendant à un promoteur immobilier !",
    p2: "Contactez-nous pour en savoir plus !",
    span: "Taxe sur la plus-value : comment en diminuer le montant ?",
    a: {
      text: "Consultez l’article du blog",
      href: "https://www.cityandyou.com/blog/2020/01/06/diminuer-taxe-sur-la-plus-value"
    }
  }
}

const formatMoneyBack = money => parseInt(money.replace(/ /g, ''), 10)

const Calculator = () => {
  const [answers, setAnswers] = useState(answersDefault)
  const [results, setResults] = useState(resultsDefault)
  const [noTax, setNoTax] = useState(false)
  const [stopFlow, setStopFlow] = useState(false)

  const handleSimulation = () => {
    setStopFlow(true)

    const buyingFeesDefault = answers.buyingFees === "" ? formatMoneyBack(answers.buyingPrice) * 0.075 : formatMoneyBack(answers.buyingFees)
    const constructionFeesDefault = answers.constructionFees === "" ? formatMoneyBack(answers.buyingPrice) * 0.15 : formatMoneyBack(answers.constructionFees)

    setAnswers({ ...answers, buyingFees: buyingFeesDefault.toString(), constructionFees: constructionFeesDefault.toString() })
    let P0, PV, PV1, PV2, PVV, T1, T2, PVT1, PVT2
    if (answers.propertyType.value === 3) {
      P0 = formatMoneyBack(answers.buyingPrice) + buyingFeesDefault
    } else {
      P0 = formatMoneyBack(answers.buyingPrice) + buyingFeesDefault + constructionFeesDefault
    }

    const ownershipDuration = answers.sellingDate.year() - answers.buyingDate.year()
    const irRate = 0.19
    const psRate = !answers.frenchResident && socialSecurityOutsideFrance ? 0.075 : 0.172

    let abattementIr, abattementPs
    if (ownershipDuration < 6) {
      abattementIr = 0
      abattementPs = 0
      T1 = irRate
      T2 = psRate
    } else if (ownershipDuration >= 30) {
      abattementIr = 1
      abattementPs = 1
      T1 = 0
      T2 = 0
    } else {
      abattementIr = parseFloat(abattementDataIR[ownershipDuration])
      abattementPs = parseFloat(abattementDataPS[ownershipDuration])
      T1 = irRate - abattementIr * irRate
      T2 = psRate - abattementPs * psRate
    }

    if (answers.propDevSelling === 'false' || answers.propertyType.value === 2) {
      PV = formatMoneyBack(answers.sellingPrice) - P0
      PVT1 = PV * (1 - abattementIr)
      PVT2 = PV * (1 - abattementPs)
      PVV = PV
    } else if (answers.propDevSelling === 'true' && answers.isPrimaryHouse === "true") {
      PV1 = formatMoneyBack(answers.sellingPriceEstimate) - P0
      PV2 = formatMoneyBack(answers.sellingPricePropDev) - formatMoneyBack(answers.sellingPriceEstimate)
      PVT1 = PV2 * (1 - abattementIr)
      PVT2 = PV2 * (1 - abattementPs)
      PVV = PV2
    } else {
      PV1 = formatMoneyBack(answers.sellingPriceEstimate) - P0
      PV2 = formatMoneyBack(answers.sellingPricePropDev) - formatMoneyBack(answers.sellingPriceEstimate)
      PVT1 = PV1 * (1 - abattementIr)
      PVT2 = PV1 * (1 - abattementPs)
      PVV = PV1
    }

    const I1 = PVT1 * irRate
    const I2 = PVT2 * psRate

    let I3
    if (answers.propertyType.value === 3) {
      I3 = 0
    } else {
      I3 = taxePV(PVT1)
    }

    const IT = I1 + I2 + I3
    const G = PVV - IT
    let newResults
    if (PVV <= 0) {
      newResults = {
        taxableGain: 0,
        abattementIr: parseFloat((T1 * 100).toPrecision(4)),
        abattementPs: parseFloat((T2 * 100).toPrecision(4)),
        taxableGainIr: 0,
        taxableGainPs: 0,
        ir: 0,
        ps: 0,
        surtaxe: 0,
        totalTax: 0,
        netGain: 0,
        ownershipDuration
      }
    } else {
      newResults = {
        taxableGain: parseInt(PVV),
        abattementIr: parseFloat((T1 * 100).toPrecision(4)),
        abattementPs: parseFloat((T2 * 100).toPrecision(4)),
        taxableGainIr: parseInt(PVT1),
        taxableGainPs: parseInt(PVT2),
        ir: parseInt(I1),
        ps: parseInt(I2),
        surtaxe: parseInt(I3),
        totalTax: parseInt(IT),
        netGain: parseInt(G),
        ownershipDuration
      }
    }

    setResults(newResults)
    document.getElementById('scrolltobottom').scrollIntoView({ behavior: 'smooth' })
    sendResults(newResults)
  }

  const sendResults = (newResults) => {
    const body = {
      taxableGainSimulation: {
        ...answers,
        address: answers.address.content,
        propertyType: answers.propertyType.label,
        ownershipOrigin: answers.ownershipOrigin.label,
        ...newResults
      }
    }
    fetch('/taxable_gain_simulations', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(responseJson => responseJson)
  }

  useEffect(() => {
    if (answers.propertyType.value === 1 && answers.isPrimaryHouse === 'true' && answers.propDevSelling === 'false') {
      setNoTax(true)
      setStopFlow(true)
    } else if (answers.propertyType.label === 'Appartement' && answers.isPrimaryHouse === 'true') {
      setNoTax(true)
      setStopFlow(true)
    } else {
      setNoTax(false)
      // setStopFlow(false)
    }
  }, [answers])

  return (
    <Page>
      <ThemeProvider theme={theme}>
        <Sidebar answers={answers} />
        <Container>
          <Main
            answers={answers}
            handleAnswers={(e) => setAnswers(e)}
            completed={stopFlow}
            handleSimulation={() => {
              handleSimulation()
            }}
          />
          <span id="scrolltobottom" />
          {
            (results.netGain !== '-' || (noTax && answers.propertyType.label !== 'Appartement')) && (
              <Extra
                img={FAQIcon}
                content={extraDefault.faq}
                cta
              />
            )
          }

          {
            results.netGain !== '-' && stopFlow && answers.abattement_exceptionnel && (
              <Extra
                img={BlogIllu}
                content={extraDefault.blog}
                pink
                last
              />
            )
          }
          {
            stopFlow && (
              <ContactForm
                answers={answers}
                results={results}
              />
            )
          }
          <Results
            answers={answers}
            results={results}
            completed={results.netGain !== '-' || noTax}
            ownershipDuration={answers.sellingDate - answers.buyingDate}
            noTax={noTax}
          />
        </Container>
      </ThemeProvider>
    </Page>
  )
}

export const Tooltip = ({ tip }) => (
  <TooltipIcon
    className="calc-tooltip"
    data-tip={tip}
  >
    i
    <ReactTooltip
      arrowColor="#a6c3db"
      className="calc-tooltip"
      html
    />
  </TooltipIcon>
)

const TooltipIcon = styled.span`
  background-color: #a6c3db;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Page = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.candyPrimary};

  .__react_component_tooltip {
    background-color: #a6c3db;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    font-family: Gilroy;
    font-size: 14px;
    max-width: 300px;
    opacity: 1;

    i {
      font-weight: normal;
      font-style: normal;
    }
  }

  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #a6c3db;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export default Calculator
