import React, { useEffect } from 'react'
import { TitleSimpleForm } from '../../SimpleForm/TitleSimpleForm'
import { Errors } from '../../SimpleForm/error'
import { useForm } from '../../../context/form.context'

export const AnswerSquareMeter = ({
  squareMeter, setSquareMeter,
  area, isArea = false, step, text, testID, type, placeholder, id
}) => {
  const { errors } = useForm()
  useEffect(() => {
    if (document) {
      document.getElementById('input-focus')
        .focus()
    }
  })
  return (
    <div
      className="container-answers fadeIn"
      data-testid={testID}
      id={id}
    >
      <TitleSimpleForm
        itemIndex={step}
        title={text}
      />
      <div id="HowManySquareMeter">
        <label>
          <input
            id="input-focus"
            aria-label="squareMeter"
            name="squareMeter"
            type="number"
            min={1}
            onChange={e => setSquareMeter(e.target.value)}
            value={isArea ? area : squareMeter}
            className="input-email input-number"
            style={{ borderColor: errors[type].includes('empty') ? '#d95d57' : 'initial' }}
            data-testid="input-square"
            placeholder={placeholder}
          />
          <div className="ctn-btn-input-number">
            <button className="button-arrow" onClick={() => setSquareMeter(isArea ? Number(area) + 1 : Number(squareMeter) + 1)}>
              <div className="arrow-top" />
            </button>
            <button
              className="button-arrow"
              onClick={() => {
                if (isArea ? (area === 0 || !area) : (squareMeter === 0 || !squareMeter)) return
                setSquareMeter(isArea ? area - 1 : squareMeter - 1)
              }}
            >
              <div className="arrow-bottom" />
            </button>
          </div>
          {errors[type].includes('empty')
          && (
            <Errors
              message="Ce champ est requis."
            />
          )}
        </label>
      </div>
    </div>
  )
}
