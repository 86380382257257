import '../stylesheets/application.scss'
import Rails from 'rails-ujs'
import './glide'
import './community'
import './service'
import './product'
import './corporate'
import './guide'
import './footer'

require('chartkick')
require('chart.js')

// Support component names relative to this directory:
const componentRequireContext = require.context('pages', true)

const importAll = (r) => r.keys().map(r)
importAll(require.context('../images', true, /\.(png|jpe?g|svg)$/))

const ReactRailsUJS = require('react_ujs')

Rails.start()

ReactRailsUJS.useContext(componentRequireContext)
