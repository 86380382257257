import React from 'react'
import FormAnimation from './FormAnimation'
import { useForm } from '../../context/form.context'

export const FriseForm = ({step, type}) => {
  const {out} = useForm()

  if (out) {
    return <Frise image={require('../../images/desktop/simpleform/appartment_rejection.svg')}/>
  }

  switch (step) {
    case 0:
      return <FormAnimation/>
    case 1:
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_2@2x.png')}/>
    case 2: {
      if (type === 'two') {
        return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_3@2x.png')}/>
      }
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_2@2x.png')}/>
    }
    case 3: {
      if (type === 'two') {
        return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_5@2x.png')}/>
      }
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_3@2x.png')}/>
    }
    case 4: {
      if (type === 'two') {
        return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_5@2x.png')}/>
      }
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_rooms@2x.png')}/>
    }
    case 5: {
      if (type === 'two') {
        return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_4@2x.png')}/>

      }
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_3@2x.png')}/>
    }
    case 6:
      if (type === 'two') {
        return <Frise image={require('../../images/desktop/simpleform/illusswaitingestimation@2x.png')}/>
      }
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_5@2x.png')}/>

    case 7:
      if (type === 'two') {
        return <p />
      }
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_5@2x.png')}/>

    case 8:
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_4@2x.png')}/>
    case 9:
      return <Frise image={require('../../images/desktop/simpleform/illusswaitingestimation@2x.png')}/>
    case 10:
      return <p/>
    default:
      return <Frise image={require('../../images/desktop/simpleform/Formulaire_illustration_large_form_1@2x.png')}/>
  }
}

export const Frise = ({image}) => (
  <div id="frise" data-testid="right-side-image-form">
    <img
      src={image}
      alt="frise_form"
    />
  </div>
)
