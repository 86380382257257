import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { device } from '../../stylesheets/device/device'
import PlaneIcon from '../../images/desktop/calculator/PaperPlane.svg'

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ContactForm = ({ answers, results }) => {
  const [completed, setCompleted] = useState(false)
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = (data) => {
    setCompleted(true)
    const body = {
      contactMessage: data,
      taxableGainSimulation: {
        ...answers,
        address: answers.address.content,
        propertyType: answers.propertyType.label,
        ownershipOrigin: answers.ownershipOrigin.label,
        ...results
      }
    }
    fetch('/contact_message_calculators', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(responseJson => responseJson)
  }
  return (
    <FormWrapper>
      <h2>Contactez-nous !</h2>
      {
        completed ? (
          <div className="plane-wrapper">
            <img src={PlaneIcon} alt="avion en papier" />
            <p>Votre demande a été bien transmise</p>
          </div>
        )
        : (
          <form onSubmit={handleSubmit(onSubmit)} id="contact-calculator">
            <input name="firstName" placeholder="Prénom*" ref={register({ required: true })} />
            {errors.firstname && <span className="form-error">Votre prénom est requis.</span>}
            <input name="lastName" placeholder="Nom*" ref={register({ required: true })} />
            {errors.lastname && <span className="form-error">Votre nom est requis.</span>}
            {/*
              <select id="reason" form="contact-calculator">
                <option value="" disabled>L'objet de votre demande*</option>
                <option value="Être recontacté(e)">Être recontacté(e) par l’un de nos conseillers</option>
                <option value="Recevoir le récapitulatif">Recevoir le récapitulatif de mon impôt sur la plus-value</option>
              </select>
            */}
            <input name="email" placeholder="Email*" ref={register({ required: true, pattern: emailRegEx })} />
            {errors.email && <span className="form-error">Format de votre email incorrect.</span>}
            <input name="phone" placeholder="Téléphone*" ref={register({ required: true })} />
            {errors.email && <span className="form-error">Numéro de téléphone requis.</span>}
            <p><small>* Champs obligatoires</small></p>
            <p><small>City&You utilise vos données personnelles pour remplir sa mission. Ces informations sont confidentielles et ne seront jamais partagées sans votre accord. <a href="https://www.cityandyou.com/charte-de-confidentialite">En savoir plus sur la gestion de vos données et vos droits.</a></small></p>
            <input type="submit" value="Envoyer" />
          </form>
        )
      }
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;

  .plane-wrapper {
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 24px;
    }

    p {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
    }
  }

  form {
    max-width: 412px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  input, select {
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgrey;
    background: transparent;
    margin-bottom: 24px;

    option {
      width: 100%;
      height: 30px;
      color: ${props => props.theme.candyPrimary};
    }

    ::placeholder, option:disabled {
      font-style: italic;
      color: rgba(44, 45, 86, 0.6);
    }

    &[type=submit] {
      background-color: ${props => props.theme.candyPrimary};
      color: white;
      width: 180px;
      height: 60px;
      border-radius: 36px;
      font-weight: bold;
      transition: 300ms all ease;

      &:hover {
        background-color: #808099;
      }
    }

    &:focus {
      outline: none !important;
    }
  }

  .form-error {
    font-size: 14px;
    color: ${props => props.theme.candySecondary};
    width: 100%;
    text-align: right;
  }

  p {
    color: ${props => props.theme.candyPrimary};
    font-size: 11px;
    text-align: left;
    width: 100%;
    line-height: 1.1;
    margin-bottom: 8px;

    a {
      text-decoration: underline;
    }
  }

  p:last-of-type {
    margin-bottom: 24px;
  }

  @media ${device.laptop} {
    padding: 24px;

    h2 {
      font-size: 18px;
    }
  }
`

export default ContactForm
