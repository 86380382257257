import React from 'react'
import styled from 'styled-components'
import { Tooltip } from './Calculator'
import { device } from '../../stylesheets/device/device'
import { isMobile } from 'react-device-detect'

export const formatter = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 0,
})

const tipContent = {
  'french': "<b>Pour les résidents fiscaux de France : </b><ul><li>L'exonération totale pour la réalisation d'une opération à 100% de logements sociaux.</li><li>La première cession d'un logement autre que la résidence principale sous condition de remploi</li><li>Considérés comme retraités ou invalides de condition modeste</li></ul>",
  'notFrench': "<b>Pour les non-résidents fiscaux de France</b> :<br /> Un logement situé en France dans la limite d'une résidence par contribuable et de 150 000€ de plus-value nette imposable"
}

const Results = ({ answers, results, noTax, completed }) => (
  <ResultsWrapper noTax={noTax} completed={completed}>
    {
      noTax ? (
        <div className="results-container">
          <h2>Vous n’avez pas d’impôt sur la plus-value à payer !</h2>
          <p className="notax">D’après les informations renseignées, vous êtes exonérés d’impôt sur la plus-value immobilière.</p>
        </div>
      )
        : (
          <div className="results-container">
            <h2>Découvrez le montant de votre impôt sur la plus-value</h2>
            <AnswerBloc1 results={results} ownershipDuration={results.ownershipDuration} />
            <AnswerBloc2 results={results} />
            <AnswerBloc3 results={results} />
            {
              isMobile ? (
                <p>Les résultats de ce calcul sont donnés à titre indicatif. Ils ne prennent pas en compte certains cas particuliers.</p>
              )
              : (
                <small>Les résultats de ce calcul sont donnés à titre indicatif. Ils ne prennent pas en compte certains cas particuliers.
                  {
                    <Tooltip tip={`${answers.frenchResident === "true" ? tipContent.french : tipContent.notFrench}`} />
                  }
                </small>
              )
            }
          </div>
      )
    }
  </ResultsWrapper>
)

const AnswerBloc1 = ({ results, ownershipDuration }) => (
  <AnswerBloc>
    <h3>Calcul de votre plus-value taxable</h3>
    <div className="result-large">
      <label>Montant de la plus-value imposable</label>
      <span>{formatter.format(results.taxableGain)} €</span>
    </div>
    <div className="result-large">
      <label>Taux d'imposition pour la durée de détention</label>
    </div>

    <div className="result-min">
      <label>Durée de détention</label>
      <span>{ownershipDuration} ans</span>
    </div>
    <div className="result-min">
      <label>Taux d'imposition au titre de l’impôt sur le revenu</label>
      <span>{results.abattementIr} %</span>
    </div>
    <div className="result-min">
      <label>Taux d'imposition au titre des prélèvement sociaux </label>
      <span>{results.abattementPs} %</span>
    </div>

    <div className="result-large">
      <label>Montant de la plus-value taxable</label>
    </div>
    <div className="result-min">
      <label>Plus-value taxable au titre de l’impôt sur le revenu</label>
      <span>{formatter.format(results.taxableGainIr)} €</span>
    </div>
    <div className="result-min">
      <label>Plus-value taxable au titre des prélèvements sociaux</label>
      <span>{formatter.format(results.taxableGainPs)} €</span>
    </div>

  </AnswerBloc>
)

const AnswerBloc2 = ({ results }) => (
  <AnswerBloc>
    <h3>Calcul de votre impôt sur la plus-value immobilière</h3>
    <div className="result-large">
      <label>Impôt sur le revenu</label>
      <span>{formatter.format(results.ir)} €</span>
    </div>

    <div className="result-large">
      <label>Prélèvements sociaux</label>
      <span>{formatter.format(results.ps)} €</span>
    </div>

    <div className="result-large">
      <label>Surtaxe sur les plus values excédant 50 000 €</label>
      <span>{formatter.format(results.surtaxe)} €</span>
    </div>
  </AnswerBloc>
)

const AnswerBloc3 = ({ results }) => (
  <AnswerBloc>
    <div className="result-total">
      <i></i>
      <h3>Impôt total</h3>
      <span>{formatter.format(results.totalTax)} €</span>
    </div>
    <h3>Vos gains net de cession</h3>
    <div className="result-large">
      <label>Montant de votre plus-value nette d’impôt</label>
      <span>{formatter.format(results.netGain)} €</span>
    </div>
  </AnswerBloc>
)

const ResultsWrapper = styled.div`
  position: relative;
  font-family: Gilroy;
  color: ${props => props.theme.candyPrimary};
  background-color: ${props => props.theme.candyYellow};
  width: 100%;
  justify-content: center;
  padding-bottom: ${props => props.noTax ? '0' : '48px'};
  display: ${props => props.completed ? 'flex' : 'none'};
  transition: 300ms all ease;

  #results-scroll {
    position: absolute;
  }

  .results-container {
    width: 100%;
    max-width: 736px;
    padding: 26px 12px;

    small {
      position: relative;

      span {
        position: absolute;
        right: -20px;
        top: -4px;
      }
    }
  }

  .notax {
    font-size: 14px;
    line-height: 1.1;
    text-align: center;
  }

  h2 {
    font-size: 30px;
    line-height: 1.5;
    letter-spacing: -0.9px;
    text-align: center;
    max-width: 500px;
    text-align: center;
    margin: 24px auto;
  }

  small {
    font-size: 12px;
    font-style: italic;
  }

  .__react_component_tooltip {
    background-color: #a6c3db;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    font-family: Gilroy;
    font-size: 14px;
    max-width: 300px !important;
    opacity: 1;
    line-height: 1;

    ul {
      padding-left: 8px;
    }
  }

  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #a6c3db;
  }

  @media ${device.laptop} {
    h2 {
      font-size: 24px;
      line-height: 1.1;
    }

    padding: 0 24px;

    .calc-tooltip {
      display: none !important;
    }

    p {
      font-size: 11px;
      line-height: 1.2;
    }
  }
`

const AnswerBloc = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${props => props.theme.candyPrimary};

  @media ${device.laptop} {
    h3 {
      font-size: 16px;
      line-height: 1.1;
    }

    label, span {
      font-size: 12px;
    }

    .result-min {
      padding-left: 12px !important;

      label {
        max-width: 70%;
      }
    }

    .result-large {
      label {
        max-width: 70%;
      }
    }
  }


  h3 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  label {
    color: ${props => props.theme.candyPrimary};
    margin: 0;
  }

  .result-large, .result-min {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .result-large {
    border-bottom: 1px solid gray;
    height: 40px;
    margin-bottom: 12px;
  }

  .result-min {
    height: 32px;
    padding-left: 42px;
  }

  .result-total {
    margin-top: 36px;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bold;
    }
  }
`

export default Results
