import React from 'react';
import PropTypes from 'prop-types';
import Question from './Question.jsx';

const FAQColumn = ({
  questions,
  columnNumber,
  activeQuestion,
  handleClick,
}) => (
  <div className="faq-column">
    {
      questions.map((question, index) => (
        <Question
          handleClick={() => handleClick(`column ${columnNumber} - ${index}`)}
          key={`column ${columnNumber} - ${index}`}
          question={question}
          active={activeQuestion === `column ${columnNumber} - ${index}`}
        />
      ))
    }
  </div>
);

FAQColumn.propTypes = {
  questions: PropTypes.array.isRequired,
  columnNumber: PropTypes.number.isRequired,
  activeQuestion: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default FAQColumn;
