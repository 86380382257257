import React from 'react'
import 'react-loading-bar/dist/index.css'
import { StepProvider } from '../context/step.context'
import { Form } from '../components/inscription/form'
import { FormProvider } from '../context/form.context'
import { LayoutInscription } from '../components/inscription/layout/layout'

require('es6-promise').polyfill()
require('isomorphic-fetch')

export default function Inscription() {
  return (
    <div data-testid="inscription">
      <StepProvider>
        <FormProvider>
          <LayoutInscription>
            <Form />
          </LayoutInscription>
        </FormProvider>
      </StepProvider>
    </div>
  )
}
