const formGuide = document.getElementById('form-guide')
const formSuccess = document.getElementById('form-success')

window.onload = () => {
  const url = new URL(window.location.href)
  const success = url.searchParams.get('success')
  if (success === 'true') {
    formGuide.classList.add('hidden')
    formSuccess.classList.remove('hidden')
  } else if (success === 'false') {
    formGuide.classList.add('form-guide-error')
  }
}
