import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MapboxMap, { Marker } from 'react-mapbox-wrapper'
import ReactTooltip from 'react-tooltip'
import { isMobile } from 'react-device-detect'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import Moment from 'react-moment'
import 'moment/locale/fr'
import moment from 'moment'
import { MapWrapper,
  ProjectReco,
  ResultsBloc,
  PublicStyle,
  PublicBloc,
  Articles,
  TransactionCard,
  translatePropertyType,
  capitalizeFirstLetter,
  translatePropertyCondition,
  MAP_BOX_ACCESS_TOKEN
} from './simulator'
import ContactForm from '../contactForm'
import { formatPrice, toolTip1 } from '../../components/SimpleForm/Estimation'
import { device } from '../../stylesheets/device/device'
import BuildingIcon from './images/BuildingIcon.svg'
import HouseIcon from './images/HouseIcon.svg'
import GreenfieldIcon from './images/GreenfieldIcon.svg'
import MarkerIcon from './images/Marker.svg'

import mapboxgl from 'mapbox-gl'
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

Moment.globalLocale = 'fr'
Moment.globalFormat = 'D MMMM YYYY'

const Results = ({ valuation, property, user, agencies, idf, transactions, valuations }) => {
  const [map, setMap] = useState(null)

  const { id, confidence_min, confidence_max, predicted_price, confidence_index } = valuation

  const onMapLoad = (e) => {
    setMap(e)
    e.resize()
  }

  useEffect(() => {
  }, [])

  return (
    <SimulatorWrapper>
      <ResultsWrapper>
        <ResultsContainer>
          <span
            id="back"
            onClick={() => window.location.href = "https://www.cityandyou.com/estimation-immobiliere"}
          >
            {'< Retour'}
          </span>
          <DataBlocs>
            <ResultsBloc long>
              <section>
                <h2>Votre bien</h2>
                <ul>
                  <li><b>{translatePropertyType(property.category)}</b></li>
                  <li><b>{property.street_address}, {property.postal_code}, {property.city}</b></li>
                </ul>
                <ul>
                  {
                    property.category !== 'greenfield' && (
                      <div>
                        <li>
                          <label>État général :</label>
                          <span>{translatePropertyCondition(property.condition)}</span>
                        </li>
                        <li>
                          <label>Surface habitable :</label>
                          <span>{property.living_area} m²</span>
                        </li>
                        <li>
                          <label>Nombre de pièces :</label>
                          <span>{property.rooms}</span>
                        </li>
                      </div>
                    )
                  }
                  {
                    property.category !== "apartment" && (
                      <li>
                        <label>Surface du terrain :</label>
                        <span>{property.area} m²</span>
                      </li>
                    )
                  }
                </ul>
              </section>
              <section id="mapbox-section">
                { property.latitude && property.longitude && (
                  <MapboxMap
                    accessToken={MAP_BOX_ACCESS_TOKEN}
                    defaultZoom={18}
                    mapboxStyle={'mapbox://styles/cityandyou/ckcdcdt7t1w471iqimnseibx0'}
                    coordinates={{ lat: property.latitude, lng: property.longitude }}
                    zoom={17}
                    maxZoom={19}
                    onLoad={e => onMapLoad(e)}
                  >
                    { map && <Marker coordinates={{ lat: property.latitude, lng: property.longitude }} map={map}><img id="marker" src={MarkerIcon}/></Marker> }
                  </MapboxMap>
                )}
              </section>
            </ResultsBloc>
          </DataBlocs>
          {
            (confidence_min > 0 && confidence_max > 0 && predicted_price > 0) && (
              <ProjectReco>
                <h2>L'estimation immobilière</h2>
                <ul className="subtitle">
                  <li>Réalisée le : {moment(valuation.created_at).format('DD/MM/YYYY')}</li>
                </ul>
                <section>
                  <div className="estimation-block">
                    <ul id="animation-estimates">
                      <li>
                        <span>
                          {formatPrice(confidence_min.toFixed())}
                          {' '}
                          €
                        </span>
                        <span>Estimation basse</span>
                      </li>
                      <li>
                        <span>
                          {formatPrice(predicted_price.toFixed())}
                          {' '}
                          €
                        </span>
                        <span>Estimation moyenne</span>
                      </li>
                      <li>
                        <span>
                          {formatPrice(confidence_max.toFixed())}
                          {' '}
                          €
                        </span>
                        <span>Estimation haute</span>
                      </li>
                    </ul>
                    <div className="trust-index-sim">
                      <p data-tip={toolTip1}>
                        Indice de pertinence
                        <sup>
                          (1)
                        </sup>
                      </p>
                      <ReactTooltip className="tooltip" />
                      <ul>
                        {
                          [0, 1, 2, 3, 4].map((e, i) => (i < confidence_index ? <li className="full" key={`confidence-index-bullet-${e}`} />
                            : <li className="empty" key={`confidence-index-bullet-${e}`}/>))
                        }
                      </ul>
                    </div>
                    {
                      isMobile && (
                        <p className="tooltip-mobile" id="mobile-tooltip-estimate">
                          <sup>(1)</sup>
                          {toolTip1}
                        </p>
                      )
                    }
                  </div>
                </section>
                <p id="valuation-disclaimer"><b>Ceci est une première approche de la valeur de votre bien.</b><br/>Pour une estimation plus précise et fiable, il est nécessaire de faire appel à un professionnel de l’immobilier.</p>
              </ProjectReco>
            )
          }
          <DataBlocs middle>
            <ResultsBloc cta>
              <section>
                <h2>Votre quartier</h2>
                <p>Prix moyens de vente :</p>
                <div className="row row--little">
                  <div className="bloc">
                    <img src={HouseIcon} />
                    <p>Maison</p>
                    <p><b>{` ${valuations[0] || '-'} € / m²`}</b></p>
                  </div>
                  <div className="bloc">
                    <img src={BuildingIcon} />
                    <p>Appartement</p>
                    <p><b>{` ${valuations[1] || '-'} € / m²`}</b></p>
                  </div>
                  <div className="bloc">
                    <img src={GreenfieldIcon} />
                    <p>Terrain nu</p>
                    <p><b>{` ${valuations[2] || '-'} € / m²`}</b></p>
                  </div>
                </div>
              </section>
            </ResultsBloc>
            <ResultsBloc cta>
              <section>
                <h2>Vous avez un projet de construction ?</h2>
                <p>City&You vous propose de simuler le projet immobilier adapté à votre bien.</p>
                <p>Promotion immobilière, division foncière ou extension, nous vous recommandons le projet le plus adapté à votre bien ou votre terrain.</p>
                <button
                  type="button"
                  className="with-bg-violet with-bg-violet--blue"
                  onClick={() => {
                    window.location.href = `https://pro.cityandyou.com/simulation?address=${encodeURIComponent(`${property.street_address}, ${property.postal_code}, ${property.city}`)}`
                  }}
                >
                  <span>Simuler mon projet</span>
                </button>
              </section>
            </ResultsBloc>
          </DataBlocs>
          {
            (idf && transactions.length > 0) && (
              <DataBlocs high>
                <ResultsBloc long>
                  <section>
                    <h2>Transactions dans ce secteur</h2>
                    <ul id="transactions">
                      {
                        transactions.map((trans) => (
                          <TransactionCard
                            key={trans.id}
                            address={trans.street_address.toLowerCase()}
                            city={property.city}
                            propertyType={capitalizeFirstLetter(trans.property_type)}
                            price={trans.price}
                            date={trans.date}
                            squareMeterPrice={trans.square_meter_price}
                            livingArea={trans.living_area}
                          />
                        ))
                      }
                    </ul>
                  </section>
                </ResultsBloc>
              </DataBlocs>
            )
          }
          <Public simId={id} phone={user.phone} />
          <p className="public-disclaimer">
            L’ensemble des informations ainsi que le résultat de l’estimation immobilière, issus de bases de données publiques et de nos propres algorithmes, sont transmis à titre informatif. City&You ne pourra être tenu responsable de l’utilisation de ces informations.
          </p>
          <p className="public-disclaimer">
            Pour une étude plus fine, nous vous invitons à prendre contact avec nos experts.
          </p>
        </ResultsContainer>
      </ResultsWrapper>
    </SimulatorWrapper>
  )
}

const Public = ({ phone, simId }) => (
  <PublicStyle>
    <Articles />
    <PublicBloc id="contact-us-results">
      <h2>Nous contacter</h2>
      <ContactForm valuationId={simId} />
    </PublicBloc>
  </PublicStyle>
)

const SimulatorWrapper = styled(MapWrapper)`
  padding-top: 70px;

  .mapboxgl-marker > img {
    top: -26px;
    position: absolute;
    height: 38px;
  }

  @media ${device.mobileL} {
    height: auto;
    background: #F2F6FA;
    min-height: 100vh;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .mapbox-map {
      opacity: ${props => props.plotsPopupOpen ? '0' : '1'};
      position: ${props => props.plotsPopupOpen ? 'absolute' : 'relative'};
      z-index: ${props => props.plotsPopupOpen ? '-1' : '1'};
      display: ${props => props.step === 1 ? 'flex' : 'none'};
      height: 40vh !important;
    }
  }
`
const ResultsWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #F2F6FA;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: none;
  padding-top: ${props => props.pro ? '120px' : 'auto'};

  @media ${device.mobileL} {
    padding: 42px 12px 82px 12px;
    min-height: 100vh;
  }
`

const ResultsContainer = styled.div`
  position: relative;
  max-width: 980px;
  width: 100%;
  height: 100%;
  padding: 48px 0;
  background: transparent;
  display: flex;
  flex-direction: column;

  #back {
    position: absolute;
    top: 24px;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  @media ${device.mobileL} {
    padding: 0;

    #back {
      top: -32px;
    }
  }
`

const rowSize = (props) => {
  if (props.high) { return ('360px') }
  if (props.middle) { return ('295px') }
  return ('295px')
}

const DataBlocs = styled.div`
  width: 100%;
  height: ${(props) => rowSize(props)};
  min-height: ${(props) => rowSize(props)};
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  label {
    color: #31325f;
  }

  #transactions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      min-width: 48%;
      height: 127px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 6px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

      h3 {
        width: 100%;
        text-transform: capitalize;
        font-size: 16px;
        margin: 0;
        padding: 8px 16px;
        background: #f9fafb;
      }

      p {
        margin: 0;
      }

      small {
        margin-bottom: 12px;
      }

      span {
        font-weight: normal;
        font-size: 12px;
      }

      section {
        display: flex;
        background: #f9fafb;
        height: 100%;

        .image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 146px;
          background: white;

          img {
            margin: 0;
          }
        }

        .text-container {
          padding: 8px;

          section {
            margin-top: 12px;
          }
        }

        section {
          display: flex;
          flex-direction: column;
          background: transparent;
          padding: 0;
        }
      }
    }
  }

  #quartier-2 {
    width: 50%;

    li {

    }
  }

  @media ${device.mobileL} {
    height: auto;
    min-height: unset;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 8px;

    div:last-of-type {
      margin-bottom: 0;
    }

    #transactions {
      li {
        width: 100%;

        h3 {
          font-size: 14px;
        }

        section {
          flex-direction: row;

          .image-container {
            width: 92px;
          }
        }
      }
    }
  }
`

export default Results
