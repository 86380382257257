export const housingData = {
   "01": {
      "greenfield": 90,
      "house": 1770
   },
   "02": {
      "greenfield": 40,
      "house": 1070
   },
   "03": {
      "greenfield": 20,
      "house": 940
   },
   "04": {
      "greenfield": 80,
      "house": 1810
   },
   "05": {
      "greenfield": 100,
      "house": 1900
   },
   "06": {
      "greenfield": 200,
      "house": 3910
   },
   "07": {
      "greenfield": 60,
      "house": 1540
   },
   "08": {
      "greenfield": 30,
      "house": 980
   },
   "09": {
      "greenfield": 40,
      "house": 1030
   },
   "10": {
      "greenfield": 60,
      "house": 1210
   },
   "11": {
      "greenfield": 80,
      "house": 1360
   },
   "12": {
      "greenfield": 30,
      "house": 1020
   },
   "13": {
      "greenfield": 290,
      "house": 2990
   },
   "14": {
      "greenfield": 90,
      "house": 1710
   },
   "15": {
      "greenfield": 20,
      "house": 890
   },
   "16": {
      "greenfield": 30,
      "house": 1070
   },
   "17": {
      "greenfield": 90,
      "house": 1790
   },
   "18": {
      "greenfield": 30,
      "house": 980
   },
   "19": {
      "greenfield": 10,
      "house": 1030
   },
   "21": {
      "greenfield": 80,
      "house": 1590
   },
   "22": {
      "greenfield": 70,
      "house": 1260
   },
   "23": {
      "greenfield": 10,
      "house": 650
   },
   "24": {
      "greenfield": 10,
      "house": 1120
   },
   "25": {
      "greenfield": 70,
      "house": 1520
   },
   "26": {
      "greenfield": 110,
      "house": 1770
   },
   "27": {
      "greenfield": 50,
      "house": 1520
   },
   "28": {
      "greenfield": 80,
      "house": 1440
   },
   "29": {
      "greenfield": 80,
      "house": 1390
   },
   "30": {
      "greenfield": 110,
      "house": 1840
   },
   "31": {
      "greenfield": 120,
      "house": 2150
   },
   "32": {
      "greenfield": 40,
      "house": 1160
   },
   "33": {
      "greenfield": 120,
      "house": 2290
   },
   "34": {
      "greenfield": 230,
      "house": 2170
   },
   "35": {
      "greenfield": 120,
      "house": 1690
   },
   "36": {
      "greenfield": 20,
      "house": 870
   },
   "37": {
      "greenfield": 80,
      "house": 1690
   },
   "38": {
      "greenfield": 120,
      "house": 1950
   },
   "39": {
      "greenfield": 40,
      "house": 1160
   },
   "40": {
      "greenfield": 70,
      "house": 1730
   },
   "41": {
      "greenfield": 30,
      "house": 1160
   },
   "42": {
      "greenfield": 70,
      "house": 1410
   },
   "43": {
      "greenfield": 40,
      "house": 1050
   },
   "44": {
      "greenfield": 130,
      "house": 2120
   },
   "45": {
      "greenfield": 80,
      "house": 1500
   },
   "46": {
      "greenfield": 10,
      "house": 1190
   },
   "47": {
      "greenfield": 20,
      "house": 1150
   },
   "48": {
      "greenfield": 30,
      "house": 990
   },
   "49": {
      "greenfield": 80,
      "house": 1380
   },
   "50": {
      "greenfield": 50,
      "house": 1270
   },
   "51": {
      "greenfield": 90,
      "house": 1550
   },
   "52": {
      "greenfield": 20,
      "house": 790
   },
   "53": {
      "greenfield": 50,
      "house": 1110
   },
   "54": {
      "greenfield": 100,
      "house": 1410
   },
   "55": {
      "greenfield": 20,
      "house": 840
   },
   "56": {
      "greenfield": 110,
      "house": 1650
   },
   "57": {
      "greenfield": 130,
      "house": 1460
   },
   "58": {
      "greenfield": 20,
      "house": 840
   },
   "59": {
      "greenfield": 110,
      "house": 1500
   },
   "60": {
      "greenfield": 90,
      "house": 1820
   },
   "61": {
      "greenfield": 20,
      "house": 980
   },
   "62": {
      "greenfield": 90,
      "house": 1340
   },
   "63": {
      "greenfield": 80,
      "house": 1490
   },
   "64": {
      "greenfield": 60,
      "house": 1720
   },
   "65": {
      "greenfield": 30,
      "house": 1210
   },
   "66": {
      "greenfield": 230,
      "house": 1860
   },
   "67": {
      "greenfield": 160,
      "house": 1760
   },
   "68": {
      "greenfield": 140,
      "house": 1740
   },
   "69": {
      "greenfield": 260,
      "house": 2590
   },
   "70": {
      "greenfield": 30,
      "house": 1000
   },
   "71": {
      "greenfield": 30,
      "house": 1130
   },
   "72": {
      "greenfield": 70,
      "house": 1270
   },
   "73": {
      "greenfield": 100,
      "house": 2400
   },
   "74": {
      "greenfield": 190,
      "house": 3140
   },
   "75": {
      "greenfield": 20000,
      "house": 10650
   },
   "76": {
      "greenfield": 70,
      "house": 1600
   },
   "77": {
      "greenfield": 210,
      "house": 2320
   },
   "78": {
      "greenfield": 280,
      "house": 3290
   },
   "79": {
      "greenfield": 50,
      "house": 1000
   },
   "80": {
      "greenfield": 50,
      "house": 1340
   },
   "81": {
      "greenfield": 50,
      "house": 1240
   },
   "82": {
      "greenfield": 30,
      "house": 1350
   },
   "83": {
      "greenfield": 150,
      "house": 3050
   },
   "84": {
      "greenfield": 160,
      "house": 2120
   },
   "85": {
      "greenfield": 80,
      "house": 1610
   },
   "86": {
      "greenfield": 60,
      "house": 1180
   },
   "87": {
      "greenfield": 20,
      "house": 1090
   },
   "88": {
      "greenfield": 20,
      "house": 940
   },
   "89": {
      "greenfield": 30,
      "house": 1080
   },
   "90": {
      "greenfield": 70,
      "house": 1460
   },
   "91": {
      "greenfield": 330,
      "house": 2590
   },
   "92": {
      "greenfield": 1160,
      "house": 6430
   },
   "93": {
      "greenfield": 520,
      "house": 2980
   },
   "94": {
      "greenfield": 580,
      "house": 3810
   },
   "95": {
      "greenfield": 390,
      "house": 2930
   },
   "2A": {
      "greenfield": 80,
      "house": 3300
   },
   "2B": {
      "greenfield": 70,
      "house": 2340
   }
}
