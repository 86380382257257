import React from 'react'

const firstWord = string => string.replace(/ .*/,'')
const buildId = string => firstWord(string).toLowerCase()

export const CardForm = ({
  img, title, select, selected, imgSelected, index
}) => (
  <button
    data-testid={`btn-cardform${index}`}
    id={`card-${buildId(title)}-${index + 1}`}
    className="card-form-ctn"
    style={{ backgroundColor: `${selected ? '#31325f' : '#fff'}` }}
    onClick={() => select()}
    onKeyPress={(e) => e.preventDefault()}
  >
    <img src={selected ? imgSelected : img} className="img-card-form" />
    <p
      style={{ color: `${selected ? '#fff' : '#31325f'}` }}
      dangerouslySetInnerHTML={{__html: title}}
    />
  </button>
)
