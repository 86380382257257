import React from 'react'
import PropTypes from 'prop-types'

export const StepButton = ({
  step, prevStep, nextStep, send, prevDisabled = false, nextDisabled = false, type = 'one'
}) => (
  <div data-testid="step">
    <div className="ctn-btn-form-simple">
      <button
        onClick={prevStep}
        className="btn is-prev"
        disabled={prevDisabled || step === 1}
        aria-label="prev"
        data-testid="step-prev"
      >
          Précédent
      </button>
      <button
        onClick={nextStep}
        aria-label="next"
        data-testid="step-next"
        disabled={nextDisabled}
        className="btn is-next"
        id={`form-btn-${step}`}
      >
        {type === 'one' ? step < 8 ? 'Suivant' : 'Envoyer' : null}
        {type === 'two' ? step < 5 ? 'Suivant' : 'Envoyer' : null}
      </button>
    </div>
  </div>
)

StepButton.propTypes = {
  step: PropTypes.number,
  prevStep: PropTypes.func,
  nextStep: PropTypes.func,
  send: PropTypes.func
}

StepButton.defaultProps = {
  step: 1,
  prevStep: () => null,
  nextStep: () => null,
  send: () => null
}
