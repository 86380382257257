const theme = {
  candyPrimary: '#31325f',
  candySecondary: '#d95d57',
  candyYellow: '#FCE45C',
  candyPink: 'rgba(255, 191, 190, .1)',
  teal: '#58768f',
  lighterGrey: '#dbe0e4',
  dashboardBackground: '#f2f6fa',
  proGrey: '#eff3f8',
  whiteOpacity: '#fcfdfe',
  smoothTransition: '300ms all ease',
  containerWidth: '1200px',
  lighterGreyOpacity: 'rgba(248,249,250,.8)',
  lightYellowBackground: '#FBF4E4',
  lightBlueBackground: '#F2F6FA',
  lightRedBackground: '#FFEFEF',
  tertaryGreen: '#E6F2E5',
  candyGreen: '#639A5F',
  tertaryGrey: '#F9F9F9',
  lightGrey: '#999999',
}

export default theme
