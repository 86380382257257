/* eslint-disable global-require */

export const data = {
  dataForm: {
    properties: [{
      img: require('../images/desktop/Illustration 51.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_maison_selection.png'),
      title: 'Maison / Immeuble',
      value: 1,
    }, {
      img: require('../images/desktop/Illustration 52.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_appartement_selection@2x.png'),
      title: 'Appartement',
      value: 3,
    }, {
      img: require('../images/desktop/Illustration 53.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_terrain_selection.png'),
      title: 'Terrain nu',
      value: 0,
    }],
    whoAreYou: [{
      img: require('../images/desktop/02_Formulaire_pictogramme_proprietaire.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_proprietaire_selection.png'),
      title: 'Propriétaire',
      value: 0
    }, {
      img: require('../images/desktop/02_Formulaire_pictogramme_proche.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_proche_selection.png'),
      title: 'Proche du propriétaire',
      value: 7
    }, {
      img: require('../images/desktop/02_Formulaire_pictogramme_locataire.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_locataire_selection.png'),
      title: 'Locataire',
      value: 1
    }, {
      img: require('../images/desktop/02_Formulaire_pictogramme_professionnel.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_professionnel_selection.png'),
      title: 'Professionnel(le) de l\'immobilier',
      value: 2
    },
    {
      img: require('../images/desktop/02_Formulaire_pictogramme_acheteur.png'),
      imgSelected: require('../images/desktop/selection/02_Formulaire_pictogramme_locataire_selection.png'),
      title: 'Acheteur',
      value: 8
    }
    ],
    listcontent: [{
      text: 'Remplissez le formulaire en ligne'
    }, {
      text: 'Nos experts analysent votre projet'
    }, {
      text: 'Nous vous recontactons sous 48h avec une faisabilité'
    }]
  }
};
