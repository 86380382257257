import React from 'react'
import PropTypes from 'prop-types'

export const StepLine = ({ step, currentStep }) => (
  <div className="ctn-dot-simple-form">
    {step.map((elem, index) => (
      <span
        className={`dot-simple-form ${index + 1 < currentStep ? 'dot-simple-form--passed' : ''} ${currentStep === index + 1 ? 'dot-simple-form--current' : ''}`}
        key={index}
      />
    ))}
  </div>
)

StepLine.propTypes = {
  step: PropTypes.arrayOf(PropTypes.number),
  currentStep: PropTypes.number
}

StepLine.defaultProps = {
  step: [1, 2, 3, 4, 5, 6],
  currentStep: 1
}
