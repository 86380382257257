import React from 'react'
import { data } from '../../data/tooltipData'

export const FormDisclaimerMobile = () => (
  <div className="form-disclaimer-mobile">
    <ul>
      {
        data.map((tooltip) => (
          <li key={tooltip.question.trim()}>
            <strong>{tooltip.question}</strong>
            <br />
            <p>{tooltip.answer}</p>
          </li>
        ))
      }
    </ul>
  </div>
)
