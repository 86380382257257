import React from 'react'
import isEmail from 'validator/lib/isEmail'
import { useForm } from './form.context'

const validateFrenchTelephoneNumber = number => (/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/).test(number)

const StepContext = React.createContext()

function StepProvider(props) {
  const [step, setStep] = React.useState(1)
  const value = React.useMemo(() => ({
    step,
    setStep
  }), [step])
  return <StepContext.Provider value={value} {...props} />
}

function getNextDisabled(step) {
  return false
}

function getDisabled(step) {
  if (step === 0) return true
  else return false
}

function useStep() {
  const context = React.useContext(StepContext)
  if (!context) {
    throw new Error('useStep must be used within a StepProvider')
  }
  const { step, setStep } = context

  const increment = React.useCallback(() => setStep(s => s + 1), [])
  const jumpIncrement = React.useCallback(() => setStep(s => s + 2), [])
  const decrement = React.useCallback(() => setStep(s => s - 1), [])
  const prevDisabled = getDisabled(step)
  const nextDisabled = getNextDisabled(step)
  const {
    property, setErrors, user, errors, appartement
  } = useForm()

  function getType() {
    if (property.category === 2) return 'two'
    return 'one'
  }
  function handleErrorsFirstScenario() {
    switch (step) {
      case 1: {
        const { category } = property
        if (category === null) {
          return setErrors('category', ['kindOfProperties'])
        }
        setErrors('category', [])
        return increment()
      }

      case 2: {
        const { condition } = property
        if (condition === 0) {
          return setErrors('condition', ['empty'])
        }
        setErrors('condition', [])
        return increment()
      }

      case 3: {
        const { squareMeter } = property
        if (squareMeter === '') {
          return setErrors('squareMeter', ['empty'])
        }
        setErrors('squareMeter', [])
        return increment()
      }

      case 4: {
        const { rooms } = property
        if (rooms === '') {
          return setErrors('rooms', ['empty'])
        }
        if (appartement) {
          setErrors('rooms', [])
          return jumpIncrement()
        }
        setErrors('rooms', [])
        return increment()
      }

      case 5: {
        const { area } = property
        if (area === '') {
          return setErrors('area', ['empty'])
        }
        setErrors('area', [])
        return increment()
      }

      case 6: {
        const { transactionRole } = user
        if (transactionRole === null) {
          return setErrors('transactionRole', ['empty'])
        }
        setErrors('transactionRole', [])
        return increment()
      }

      case 7: {
        const { saleReadiness } = user
        if (saleReadiness === 0) {
          return setErrors('saleReadiness', ['empty'])
        }
        setErrors('saleReadiness', [])
        return increment()
      }

      case 8: {
        const {
          email, firstName, lastName, phone
        } = user

        if (firstName === '') {
          return setErrors('firstName', ['empty'])
        }

        setErrors('firstName', [])

        if (lastName === '') {
          return setErrors('lastName', ['empty'])
        }

        setErrors('lastName', [])

        if (email === '') {
          return setErrors('email', ['Ce champ est requis.'])
        }

        setErrors('email', errors.email.filter(e => e !== 'Ce champ est requis.'))

        if (!isEmail(email)) {
          return setErrors('email', ['Merci d\'utiliser un email valide.'])
        }

        setErrors('email', errors.email.filter(e => e !== 'Merci d\'utiliser un email valide.'))

        if (phone === '') {
          return setErrors('phone', ['Ce champ est requis.'])
        }

        if (!validateFrenchTelephoneNumber(phone)) {
          return setErrors('phone', ['Votre numéro comporte une erreur.'])
        }

        setErrors('phone', [])
        if (errors.email.length > 0) return
        return increment()
      }

      default: {
        return increment()
      }
    }
  }

  function handleErrorsSecondScenario() {
    switch (step) {
      case 1: {
        const { category } = property
        if (category === null) {
          return setErrors('category', ['kindOfProperties'])
        }
        setErrors('category', [])
        return increment()
      }

      case 2: {
        const { area } = property
        if (area === '') {
          return setErrors('area', ['empty'])
        }
        setErrors('area', [])
        return increment()
      }

      case 3: {
        const { transactionRole } = user
        if (transactionRole === null) {
          return setErrors('transactionRole', ['empty'])
        }
        setErrors('transactionRole', [])
        return increment()
      }

      case 4: {
        const { saleReadiness } = user
        if (saleReadiness === 0) {
          return setErrors('saleReadiness', ['empty'])
        }
        setErrors('saleReadiness', [])
        return increment()
      }

      case 5: {
        const {
          email, firstName, lastName, phone
        } = user
        if (firstName === '') {
          return setErrors('firstName', ['empty'])
        }
        setErrors('firstName', [])
        if (lastName === '') {
          return setErrors('lastName', ['empty'])
        }
        setErrors('lastName', [])
        if (email === '') {
          return setErrors('email', ['Ce champ est requis.'])
        }
        setErrors('email', errors.email.filter(e => e !== 'Ce champ est requis.'))
        if (!isEmail(email)) {
          return setErrors('email', ['Merci d\'utiliser un email valide.'])
        }
        setErrors('email', errors.email.filter(e => e !== 'Merci d\'utiliser un email valide.'))

        if (phone === '') {
          return setErrors('phone', ['Ce champ est requis.'])
        }

        if (!validateFrenchTelephoneNumber(phone)) {
          return setErrors('phone', ['Votre numéro comporte une erreur.'])
        }

        setErrors('phone', [])
        if (errors.email.length > 0) return
        return increment()
      }
      default: {
        return increment()
      }
    }
  }

  function incrementWithErrors() {
    if (getType() === 'one') {
      handleErrorsFirstScenario()
    } else {
      handleErrorsSecondScenario()
    }
  }

  return {
    step,
    increment,
    decrement,
    prevDisabled,
    nextDisabled,
    incrementWithErrors
  }
}


export { StepProvider, useStep, StepContext }
