import React from 'react';
import PropTypes from 'prop-types';

const Question = ({ question, active, handleClick }) => (
  <button
    type="button"
    className={active ? 'faq-question faq-question--active' : 'faq-question'}
    onClick={() => handleClick()}
  >
    <h2>
      {question.title}
      <div className="question-cross">
        <span />
        <span />
      </div>
    </h2>
    <p className={!active ? 'hidden' : ''} dangerouslySetInnerHTML={{ __html: question.answer }} />
  </button>
);

Question.propTypes = {
  question: PropTypes.shape({
    title: PropTypes.string,
    answer: PropTypes.string
  }).isRequired,
  active: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default Question;
