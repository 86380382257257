import React, { useEffect } from 'react'
import Chart from 'chart.js/auto'
import { BaseTable, col5Table, doubleHead, singleHead, Rating, Asterich } from './Shared'
import { sellsDistribution, priceDistribution, priceEvolution } from './ChartDatas'

const OldRealEstateData = ({ city, plot, nivGeo }) => {
  const evolution = (type, years) => {
    const dataset = Object.keys(city.transactions_price_evolution[type])
    if (dataset.length === 0) { return '-' }
    const lastYear = dataset.map((x) => new Date(x).getFullYear())
      .sort((a, b) => b - a)[0]
    const lastPrice = city.transactions_price_evolution[type][lastYear + "-01-01"]
    const firstPrice = city.transactions_price_evolution[type][(lastYear - years) + "-01-01"]
    if (!firstPrice || !lastPrice) { return '-' }
    return `${parseFloat(((lastPrice - firstPrice) / firstPrice) * 100).toFixed(2)} %`
  }

  useEffect(() => {
    const ctx = document.getElementById('sellsDistribution').getContext('2d')
    new Chart(ctx, sellsDistribution(plot.transactions.appartments))
    const ctx2 = document.getElementById('priceDistribution').getContext('2d')
    new Chart(ctx2, priceDistribution(plot.transactions.appartments))
    const ctx3 = document.getElementById('priceEvolution').getContext('2d')
    new Chart(ctx3, priceEvolution(city.transactions_price_evolution.appartments, city.transactions_price_evolution.houses))
  }, [])

  return (
    <div className="pdf-content-inside">
      <div className="sub-header">Sur le secteur</div>
      <BaseTable className="green-background width-240 mb-12">
        {singleHead('Prix / m² moyen estimé')}
        <tbody>
          <tr>
            <td>Appartement dans l’ancien :</td>
            <td className="d-flex gap-4">
              <div className="w-100">
                { Math.round(plot.price_old_apartment.adjusted_price_area?.test).toLocaleString('fr-FR') }
                <span> €/m²</span>
              </div>
              <Rating index={plot.price_old_apartment.confidence_index?.test} />
            </td>
          </tr>
          <tr>
            <td>Maison dans l’ancien :</td>
            <td className="d-flex gap-4">
              <div className="w-100">
                { Math.round(plot.price_old_house.adjusted_price_area?.test).toLocaleString('fr-FR') }
                <span> €/m²</span>
              </div>
              <Rating index={plot.price_old_house.confidence_index?.test} />
            </td>
          </tr>
        </tbody>
      </BaseTable>

      <div id="side-tables">
        <BaseTable className="mb-12">
          <thead id="sales-prices-by-type">
            <tr>
              <th colSpan={5}>
                Prix de ventes constatés par typologie *
                <br />
                <span>Au cours des 12 derniers mois</span>
              </th>
            </tr>
          </thead>
          <thead>
            <tr><th colSpan="5">Appartement</th></tr>
          </thead>
          {col5Table(plot.transactions.appartments)}
        </BaseTable>

        <BaseTable className="mb-12">
          {doubleHead('', '')}
          <thead>
            <tr><th colSpan="5">Maison</th></tr>
          </thead>
          {col5Table(plot.transactions.houses)}
        </BaseTable>
      </div>

      <div className="page-2-col">
        <div className="height-240px">
          <canvas id="sellsDistribution" className="canvas-border" />
        </div>
        <div className="height-240px">
          <canvas id="priceDistribution" className="canvas-border" />
        </div>
      </div>

      <div className="sub-header">Sur {nivGeo}</div>
      <div className="page-2-col mb-12 align-start">
        <div className="height-240px">
          <canvas id="priceEvolution" className="canvas-border" />
        </div>
        <BaseTable id="meanPriceEvolution" className="white-background">
          {singleHead('Evolution des moyennes de prix')}
          <tbody>
            <tr>
              <td className="sub-head">Appartement</td>
              <td className="sub-head">Maison</td>
            </tr>
            <tr>
              <td className="price-evaluation w-50">
                <div className="d-flex flex-column p-15">
                  <div className="bold font-size-14px">
                    {evolution('appartments', 1)}<span className="no-bold font-size-12px"> sur 6 mois**</span>
                  </div>
                  <div className="bold font-size-14px">
                    {evolution('appartments', 5)}<span className="no-bold font-size-12px"> sur 5 ans</span>
                  </div>
                </div>
              </td>
              <td className="price-evaluation w-50">
                <div className="d-flex flex-column p-15">
                  <div className="font-size-14px">
                    {evolution('houses', 1)}<span className="font-size-12px"> sur 6 mois**</span>
                  </div>
                  <div className="font-size-14px">
                    {evolution('houses', 5)}<span className="font-size-12px"> sur 5 ans</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </BaseTable>
      </div>
      <Asterich flexGrow>
        <div>
          <span className="color-candy-primary">* </span>
          {`Les données sont issues de la base des notaires mise à jour tous les six mois.
          Aussi, les informations transmises se réfèrent à la date de dernière diffusion des données par Etalab.
          Les données présentées correspondent à un périmètre de ${plot.transactions_buffer === 500 ? '500 m' : '1 km'} autour de l'adresse.`}
          <br />
          <span className="color-candy-primary">** </span>
          sur la période du 1er janvier au 30 juin 2024.
        </div>
      </Asterich>
    </div>
  )
}

export default OldRealEstateData
