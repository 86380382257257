import React, { useEffect } from 'react'
import { Toggle } from 'react-toggle-component'
import { TitleSimpleForm } from './TitleSimpleForm'
import { Errors } from './error'
import { useForm } from '../../context/form.context'
import { checkEmailIfExist } from './api'
import { addEvent, triggerCrisp } from '../../utils/utils'

const getCorrectStyleFemaleGender = gender => (gender === 0 || gender === '0' ? 'is-radio-select' : '')
const getCorrectStyleMaleGender = gender => (gender === 1 || gender === '1' ? 'is-radio-select' : '')
const getPhoneValue = phone => (typeof phone === 'object' ? phone.formatInternational() : phone)

const checkIfInputInFocus = () => {
  const inputs = document.getElementsByTagName('input')
  let focused = 0
  Array.prototype.forEach.call(inputs, (input) => {
    if (input === document.activeElement) {
      focused = 1
    }
  })
  return focused
}

export const AnswerForm = ({
  setGender,
  gender,
  handleChange,
  firstName,
  lastName,
  email,
  phone,
  subscribedToNewsletter,
  thirdPartySharingOptOut,
  setSubscribeToNewsletter,
  step,
  id
}) => {
  const { errors, setErrors } = useForm()

  useEffect(() => {
    addEvent(document, 'mouseleave', function(e) {
      e = e ? e : window.event
      var from = e.relatedTarget || e.toElement
      if (!from || from.nodeName == "HTML") {
        if (checkIfInputInFocus() === 0) {
          triggerCrisp('form_information_mouse_leave')
        }
      }
    })
    return function emoveTrigger() {
      document.removeEventListener('mouseleave', triggerCrisp('form_information_mouse_leave'))
    }
  }, [])

  return (
    <div
      className="container-answers fadeIn"
      id={id}
    >
      <TitleSimpleForm
        itemIndex={step}
        title="Vous êtes à un clic de votre estimation !"
      />
      <div>
        <form className="form-mobile">
          <div className="radio-group">
            <div className="radio">
              <label>
                <div className="round-btn-radio">
                  <div
                    aria-label="Mr"
                    className={`btn-radio ${getCorrectStyleMaleGender(gender)}`}
                    onClick={() => handleChange('gender', 1)}
                    name="gender"
                  />
                </div>
                M
                {' '}
              </label>
            </div>
            <div className="radio">
              <label>
                <div className="round-btn-radio">
                  <div
                    aria-label="Mme"
                    className={`btn-radio ${getCorrectStyleFemaleGender(gender)}`}
                    onClick={() => handleChange('gender', 0)}
                    name="gender"
                  />
                </div>
                Mme
              </label>
            </div>
          </div>
          <input
            aria-label="firstName"
            name="firstName"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={firstName}
            placeholder="votre prénom"
            className="input-email"
          />
          {errors.firstName.length > 0
          && (
            <Errors
              message="Ce champ est requis."
            />
          )}
          <input
            name="lastName"
            aria-label="lastName"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={lastName}
            placeholder="votre nom"
            className="input-email"
          />
          {errors.lastName.length > 0
          && (
            <Errors
              message="Ce champ est requis."
            />
          )}
          <input
            name="email"
            aria-label="email"
            type="email"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={email}
            placeholder="votre email"
            className="input-email"
            onBlur={() => {
              checkEmailIfExist(email).then(data => {
                if(data.error === 'Email existant') {
                  setErrors('email', [...errors.email, 'Email existant'])
                } else if(data.message === 'Email libre') {
                  setErrors('email', errors.email.filter(e => e !== 'Email existant'))
                }
              }).catch(e => console.log('er', e))
            }}
          />
          {errors.email.length > 0
          && (
            <Errors
              message={errors.email[0]}
            />
          )}
          <input
            name="phone"
            aria-label="phone"
            type="tel"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={getPhoneValue(phone)}
            placeholder="votre téléphone"
            className="input-email"
            onBlur={() => {

            }}
          />
          {errors.phone.length > 0
            && (
              <Errors
                message="Ce champ est requis."
              />
          )}
          <div className="ctn-cgu">
            <div>
              <label htmlFor="toggle-2">
                <Toggle
                  onToggle={(e) => handleChange('subscribedToNewsletter', !subscribedToNewsletter)}
                  checked={subscribedToNewsletter}
                  leftBackgroundColor="#9A9A9A"
                  rightBackgroundColor="#31325f"
                  borderColor="transparent"
                  knobColor="white"
                  name="news"
                  className={subscribedToNewsletter ? 'is-checked' : ''}
                />
                Je souhaite m'abonner à la newsletter.
              </label>
            </div>
          </div>
          <div className="ctn-cgu">
            <div>
              <label htmlFor="toggle-3">
                <Toggle
                  onToggle={() => handleChange('thirdPartySharingOptOut', !thirdPartySharingOptOut)}
                  checked={thirdPartySharingOptOut}
                  leftBackgroundColor="#9A9A9A"
                  rightBackgroundColor="#31325f"
                  borderColor="transparent"
                  knobColor="white"
                  name="third-party"
                  className={thirdPartySharingOptOut ? 'is-checked' : ''}
                />
                  <p>Je m'oppose à ce que mon adresse postale et mon numéro de téléphone soient transmis aux partenaires (<a href="https://www.cityandyou.com/charte-de-confidentialite#recipient" target="_blank">charte de confidentialité</a>) de la société City&You à des fins de prospection commerciale.</p>
              </label>
            </div>
          </div>
        </form>
        <div className="form-disclaimer">
          <p>
            City&You utilise vos données personnelles pour remplir sa mission :  vous accompagner dans la vente de votre bien à un opérateur immobilier.
            Ces informations sont confidentielles et ne seront jamais partagées sans votre accord. <a
            href="/charte-de-confidentialite?theme=formulaire" target="_blank">En savoir plus sur la gestion de vos
            données et vos droits</a>.
          </p>
        </div>
      </div>
    </div>
  )
}
