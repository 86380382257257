import React from 'react'
import moment from 'moment'
import { BaseTable, PlotMap, renderTr } from './Shared'

const PluDetails = ({
  city,
  mapboxToken,
  mapTypeProspection,
  plot,
}) => {
  const baseUrl = `https://api.mapbox.com/styles/v1/${mapTypeProspection}/static`
  const width = 580
  const height = 420
  const location = `${plot.coordinates.lng},${plot.coordinates.lat},${plot.zoom}`
  const geometryToGeojson = {
    type: 'Feature',
    geometry: plot.geometry_to_geojson,
    properties: {
      'stroke': '#31325f',
      'stroke-width': 2,
      'fill-opacity': 0
    }
  }
  const geojsonString = JSON.stringify(geometryToGeojson)
  const encodedGeoJSON = `geojson(${encodeURIComponent(geojsonString)})`
  const mapboxStaticImageUrl = `${baseUrl}/${encodedGeoJSON}/${location}/${width}x${height}@2x?access_token=${mapboxToken}`

  const documentTypeText = (typeNumber) => {
    switch (typeNumber) {
      case 0:
        return 'PLU'
      case 1:
        return 'PLU-i'
      case 2:
        return 'PLU-i sectoriel'
      case 3:
        return 'Carte communale'
      case 4:
        return 'RNU'
      default:
        return 'PLU'
    }
  }

  const riskLevelTranslation = (level) => {
    switch (level) {
      case 'medium':
        return 'moyen'
      case 'high':
        return 'fort'
      default:
        return 'faible'
    }
  }

  const uniqueArray = (array) => Array.from(new Set(array))

  const Icon = () => (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.5" r="4" fill="#FFEB78" />
    </svg>
  )

  const GreyIcon = () => (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.5" r="4" fill="#31325f" />
    </svg>
  )

  const riskTranslation = [
    {
      label: 'Remontée de nappe',
      code: 'nappes',
      minLevel: 2
    },
    {
      label: 'Installation industrielle classée (ICPE)',
      code: 'icpe',
      minLevel: 0
    },
    {
      label: 'Pollution des sols',
      code: 'pollution',
      minLevel: 0
    },
    {
      label: "Ruissellement (PPRI)",
      code: 'floodings_ruissellement',
      minLevel: 0
    },
    {
      label: "Débordement de cours d'eau (PPRI)",
      code: 'floodings_debordement',
      minLevel: 0
    },
    {
      label: "Submersion marine (PPRI)",
      code: 'floodings_submersion',
      minLevel: 0
    },
    {
      label: 'Retrait-gonflement des argiles',
      code: 'argile',
      minLevel: 0
    },
    {
      label: "Plan d'exposition au bruit",
      minLevel: 0,
      code: 'peb'
    },
    {
      label: "Servitude d'utilité publique liée au périmètre de 500 m des Monuments Historiques",
      minLevel: 0,
      code: 'mh'
    }
  ]

  return (
    <div className="d-flex flex-column pdf-content-inside">
      <div className="page-2-col align-start">
        <div>
          <BaseTable className="col-2 mb-12">
            {plot.zone_info.length > 2 && (
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '17%' }} />
                <col style={{ width: '17%' }} />
                <col style={{ width: '17%' }} />
              </colgroup>
            )}
            <tbody>
              {renderTr('Nom de la zone :', plot.zone_info, 'name', '', null)}
              {plot.zone_info.length < 3 && renderTr('Type de zone :', plot.zone_info, 'qualification')}
              <tr className="empty" />
              {renderTr('Hauteur maximale :', plot.zone_info, 'height_max', 'm')}
              <tr className="empty" />
              {renderTr('Nombre de niveaux :', plot.zone_info, 'height_max', '', (vl) => Math.floor(vl / 3))}
              {renderTr('Emprise au sol :', plot.zone_info, 'ces_1', '%', (vl) => Math.round(vl * 100))}
              {renderTr('Bande de constructibilité :', plot.zone_info, 'bc', 'm')}
              {renderTr('Nbre de places de parking :', plot.zone_info, 'parking_spaces')}
              <tr className="empty" />
              <tr>
                <td>Doc. en cours :</td>
                <td>{documentTypeText(city.urban_document?.ud_type)}</td>
              </tr>
              <tr>
                <td>Date d'appro. :</td>
                <td>{city.urban_document?.approval_date ? moment(city.urban_document?.approval_date).format('DD/MM/YYYY') : '-'}</td>
              </tr>
            </tbody>
          </BaseTable>

          { city.urban_document?.revision_state && (
            <BaseTable className="mb-12 red-background">
              <tbody>
                <tr>
                  <td className="bold">{`${city.urban_document?.revision_state} de ${documentTypeText(city.urban_document?.revision_type)} en cours`}</td>
                </tr>
                <tr>
                  <td>Lancée par : {city.urban_document?.owner_name}</td>
                </tr>
                <tr>
                  <td>Le : {moment(city.urban_document?.revision_date).format("DD/MM/YYYY")}</td>
                </tr>
              </tbody>
            </BaseTable>
          )}

          <BaseTable className="mb-12 yellow-background">
            <thead>
              <tr><th>Prescriptions</th></tr>
            </thead>
            <tbody>
              { plot.prescriptions.length > 0 && uniqueArray(plot.prescriptions).sort((a, b) => a.name.localeCompare(b.name)).map((pres) => (
                <tr key={`pres-${pres.id}`}>
                  <td className="d-flex gap-5 a-center">
                    <Icon />
                    <span className="ml-3">
                      <strong>{![13].includes(pres.group) && pres.name}{pres.subCategory && pres.group !== 20 ? ` ${pres.subCategory}` : ""}{pres.group === 13 && !pres.subCategory ? "Périmètre de reflexion" : '' }</strong>
                      {pres.libelle && pres.displayName && !city.available_prescriptions.includes(pres.libelle) ? ` : "${pres.libelle}"` : ''}
                      {pres.group === 20 && pres.subCategory ? ` : "${pres.subCategory}"` : ''}
                    </span>
                  </td>
                </tr>
              ))}
              { city.available_prescriptions && city.available_prescriptions.filter(pres => !plot.prescriptions.map((pr) => pr.name).includes(pres)
                && city.prescriptions_groups.includes(pres)).sort().map((pres) => (
                  <tr className="absent" key={`pres-${pres}`}>
                    <td className="d-flex gap-5 a-center">
                      <GreyIcon />
                      <span className="ml-3">{pres}: abs.</span>
                    </td>
                  </tr>
              ))}
            </tbody>
          </BaseTable>
        </div>

        <div>
          <PlotMap url={mapboxStaticImageUrl} className="mb-12" />
          <BaseTable className="yellow-background">
            <thead>
              <tr><th>Risques</th></tr>
            </thead>
            <tbody>
              { plot.risks.filter(r => r.category != 'floodings').map((risk) => ({ ...risk, catName: riskTranslation.find((ri) => ri.code === risk.category).label }))
                .sort((a, b) => a.catName.localeCompare(b.catName)).map((risk, index) => (
                  <tr key={`${index}-present`}>
                    <td className="d-flex gap-5 a-center">
                      <Icon />
                      <span className="ml-3 mw-90">
                        <strong>{risk.catName}</strong>
                        { risk.level !== 'undefined' && risk.category !== 'peb' && !risk.ppriLevel && (` : Aléa ${riskLevelTranslation(risk.level)}`)}

                        {
                          risk.category === 'peb' && risk.name
                        }

                        {
                          risk.ppriLevel && ` : ${risk.ppriLevel}`
                        }
                      </span>
                    </td>
                  </tr>
                ))}
              { riskTranslation.filter((ri) => !plot.risks.map((r) => r.category).includes(ri.code))
                .sort((a, b) => a.label.localeCompare(b.label)).map((risk, index) => (
                  <tr key={`${index}-absent`} className="absent">
                    <td className="d-flex gap-5 a-center">
                      <GreyIcon />
                      <span className="ml-3">{risk.label}: abs.</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </BaseTable>
        </div>
      </div>
    </div>
  )
}

export default PluDetails
