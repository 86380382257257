import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

export const NavElement = ({ handleClick, active }) => (
  <nav>
    <div className="nav-dropdown nav-dropdown-small nav-dropdown-first nav-dropdown--button">
      <span>
        <a
          href="/accompagnement"
          className={active === 1 ? 'active-btn' : ''}
        >
          Nos services
        </a>
      </span>

      <div className="nav-dropdown-container">
        <div className="nav-dropdown-content">
          <a href="/accompagnement">L&apos;accompagnement</a>
          <a href="/qui-sommes-nous">Qui sommes-nous ?</a>
          <a href="/en-savoir-plus">En savoir +</a>
        </div>
      </div>
    </div>
    <div className="nav-dropdown nav-dropdown-small nav-dropdown--button">
      <span>
        <a
          href="/promotion-immobiliere"
          className={active === 2 ? 'active-btn' : ''}
        >
          Promotion Immobilière
        </a>
      </span>

      <div className="nav-dropdown-container">
        {
          isMobile
            ? (
              <div className="nav-dropdown-content">
                <a href="/promotion-immobiliere">Promotion immobilière</a>
                <a href="/blog/">Guide de l&apos;mmobilier</a>
                <a href="/collectivites">Partenariat Collectivités</a>
                <a href="/professionnels">Partenariat Professionnels</a>
              </div>
            )
            : (
              <div className="nav-dropdown-content">
                <a href="/promotion-immobiliere">Qu’est-ce que c’est ?</a>
                <a href="/promotion-immobiliere#promotion-immobiliere-data">Les chiffres en 2021</a>
                <a href="/promotion-immobiliere#avec-city-and-you">La promotion avec City&You</a>
              </div>
            )
        }
      </div>
    </div>
    {
      !isMobile && (
        <div className="nav-dropdown nav-dropdown--button">
          <a
            className="button-alternative"
            href="/blog/"
          >
            Guide de l'immobilier
          </a>
        </div>
      )
    }
  </nav>
)

NavElement.propTypes = {
  handleClick: PropTypes.func,
}

NavElement.defaultProps = {
  handleClick: () => null
}
