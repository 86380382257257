import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { camelize, Header, SerialDataTable, Footer } from './Shared'

const Owners = ({ address, owners }) => {
  moment.locale('fr')
  const PAGE_SIZE = 19
  const TITLE_LINES = 2

  // CALCULATE NUMBER OF PAGES NEEDED IN TOTAL FOR OWNERS (TAKE INTO ACCOUNT 1 TITLE FOR EACH CATEGORY)
  const coprosLength = (owners.copros.length > 0 ? TITLE_LINES : 0) + owners.copros.length
  const ownersLength = (owners.owners.length > 0 ? TITLE_LINES : 0) + owners.owners.length
  const residentLength = (owners.residents.length > 0 ? TITLE_LINES : 0) + owners.residents.length
  const howManyPages = Math.ceil((coprosLength + ownersLength + residentLength) / PAGE_SIZE)
  const pageBreakIndexes = []
  for (let i = 1; i <= howManyPages; i++) { pageBreakIndexes.push(PAGE_SIZE * i) }

  // SPLIT EACH CATEGORY TABLE INTO SUB-TABLES ARRAY, BASED ON MAX PAGE SIZE AND INCREMENTED INDEX
  let incrementedIndex = 0
  const handlePageBreak = (itemArray) => {
    const results = []
    if (itemArray.length === 0) { return results }
    // increment title
    incrementedIndex += TITLE_LINES

    let currentIndex = 0 // s'incrémente quand on tombe sur breakIndex
    itemArray.forEach((_, index) => {
      if (pageBreakIndexes.includes(incrementedIndex)) {
        results.push(itemArray.slice(currentIndex, index + 1))
        currentIndex = index + 1
      }
      incrementedIndex += 1
    })

    if (currentIndex !== itemArray.length) {
      results.push(itemArray.slice(currentIndex))
    }

    return results
  }

  const coprosTables = handlePageBreak(owners.copros)
  const ownersTables = handlePageBreak(owners.owners)
  const residentsTables = handlePageBreak(owners.residents)

  // ADD NULL VALUES TO GET 3 ARRAYS OF LENGTH = HOWMANY PAGES
  const coprosTablesLength = coprosTables.length
  const ownersTablesLength = ownersTables.length
  // 1) copros : add null at the end
  for (let i = coprosTablesLength; i < howManyPages; i++) {
    coprosTables.push(null)
  }
  // 2) owners : add null at the beginning and at the end
  for (let i = 0; i < coprosTablesLength - 1; i++) {
    ownersTables.unshift(null)
  }
  for (let i = (coprosTablesLength + ownersTablesLength) - 1; i < howManyPages; i++) {
    ownersTables.push(null)
  }
  if (ownersTables.length < howManyPages) { ownersTables.unshift(null) } // Manual fix when 2nd table start on next page
  // 3) residents : add null at the beginning
  for (let i = 0; i < (coprosTablesLength - 1 + ownersTablesLength - 1); i++) {
    residentsTables.unshift(null)
  }
  if (residentsTables.length < howManyPages) { residentsTables.unshift(null) } // Manual fix when 2nd table start on next page

  const CoprosTable = ({copros}) => (
    <SerialDataTableOwners>
      <thead className="line-height-10">
        <tr>
          <th className="w-7 pl-5 pr-5">Parcelle</th>
          <th className="w-20">Nom</th>
          <th className="w-13">Immatriculation</th>
          <th className="w-7">Nbre de lots total</th>
          <th className="w-7">Habitation</th>
          <th className="w-7">Activité</th>
          <th className="w-7"><span>Station-</span><span>nement</span></th>
          <th className="w-7">Résidence service</th>
          <th className="w-10">Année de construction</th>
          <th className="w-15"><span>Nom du</span><span>Syndic et SIREN</span></th>
        </tr>
      </thead>
      <tbody>
        { copros.map((copro, index) => {
          return (
            <React.Fragment key={`${index}-${copro.id}`}>
              <tr className={`height-40px grey-background`}>
                <td className="pl-5 pr-5">{copro.land_registry_id_short}</td>
                <td className="bold td-container"><div className="multi-line-ellipsis">{camelize(copro.copro_name)}</div></td>
                <td>{copro.copro_admin_id || '-'}</td>
                <td className="bold">{copro.nb_lot_tot ? Math.round(copro.nb_lot_tot) : '-'}</td>
                <td>{copro.nb_log ? Math.round(copro.nb_log) : '-'}</td>
                <td>{copro.nb_lot_tertiaire ? Math.round(copro.nb_lot_tertiaire) : '-'}</td>
                <td>{copro.nb_lot_garpark ? Math.round(copro.nb_lot_garpark) : '-'}</td>
                <td>-</td>
                <td>{copro.building_year || '-'}</td>
                { copro.syndic_siren ? (
                    <td className="bold td-container">
                      <div className="single-line-ellipsis">{camelize(copro.syndic_name)}</div>
                      <div>{copro.syndic_siren}</div>
                    </td>

                ) : <td>-</td> }
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </SerialDataTableOwners>
  )

  const OwnersTable = ({owners}) => (
    <SerialDataTableOwners>
      <thead className="line-height-10">
        <tr>
          <th className="w-7 pl-5 pr-5">Parcelle</th>
          <th className="w-28">Nom</th>
          <th className="w-10">Forme</th>
          <th className="w-15">SIREN</th>
          <th className="w-40" />
        </tr>
      </thead>
      <tbody>
        { owners.map((owner, index) => {
          return (
            <React.Fragment key={`${index}-${owner.siren}`}>
              <tr className={`height-40px green-background`}>
              <td className="pl-5 pr-5">{owner.land_registry_id_short}</td>
                <td className="bold td-container"><div className="multi-line-ellipsis">{camelize(owner.name)}</div></td>
                <td>{owner.form}</td>
                <td>{owner.siren}</td>
                <td />
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </SerialDataTableOwners>
  )

  const ResidentsTable = ({residents}) => (
    <SerialDataTableOwners>
      <thead className="line-height-10">
        <tr>
          <th className="w-7 pl-5 pr-5">Parcelle</th>
          <th className="w-20">Nom</th>
          <th className="w-28">Activité</th>
          <th className="w-10">NAF</th>
          <th className="w-10">SIREN</th>
          <th className="w-10"><span>Date de</span><span>domiciliation</span></th>
          <th className="w-10"><span>Durée</span><span>d'occupation</span></th>
        </tr>
      </thead>
      <tbody>
        { residents.map((resident, index) => {
          return (
            <React.Fragment key={`${index}-${resident.siren}`}>
              <tr className={`height-40px red-background`}>
                <td className="pl-5 pr-5">{resident.land_registry_id_short}</td>
                <td className="bold td-container"><div className="multi-line-ellipsis">{camelize(resident.denominationUniteLegale)}</div></td>
                <td className="td-container"><div className="multi-line-ellipsis">{camelize(resident.naf_label)}</div></td>
                <td>{resident.activitePrincipaleEtablissement}</td>
                <td>{resident.siren}</td>
                <td>{moment(resident.dateCreationEtablissement).format('DD/MM/YYYY')}</td>
                <td>{resident.dureeOccupationEtablissementAnnees?.toFixed(1)}</td>
              </tr>
            </React.Fragment>
          )
        })}
      </tbody>
    </SerialDataTableOwners>
  )

  return (Array.from({ length: howManyPages }).map((_, index) => (
    <div className="pdf-content">
      <Header address={address} title="Propriétaire(s) et occupant(s)" />
      <div className="pdf-content-inside">
        {/* Copros */}
        { owners.copros.length > 0 && coprosTables[index] && !coprosTables[index - 1] && (
          <div className="sub-header">Copropriété{owners.copros.length > 1 ? 's' : ''}</div>
        )}
        { coprosTables[index] && <CoprosTable copros={coprosTables[index]} /> }
        {/* Propriétaires */}
        { owners.owners.length > 0 && ownersTables[index] && !ownersTables[index - 1] && (
          <div className="sub-header">Propriétaire{owners.owners.length > 1 ? 's' : ''}</div>
        )}
        { ownersTables[index] && <OwnersTable owners={ownersTables[index]} /> }
        {/* Occupants */}
        { owners.residents.length > 0 && residentsTables[index] && !residentsTables[index - 1] && (
          <div className="sub-header">Occupant{owners.residents.length > 1 ? 's' : ''}</div>
        )}
        { residentsTables[index] && <ResidentsTable residents={residentsTables[index]} /> }
      </div>
      <Footer />
    </div>
  )))
}

const SerialDataTableOwners = styled(SerialDataTable)`
  padding-top: 0px;
  thead {
    display: table-row-group;
    break-inside: avoid-page;
    tr {
      line-height: 14px;
    }
    th span {
        display: block;
      }
    }
  }
`

export default Owners
