const circles = document.getElementsByClassName('timeline-content')

const isInViewport = (el) => {
  const bounding = el.getBoundingClientRect()
  let offset

  if (window.screen.width >= 980 || document.getElementsByClassName('timeline-content--activated').length === 0) {
    offset = 200
  } else if (window.screen.width >= 980 || document.getElementsByClassName('timeline-content--activated').length > 0) {
    offset = 100
  } else {
    offset = 20
  }

  return (
    bounding.bottom <= (window.innerHeight - offset || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const trackHeight = (id) => {
  const timeline = document.getElementById('timeline-complete')

  timeline.style.height = `${((id - 1) / circles.length) * 100}%`
}

const fixProgressBar = () => {
  const timeline = document.getElementById('timeline-mobile')
  timeline.classList.add('timeline-mobile--fixed')
  timeline.classList.remove('timeline-mobile--terminated')
}

const unfixProgressBar = () => {
  const timeline = document.getElementById('timeline-mobile')
  timeline.classList.remove('timeline-mobile--fixed')
  timeline.classList.remove('timeline-mobile--terminated')
}

const terminateProgressBar = () => {
  const timeline = document.getElementById('timeline-mobile')
  timeline.classList.remove('timeline-mobile--fixed')
  timeline.classList.add('timeline-mobile--terminated')
}

const barDeparting = () => {
  const timelineMobile = document.getElementById('timeline-mobile')
  const firstTitle = circles[0].getElementsByTagName('h3')[0]
  const activeSteps = document.getElementsByClassName('timeline-content--activated')

  const offset = (window.innerHeight - 430) / 2

  return (timelineMobile.getBoundingClientRect().top < offset && firstTitle.getBoundingClientRect().top < offset)
}

const barArrived = () => {
  const timelineMobile = document.getElementById('timeline-mobile')
  const lastTitle = circles[circles.length - 1].getElementsByTagName('h3')[0]
  const activeSteps = document.getElementsByClassName('timeline-content--activated')

  return (timelineMobile.getBoundingClientRect().bottom > lastTitle.getBoundingClientRect().top && activeSteps.length > 5 && isInViewport(lastTitle))
}

window.addEventListener('scroll', () => {
  Array.from(circles).forEach((el) => {
    if (isInViewport(el)) {
      el.classList.add('timeline-content--activated')
      el.classList.remove('timeline-content--active')
      trackHeight(el.id.split('-')[2])
    } else {
      el.classList.remove('timeline-content--activated')
      el.classList.remove('timeline-content--active')
    }
  })

  const activeSteps = document.getElementsByClassName('timeline-content--activated')
  const lastActiveStep = activeSteps[activeSteps.length - 1]

  if (lastActiveStep) {
    lastActiveStep.classList.add('timeline-content--active')
  }
})

// GA
const serviceCTA = document.getElementById('service-cta')
const serviceCTATimeline = document.getElementById('service-cta-timeline')

window.onload = () => {
  serviceCTA.addEventListener('click', () => {
    const { analytics } = window
    analytics.track('Services - Motion Design - Bouton Estimation')
  })

  serviceCTATimeline.addEventListener('click', () => {
    const { analytics } = window
    analytics.track('Services - Timeline - Bouton Estimation')
  })
}
