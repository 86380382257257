import React from 'react'
import { useStep } from '../../context/step.context'
import { useForm } from '../../context/form.context'
import { StepLine } from '../SimpleForm/stepline'
import { StepButton } from '../SimpleForm/StepButton'

export const Step = React.memo(({ type }) => {
  const {
    step, increment, decrement, prevDisabled, nextDisabled, incrementWithErrors
  } = useStep()
  const {
    property, setErrors, user, errors
  } = useForm()

  if ((type === 'one' && step >= 9) || (type === 'two' && step >= 6)) {
    return null
  }
  return (
    <div>
      <div className="ctn-button-simple-form">
        <StepButton
          nextStep={incrementWithErrors}
          prevStep={decrement}
          step={step}
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          type={type}
        />
      </div>
      <div className="ctn-stepline">
        <StepLine step={type === 'one' ? [1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 3, 4, 5]} currentStep={step} />
      </div>
    </div>
  )
})
