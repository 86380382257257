import React from 'react'
import PropTypes from 'prop-types'

export const TitleSimpleForm = ({itemIndex = '', title}) => (
  <h2 className="titleAnswersSimpleForm" data-testid="title-form">
    {itemIndex && <b>{itemIndex} > </b>}
    {title}
  </h2>
)

TitleSimpleForm.propTypes = {
  itemIndex: PropTypes.number,
  title: PropTypes.string
}

TitleSimpleForm.defaultProps = {
  itemIndex: 1,
  title: ''
}
