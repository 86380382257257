import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import YouTube from 'react-youtube'
import { estimateAppear, makeIdAppear } from './FormAnimation'
import TeamPicture from '../../images/desktop/simpleform/team.jpg'
import AddedValueIllustration from '../../images/desktop/simpleform/estimate_illustration_added_value.png'
import EstimateIllustration from '../../images/desktop/simpleform/estimate_illustration.png'
import { greenfieldEstimation } from './api'
import { useForm } from '../../context/form.context'

export const toolTip1 = 'La pertinence de la simulation réalisée dépend des données de marché disponibles sur votre secteur'
const toolTip2 = 'L’augmentation moyenne de la valeur constatée sur les offres obtenues par City&You est de + 30%.'

const youtubeOpts = {
  playerVars: {
    loop: 1,
    playlist: 'YoMlSx-2-xI',
    modestbranding: 1
  }
}

const addedValueAppear = () => {
  makeIdAppear('estimation-chart')
  document.getElementById('chart-added-value').classList.add('added-value-animation')
}

export const formatPrice = price => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const Estimation = () => {
  const categoryType = ['Maison/Immeuble', 'Appartement', 'Terrain nu']
  const {
    setEstimate, estimation = {}, address = '', property = {
      category: 0,
      area: 0,
      squareMeter: 0,
      condition: 0
    }, user = {}
  } = useForm()

  useEffect(() => {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ 'event': 'form-completed' })
    }
    setTimeout(() => estimateAppear(), 1000)
    setTimeout(() => addedValueAppear(), 1000)
    return () => {

    }
  })

  let {
    confidenceMin = 0, predictedPrice = 0, confidenceMax = 0, confidenceIndex = 0
  } = estimation

  if (property.category === 2) {
    confidenceMin = greenfieldEstimation(confidenceMin, property.area, address)
    confidenceMax = greenfieldEstimation(confidenceMax, property.area, address)
    predictedPrice = greenfieldEstimation(predictedPrice, property.area, address)
    confidenceIndex = 2
  }

  return (
    <div
      className="container-answers fadeIn"
      id="form-final"
    >
      <header>Votre bien</header>
      <p>
        {address}
        <br />
        {categoryType[property.category]}
        {' '}
        -
        {' '}
        {
          property.category !== 2
          && `Bien de ${property.squareMeter} m² - Terrain de `
        }
        {property.area}
        {' '}
        m²
      </p>
      <div className="estimation-block">
        <h2>Estimation de la valeur actuelle de votre bien</h2>
        <ul id="animation-estimates" className="opacity-none">
          <li>
            <span>
              {formatPrice(confidenceMin.toFixed())}
              {' '}
              €
            </span>
            <span>Estimation basse</span>
          </li>
          <li>
            <span>
              {formatPrice(predictedPrice.toFixed())}
              {' '}
              €
            </span>
            <span>Estimation moyenne</span>
          </li>
          <li>
            <span>
              {formatPrice(confidenceMax.toFixed())}
              {' '}
              €
            </span>
            <span>Estimation haute</span>
          </li>
        </ul>
        <div id="trust-index">
          <p data-tip={toolTip1}>
            Indice de pertinence
            <sup>
              (1)
            </sup>
          </p>
          <ReactTooltip className="tooltip" />
          <ul>
            {
              [0, 1, 2, 3, 4].map((e, i) => (i < confidenceIndex ? <li className="full" key={`confidence-index-bullet-${e}`} />
                : <li className="empty" key={`confidence-index-bullet-${e}`}/>))
            }
          </ul>
        </div>
        <p className="tooltip-mobile" id="mobile-tooltip-estimate">
          <sup>(1)</sup>
          {toolTip1}
        </p>
      </div>
      <h2 className="mobile-padded">Si votre bien permet la réalisation d’une opération de promotion immobilière : </h2>
      <div id="estimation-chart" className="opacity-none">
        <div id="chart-added-value">
          <span>+ 30 %</span>
        </div>
        <div className="chart-mean-container">
          <img alt="Illustration" src={EstimateIllustration}/>
          <img alt="Illustration" src={AddedValueIllustration}/>
          <div className="chart-mean">
            <span>Estimation moyenne</span>
          </div>
        </div>
      </div>
      <p id="estimation-chart-legend" data-tip={toolTip2}>
        Estimation de la plus-value potentielle de votre bien avec City&You
        <sup>(2)</sup>
      </p>
      <p className="tooltip-mobile" id="mobile-tooltip-chart">
        <sup>(2)</sup>
        {toolTip2}
      </p>
      <div className="estimation-block estimation-block--yellow">
        <div className="estimation-block-center">
          <h2>L’équipe City&You à votre service</h2>
          <p>
            <img id="team-picture" alt="Équipe" src={TeamPicture}/>
            Nous revenons vers vous dans les meilleurs délais afin de mieux comprendre votre projet de vente et de voir
            ensemble si votre terrain permet la réalisation d'une opération de promotion immobilière.
            <br/>
            Vous avez une question ?
            {' '}
            <a href="mailto:immo@cityandyou.com">Contactez-nous !</a>            .
          </p>
          <h2>L'offre City&You</h2>
          <div className="estimation-video">
            <YouTube
              videoId="YoMlSx-2-xI"
              opts={youtubeOpts}
            />
            <div className="estimation-video-text isIE-margin-left">
              <p>
                Vous souhaitez en savoir plus sur les étapes de la vente à un promoteur immobilier et sur la manière
                dont nous vous obtenons des offres d'achat ?
              </p>
              <p>
                Pour les détails et les conditions, nous vous invitons à consulter <a href="/en-savoir-plus">la rubrique FAQ</a>.
              </p>
              <p>
                Découvrez notre offre d'accompagnement
              </p>
              <a className="estimation-video-cta" href="/accompagnement">Découvrez l'offre</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Estimation
