import React, { useState } from 'react'
import { GoogleSearchInput } from '../components/inscription/googleplace/googleplaces'
import { geocodeByAddress } from 'react-places-autocomplete'

require('es6-promise').polyfill()
require('isomorphic-fetch')

const searchOptions = {
  componentRestrictions: {
    country: ['fr']
  },
  types: ['address']
}

const GoogleInput = ({ simulation, visitToken }) => {
  const [address, setAddress] = useState('')
  const [ready, setReady] = useState(false)

  const setAddressInSessionStorage = results => sessionStorage.setItem('address', JSON.stringify(results))

  const handleSelect = address => geocodeByAddress(address)
    .then((results) => {
      setAddress(results[0].formatted_address)
      setAddressInSessionStorage(results)
      setReady(true)
    })

  const simulationRoot = window.location.host === 'www.cityandyou.com' ? 'https://pro.cityandyou.com' : 'http://localhost:9000'
  const path = simulation ? `${simulationRoot}/simulation?address=` : '/estimation-immobiliere/formulaire/'

  const handleClick = () => {
    if (ready) {
      window.location.href = `${path}${simulation ? encodeURIComponent(address) + `&visitToken=${visitToken}` : ''}`
    }
  }

  return (
    <div className="slide-down-1s">
      <GoogleSearchInput
        address={address}
        handleChangeAddress={(e) => setAddress(e)}
        handleSelect={handleSelect}
        errors={{ address: '' }}
        suggestion={[]}
        searchOptions={searchOptions}
      />
      <button onClick={() => handleClick()} className="visible">C'est parti</button>
    </div>
  )
}

export default GoogleInput
