import React, { useEffect } from 'react'
import FormAnimationIllustration from '../../images/desktop/simpleform/form_animation.png'

const addressBarAnimation = () => {
  const input = document.getElementById('animation-address-text')
  input.value = ''

  const text = '32 rue des Pyramides, 93100 Montreuil'
  const l = text.length
  let current = 0
  const time = 50

  const writeText = () => {
    input.value += text[current]
    if (current < l - 1) {
      current++
      setTimeout(() => {
        writeText()
      }, time)
    } else {
      input.setAttribute('value', input.value)
      input.blur()
      makeIdAppear('animation-illustration')
      listAppear()
      setTimeout(() => {
        estimateAppear()
      }, 3000)
    }
  }

  setTimeout(() => {
    writeText()
  }, 2000)
}

export const makeIdAppear = (id) => {
  const el = document.getElementById(id)
  if (el) {
    el.classList.remove('opacity-none')
  }
}

const listAppear = () => {
  makeIdAppear('animation-property-parameters')
  document.getElementById('animation-property-parameters').classList.add('list-animation')
}

export const estimateAppear = () => {
  makeIdAppear('animation-estimates')
  document.getElementById('animation-estimates').classList.add('estimate-animation')
}

export const FormAnimation = () => {
  useEffect(() => {
    addressBarAnimation()
  }, [])

  return (
    <div className="form-animation-wrapper">
      <div className="form-animation-container">
        <input type="text" spellCheck="false" className="animation-address-bar" id="animation-address-text"
               placeholder="Simulation d'estimation de bien" />
        <img src={FormAnimationIllustration} className="animation-illustration opacity-none" id="animation-illustration"
             alt="Illustration de l'animation"/>
        <ul id="animation-property-parameters" className="opacity-none">
          <li>Maison individuelle</li>
          <li>5 pièces</li>
          <li>90m² habitables</li>
          <li>150m² de terrain</li>
          <li>Rafraîchissement à prévoir</li>
        </ul>
        <ul id="animation-estimates" className="opacity-none">
          <li>
            <span>405 000 €</span>
            <span>Estimation basse</span>
          </li>
          <li>
            <span>460 000 €</span>
            <span>Estimation moyenne</span>
          </li>
          <li>
            <span>515 000 €</span>
            <span>Estimation haute</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FormAnimation
