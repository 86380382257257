import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { data } from '../../../data/tooltipData'
import { checkLatLong } from '../../SimpleForm/api'
import { Errors } from '../errors/errors'
import { getCookie, triggerCrisp } from '../../../utils/utils'
import TrustBoxContainer from '../../../pages/trustpilot/TrustBoxContainer'

function getAddressToCheck(results) {
  return {
    lat: typeof results[0].geometry.location.lat === 'function' ? results[0].geometry.location.lat() : results[0].geometry.location.lat,
    lng: typeof results[0].geometry.location.lng === 'function' ? results[0].geometry.location.lng() : results[0].geometry.location.lng
  }
}

const GooglePlaces = ({ address, setAddress, error, errors }) => {

  const searchOptions = {
    componentRestrictions: {
      country: ['fr']
    },
    types: ['address']
  }

  const turnLogoWhite = () => {
    if (document.getElementsByClassName('logo-navbar').length > 0) {
      document.getElementsByClassName('logo-navbar')[0].classList.add('logo-navbar--reversed')
    }
  }

  const setAddressInSessionStorage = results => sessionStorage.setItem('address', JSON.stringify(results))

  const handleSelect = address => geocodeByAddress(address)
    .then((results) => {
      setAddress(results[0].formatted_address)
      const addressToCheck = getAddressToCheck(results)
      checkLatLong(addressToCheck).then((value) => {
        if (value.error === 'Adresse existante') {
          setErrors('address', ['Cette adresse est déjà utilisée'])
        }
        if (errors.address.includes('Cette adresse est déjà utilisée') && value.status === 200) {
          setErrors('address', errors.address.filter(error => error !== 'Cette adresse est déjà utilisée'))
        }
        if (value.status === 200 && errors.address.includes('Ce champ est requis.')) {
          setErrors('address', errors.address.filter(error => error !== 'Ce champ est requis.'))
        }
        setAddressInSessionStorage(results)
      }).catch(e => console.error('error: ', e))
    })


  useEffect(() => {
    // turnLogoWhite()
  }, [])

  const crispScenario = (hasTried) => {
    if (hasTried === 'true') {
      triggerCrisp('form_error_address_2')
    } else {
      document.cookie = 'formAddressHasTried=true'
      triggerCrisp('form_error_address_1')
    }
  }

  const removeArticle = () => document.getElementById('valuation-block').remove()

  function handleErrors() {
    const addressFromSessionStorage = JSON.parse(sessionStorage.getItem('address'))
    if (address.length === 0 || addressFromSessionStorage === null || address !== addressFromSessionStorage[0].formatted_address) {
      setErrors('address', ['Ce champ est requis.'])
      crispScenario(getCookie('formAddressHasTried'))
    } else if (errors.address.length > 0) {
      return
    } else {
      setErrors('address', [])
      increment()
    }
  }

  function handleGoButton() {
    handleErrors()
    if (document.getElementById('valuation-block')) { removeArticle() }
  }

  return (
    <div
      data-testid="google-places"
      className="container-answers fadeIn"
      id="form-step-0-1"
    >
      <header className="first-step-title">Obtenez instantanément une estimation immobilière de votre bien</header>
      <GoogleSearchInput
        address={address}
        searchOptions={searchOptions}
        next={handleErrors}
        handleChangeAddress={setAddress}
        handleSelect={handleSelect}
        errors={errors}
      />
      <button data-testid="start-form" onClick={handleGoButton}>
        C&apos;est parti !
      </button>
      <TrustBoxContainer
        templateId="5419b732fbfb950b10de65e5"
        theme="dark"
        height="20px"
        width="270px"
      />
      <ol>
        {
          data.map((tooltip, i) => (
            <ReactTooltip className="tooltip" id={`tooltip-form-${i + 1}`} key={`tooltip-form-${i + 1}`}>
              <strong>{`${i + 1}. ${tooltip.question}`}</strong>
              <p>{tooltip.answer}</p>
            </ReactTooltip>

          ))
        }
        <li data-tip data-for="tooltip-form-1">Vous obtenez une estimation instantanée de votre bien</li>
        <li data-tip data-for="tooltip-form-2">
          Nous vérifions ensuite que votre bien permet la réalisation d’une opération de promotion
          immobilière
        </li>
        <li data-tip data-for="tooltip-form-3">
          Nous vous proposons de mettre en concurrence des promoteurs immobiliers sélectionnés par nos soins.
        </li>
      </ol>
    </div>
  )
}

const GoogleSearchInput = ({
  address,
  handleChangeAddress,
  handleSelect,
  searchOptions,
  next,
  errors
}) => (
  <PlacesAutocomplete
    value={address}
    onChange={handleChangeAddress}
    onSelect={handleSelect}
    searchOptions={searchOptions}
    shouldFetchSuggestions={address.length > 1}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
      <form
        style={{ position: 'relative' }}
        className="ctn-google-search-input"
        onSubmit={() => next()}
      >
        <input
          data-testid="google-address-input"
          style={{ border: errors.address.length > 0 ? '2px solid #d95d57' : '2px solid transparent' }}
          aria-label="address-form-input"
          {...getInputProps({
            placeholder: "Entrez l'adresse de votre bien",
            className: 'input-email'
          })}
        />
        <input
          type="submit"
          style={{
            position: 'absolute',
            left: '-9999px',
            width: '1px',
            height: '1px'
          }}
          tabIndex="-1"
        />
        {address.length > 0 && suggestions.length > 0 && (
        <div className="autocomplete-dropdown-container">
          {suggestions.map((suggestion) => {
            const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item'

            const style = suggestion.active
              ? {
                backgroundColor: '#fafafa',
                cursor: 'pointer'
              }
              : {
                backgroundColor: '#ffffff',
                cursor: 'pointer'
              }

            return (
              <div
                key={suggestion.description.trim()}
                data-testid="result-form-input"
                {...getSuggestionItemProps(suggestion, {
                                             className,
                                             style
                                           })}
              >
                <span>{suggestion.description}</span>
              </div>
            )
          })}
        </div>
        )}
        {errors.address.length > 0
        && (
          <Errors
            message={errors.address[0]}
          />
        )}
      </form>
    )}
  </PlacesAutocomplete>
)

GoogleSearchInput.propTypes = {
  address: PropTypes.string,
  handleChangeAdress: PropTypes.func,
  handleSelect: PropTypes.func,
  searchOptions: PropTypes.shape({
    componentRestrictions: PropTypes.shape({
      country: PropTypes.arrayOf(PropTypes.string)
    }),
    types: PropTypes.arrayOf(PropTypes.string)
  }),
  errors: PropTypes.shape()
}

GoogleSearchInput.defaultProps = {
  address: '',
  handleChangeAdress: () => null,
  handleSelect: () => null,
  searchOptions: {
    componentRestrictions: {
      country: ['fr']
    },
    types: ['address']
  },
  errors: {}
}


export {
  GooglePlaces,
  GoogleSearchInput
}
