import React from 'react'
import FAQColumn from '../components/FAQ/FAQColumn'
import { data } from '../data/FAQData'

const halfLenght = Math.ceil(data.length / 2)
const column1 = data.slice(0, halfLenght)
const column2 = data.slice(halfLenght, data.length)

export class FAQ extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeQuestion: '' }
  }

  handleClick = (activeQuestion) => {
    if (activeQuestion === this.state.activeQuestion) {
      this.setState({ activeQuestion: '' })
    } else {
      this.setState({ activeQuestion })
    }
  }

  componentDidMount = () => {
    const { opened } = this.props

    if (opened) {
      this.setState({ activeQuestion: `column 1 - ${opened - 1}` })
      const elTop = document.getElementsByClassName('faq-question')[opened - 1].getBoundingClientRect().top
      window.scrollTo({ top: elTop - 70, behavior: 'smooth' })
    }
  }

  render() {
    const { activeQuestion } = this.state

    return (
      <div className="faq">
        <FAQColumn
          questions={column1}
          columnNumber={1}
          activeQuestion={activeQuestion}
          handleClick={question => this.handleClick(question)}
        />
        <FAQColumn
          questions={column2}
          columnNumber={2}
          activeQuestion={activeQuestion}
          handleClick={question => this.handleClick(question)}
        />
      </div>
    )
  }
}

export default FAQ
