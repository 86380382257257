import React from 'react'
import styled from 'styled-components'
import houseIcon from './images/houseIconOld.svg'
import buildingIcon from './images/buildingIconOld.svg'
import factoryIcon from './images/factoryIconOld.svg'
import greenfieldIcon from './images/greenfieldIconOld.svg'
import Moment from 'react-moment'
import { isMobile } from 'react-device-detect'
import { device } from '../../stylesheets/device/device'

export const formatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
})

export const formatterNoCurrency = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 0,
})

export const kFormatter = num => (
  Math.abs(num) > 999 ? `${Math.round(Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)))} k€` : Math.round(Math.sign(num) * Math.abs(num))
)

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  padding-top: 96px;
  color: ${props => props.theme.candyPrimary};
  display: flex;
  justify-content: center;
  overflow-x: none;
  color: #31325f;

  .public-disclaimer {
    font-size: 16px;
    line-height: 1.2;
    max-width: 700px;
    margin: 12px auto;
    text-align: center;
  }

  @media ${device.mobileL} {
    display: block;
    height: 100vh;
    padding-top: 0;

    .highlighted-address {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;

      div {
        margin: 0;
        border-radius: 0;
      }
    }

    .mobile-permit {
      bottom: 125px;

      h3 {
        background-color: ${props => props.theme.candyYellow};
        color: ${props => props.theme.candyPrimary};
      }
    }
  }
`

export const translatePropertyType = (type) => {
  switch (type) {
    case 'greenfield':
      return 'Terrain nu'
    case 'house':
      return 'Maison'
    case 'building':
      return 'Immeuble'
    case 'apartment':
      return 'Appartement'
    case 'business':
      return 'Local commercial'
    default:
      return 'Maison'
  }
}

export const translatePropertyCondition = (score) => {
  switch (score) {
    case 1:
      return 'Refait à neuf'
    case 2:
      return 'État standard'
    case 3:
      return 'Nécessite un rafraîchissement'
    case 4:
      return 'Nécessite des travaux importants'
    default:
      return 'N/A'
  }
}

export const ProjectReco = styled.div`
  width: 100%;
  background: #dce8f1;
  padding: 24px;
  margin-bottom: 32px;
  border-radius: 8px;

  h2 {
    margin-top: 0;
    line-height: 1;
    font-size: 26px;

  }

  ul {
    list-style: none;
    padding: 0;

    li {
      line-height: 1;
      margin-bottom: 4px;
      font-size: 16px;
      width: 386px;

      display: flex;
      justify-content: space-between;

      label {
        color: ${props => props.theme.candyPrimary};
      }

      span {
        font-weight: bold;
      }
    }
  }

  section {
    width: 100%;
    padding: 24px 38px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
  }

  .estimation-block {
    margin-top: 16px;
    background-color: white;
    color: #31325f;
    width: 100%;
    border-radius: 8px;
    padding-top: 120px;

    @media ${device.mobileL} {
      padding: 0 18px;
    }

    h2 {
      color: white;
      margin: 16px 0 98px 0;

      @media ${device.mobileL} {
        margin-bottom: 64px;
      }
    }

    #animation-estimates {
      margin: 0 auto;
      width: 506px;
      height: 31px;
      background-color: rgba(219, 224, 228, .5);
      margin-bottom: 60px;

      @media ${device.mobileL} {
        width: 90%;
        max-width: 290px;
        height: 19px;
      }

      li {
        height: 42px;
        width: 42px;
        border-width: 8px;
        top: -6px;

        @media ${device.mobileL} {
          height: 32px;
          width: 32px;
          border-width: 6px;
          top: -7px;
        }

        span:first-child {
          width: 200px;
          font-size: 24px;
          top: -52px;
          left: -84px;

          @media ${device.mobileL} {
            font-size: 14px;
            top: -27px;
            left: -40px;
            width: 100px;
          }
        }

        span:last-child {
          font-size: 12px;
          bottom: -34px;
          left: -50px;
          width: 124px;

          @media ${device.mobileL} {
            font-size: 9px;
            bottom: -32px;
            width: 86px;
            left: -36px;
          }
        }

        &:nth-child(2) {
          span:first-child {
            font-size: 27px;
            top: -68px;
            left: -80px;

            @media ${device.mobileL} {
              font-size: 20px;
              top: -48px;
              left: -37px;
            }
          }
        }
      }
    }

    .trust-index-sim {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding-left: 0;

      p {
        font-size: 16px;
        margin-right: 16px;
      }

      ul {
        display: flex;
      }

      li {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 4px;
      }

      li:last-child {
        margin: 0;
      }

      .full {
        background-color: #31325f;
      }

      .empty {
        background-color: transparent;
        border: 1px solid #31325f;
      }
    }
  }

  #valuation-disclaimer {
    font-size: 14px;
    text-align: center;
    line-height: 1.2;
    max-width: 400px;
    margin: 12px auto;

    b {
      font-weight: bold;
    }
  }

  @media ${device.mobileL} {
    padding: 12px;
    margin-bottom: 8px;

    h2 {
      font-size: 16px;
    }

    ul {
      li {
        width: 100%;
        margin-bottom: 0;

        label, span {
          font-size: 12px;
        }
      }
    }

    section {
      flex-direction: column;
      padding: 0;
      align-items: center;
    }

    .subtitle {
      li {
        font-size: 14px;
        margin: 8px 0;
      }
    }

    .estimation-block {
      padding-top: 74px;
      margin-top: 0;

      .trust-index-sim p {
        font-size: 14px;
        margin: 0;
        margin-right: 8px;
      }

      .trust-index-sim ul {
        margin: 0;
      }
    }

    #mobile-tooltip-estimate {
      font-size: 12px;
      line-height: 1.1;
    }
  }
`

export const ResultsBloc = styled.div`
  position: relative;
  max-width: ${props => props.long ? "100" : "48"}%;
  width: 100%;
  height: 100%;
  background: ${props => props.blue ? "#dce8f1" : "white"};
  padding: 24px;
  border-radius: 8px;
  display: ${props => props.invisible ? 'none' : 'flex'};
  justify-content: space-between;
  font-size: 16px;

  section:first-of-type {
    width: ${props => props.quartier ? '50%' : '100%'};
  }

  section:last-of-type {
    padding-left: ${props => props.quartier ? '12' : '0'}px;

    h2 {
      visibility: ${props => props.quartier ? 'hidden' : 'visible'};
    }

    li {
      margin-bottom: ${props => props.quartier ? '12' : '0'}px;
    }

    label {
      max-width: 184px;
      margin-right: 12px;
    }
  }

  h2 {
    margin-top: 0;
    line-height: 1;
    font-size: 26px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  b {
    font-weight: bold;
  }

  ul:last-of-type {
    width: ${props => props.long && !props.quartier ? '92%' : '100%'};

    li {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  ol {
    li {
      font-weight: bold;
      margin-bottom: 18px !important;
    }
  }

  li {
    line-height: 1.2;
    max-width: 270px;

    label {
      color: ${props => props.theme.candyPrimary};
    }

    span {
      font-weight: bold;
    }

    img {
      height: 52px;
      margin-right: 20px;
    }
  }

  .with-bg-violet {
    width: 188px;
    height: 46px;
    position: absolute;
    bottom: 24px;
    left: calc(50% - 188px / 2);

    span {
      font-weight: bold;
      font-size: 16px;
      padding: 12px 0;
    }
  }

  .with-bg-violet--blue {
    background-color: #31325f;
    border-color: #31325f;

    &:hover {
      background-color: transparent;
      border-color: #31325f;
      color: #31325f;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;

    .bloc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 96px;

      img {
        width: 72px;
        height: 92px;
        margin-bottom: 12px;
      }

      p {
        margin: 0;
        line-height: 1.2;
      }
    }
  }

  p {
    line-height: 1.2;
  }

  .two-columns {
    height: 80%;

    p {
      margin-top: 0;
      line-height: 1.2;
    }

    -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2;

      -webkit-column-gap: 20px;
         -moz-column-gap: 20px;
              column-gap: 20px;
  }

  .vigilance {
    font-size: 16px;
    line-height: 1.2;
  }

  #notes {
    li {
      align-items: flex-start;
      margin-bottom: 24px;

      a {
        opacity: 1;
        transition: 300ms all ease;

        &:hover {
          opacity: 0.8;
        }
      }

      #protection {
        text-decoration: ${props => props.protection ? 'underline' : 'none'};
      }

      #reducedVat {
        text-decoration: ${props => props.reducedVat ? 'underline' : 'none'};
      }
    }
  }

  #station-name {
    text-transform: capitalize;
  }

  .row--little {
    max-width: 333px;
  }

  #mapbox-section {
    min-width: 500px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    padding: 12px 12px ${props => props.cta ? '72px' : '12px'} 12px;
    width: 100%;
    max-width: 100%;
    height: auto;
    flex-direction: column;
    margin-bottom: 8px;

    h2 {
      font-size: 16px;
    }

    li {
      align-items: center;
      max-width: none;

      label {
        font-size: 14px;
      }
    }

    #mapbox-section {
      min-width: auto;
    }

    section {
      flex-direction: column;
      display: flex;
      width: 100% !important;
      padding-left: 0 !important;

      .row {
        .bloc {
          width: 82px;

          p {
            font-size: 12px;
          }
        }
      }

      ul {
        margin-top: 0;
      }
    }

    .two-columns {
      padding: 0 !important;

      p {
        font-size: 14px;
        margin-top: 0;
        line-height: 1.2;
      }

      -webkit-column-count: 1;
           -moz-column-count: 1;
                column-count: 1;
    }

    .vigilance {
      font-size: 14px;
    }
  }

  #notes {
    img {
      height: 34px;
      max-width: 21px;
    }
  }
`

export const PublicStyle = styled.div`
  .public-disclaimer {
    font-size: 16px;
    line-height: 1.2;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
  }

  @media ${device.mobileL} {

    .public-disclaimer {
      font-size: 14px;
      padding: 0 24px;
    }

    #contact-us-results  {
        .contact-row {
          margin-bottom: 12px;
          align-items: center;

          p {
            font-size: 16px;
            line-height: 1.2;
            margin-right: 6px;
          }
        }

        .contact-row--mobile-column {
          flex-direction: column;
          align-items: flex-start;
          height: auto;

          input:first-of-type {
            margin-bottom: 12px;
          }
        }

        .DateInput_input {
          font-size: 16px;
        }

        .SingleDatePicker {

          .SingleDatePickerInput__withBorder {
            height: 40px;
          }

          .DateInput {
            width: 120px;
          }
        }

        .css-2b097c-container {
          width: 90px;
        }

        .css-1nfxo30-control {
          min-height: auto;
          height: 40px;
          width: 100px;
        }

        .contact-row--phone {
          flex-direction: column;
          align-items: flex-start;
          height: auto;

          p {
            margin-bottom: 8px;
          }
        }

        .input-email {
          height: 40px;
          font-size: 16px;
        }

        .contact-nav {
          position: relative;
          bottom: auto;
          left: auto;
          background: transparent;
        }
      }
    }
  }
`

export const PublicBloc = styled(ResultsBloc)`
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  height: auto;
  margin-bottom: 32px;

`

export const Articles = () => (
  <PublicBloc>
    <h2>Les articles du blog</h2>
    <ArticlesStyle>
      <ArticleCard
        path="https://www.cityandyou.com/blog/2020/07/13/quest-ce-quune-surface-habitable"
        img="https://www.cityandyou.com/blog/wp-content/uploads/2020/07/Surface-Habitable.jpg"
        title="Qu’est-ce que la surface habitable ?"
        content="Vous êtes propriétaire d’un bien et vous êtes sollicité par les promoteurs immobiliers ? Il est vrai que ce type de vente souvent longue et complexe peut vous permettre de réaliser une belle plus-value. … Lire la suite"
        date="24/09/2020"
      />
      <ArticleCard
        path="https://www.cityandyou.com/blog/2019/05/30/quand-sengager-offre-achat-promesse-vente-acte-authentique"
        img="https://www.cityandyou.com/blog/wp-content/uploads/2019/05/offre-promesse-quel-contrat-signer-1248x703.jpg"
        title="Quand s’engager : offre d’achat, promesse de vente ou acte authentique ?"
        content="Vous êtes propriétaire d’un terrain, d’une maison, d’un immeuble ou encore d’un entrepôt et souhaitez vendre à un promoteur ? Lire la suite"
        date="26/06/2020"
      />
      <ArticleCard
        path="https://www.cityandyou.com/blog/2020/01/06/diminuer-taxe-sur-la-plus-value"
        img="https://www.cityandyou.com/blog/wp-content/uploads/2020/01/Taxe-plus-value-immobilier-scaled-1248x703.jpeg"
        title="Taxe sur la plus-value : comment en diminuer le montant ?"
        content="Estimer la valeur d’un terrain lorsqu’il est vendu à un promoteur immobilier n’est pas aussi simple que dans le cas d’une vente dite « classique ». En effet pour définir la valeur d’un foncier, le promoteur va réaliser ce que.… Lire la suite"
        date="02/08/2019"
      />
    </ArticlesStyle>
  </PublicBloc>
)

const ArticleCard = ({ path, img, title, content, date }) => (
  <ArticleCardStyle href={path} target="_blank">
    <div style={{ backgroundImage: `url(${img})` }} />
    <section>
      <h3>{title}</h3>
      <p>{content}</p>
    </section>
    <div>{date}</div>
  </ArticleCardStyle>
)

const ArticleCardStyle = styled.a`
  position: relative;
  max-width: 280px;
  width: 100%;
  height: 418px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(70, 73, 208, 0.16);
  transition: 300ms all ease;
  border: none;

  h3 {
    font-size: 20px;
    line-height: 1.1;
    margin-top: 0;
  }

  p {
    font-size: 14px;
    line-height: 1;
  }

  section {
    padding: 24px !important;
  }

  div:first-of-type {
    height: 180px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  div:last-of-type {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    background-color: #f2f6fa;
    padding: 0 24px;
    font-size: 14px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: center;
  }

  &:hover {
    transform: scale(1.02);
  }

  @media ${device.mobileL} {
    width: 216px;
    height: 386px;
    min-width: 216px;
    margin-right: 12px;

    div:first-of-type {
      height: 136px;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 16px;
    }

    p {
      margin: 0;
      line-height: 1.2;
    }

    section {
      padding: 8px !important;
    }
  }
`

const ArticlesStyle = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;

  @media ${device.mobileL} {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding-bottom: 8px;
    justify-content: flex-start;
  }
`

const DealContainer = styled.section`
  background-color: white;
  width: 100%;
  border-radius: 6px;
  padding: 0 12px 12px 12px;
  margin-bottom: 8px;

  h1, h2 {
    font-size: 18px;
    line-height: 1;
    height: 42px;
    padding: 0 12px;
    margin: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edf2f7;
  }

  @media ${device.mobileL} {
    margin: 0 0 8px 0;
    padding: 8px;

    h2 {
      font-size: 16px;
      padding: 0;
    }
  }
`

const TransactionsContainer = styled(DealContainer)`
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: ${props => props.justifyLeft ? 'flex-start' : 'space-around'};
    margin-bottom: 0;

    div {
      cursor: auto;
      width: 48%;

      &:hover {
        box-shadow: none;
      }
    }

    .group-address {
      padding: 8px;
      width: 100%;
    }

    .image-container {
      min-width: 90px;

      img {
        transform: scale(0.8);
      }
    }

    i, h3 {
      display: none;
    }
  }

  a {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    text-align: right;
    text-decoration: underline;
    color: ${props => props.theme.candyPrimary};
  }

  @media ${device.mobileL} {
    ul {
      flex-direction: column;

      div {
        width: 100%;

        .image-container {
          width: 90px;
        }
      }
    }
  }
`

const getIcon = (propertyType) => {
  switch (propertyType) {
    case 'Appartement':
      return buildingIcon
    case 'Maison':
      return houseIcon
    case 'Business':
      return factoryIcon
    case 'Greenfield':
      return greenfieldIcon
    default:
      return buildingIcon
  }
}

const adaptPropertyTypeForMobile = (propertyType) => {
  if (propertyType !== 'Appartement') {
    return propertyType
  }
}

export const TransactionCard = ({
  address,
  city,
  propertyType,
  rooms,
  date,
  price,
  livingArea,
  area,
  squareMeterPrice
}) => {
  return (
    <li>
      <h3>{`${address}, ${city}`}</h3>
      <section>
        <div className="image-container">
          <img
            src={getIcon(propertyType)}
            alt={"icone"}
          />
        </div>
        <div className="text-container">
          {
            (propertyType === 'Maison' || propertyType === 'Appartement') &&
            (
              <p>{`${propertyType}, ${rooms > 0 ? `${rooms} pièce${rooms > 1 ? 's,' : ','}` : ''} ${livingArea} m²`}</p>
            )
          }
          {
            propertyType === 'Business' && (
              <p>{`Local, ${rooms > 0 ? `${rooms} pièce${rooms > 1 ? 's,' : ','}` : ''} ${livingArea} m²`}</p>
            )
          }
          {
            propertyType === 'Greenfield' && (
              <p>{`Terrain, ${area} m²`}</p>
            )
          }
          <small>
            {`Vendu${propertyType === 'Maison' ? 'e' : ''} le `}
            <Moment>{date}</Moment>
          </small>
          <section>
            <strong>{isMobile ? kFormatter(price) : formatter.format(price)}</strong>
            <span>{`${formatter.format(squareMeterPrice)}/m²`}</span>
          </section>
        </div>
      </section>
    </li>
  )
}

const TransactionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 6px;
  transition: 300ms all ease;
  box-shadow: ${props => props.highlighted ? '0 0 8px 0 rgba(70, 73, 208, 0.3)' : '0 1px 2px 0 rgba(0,0,0,.1)'};
  cursor: pointer;

  h3 {
    font-size: 16px;
    height: 36px;
    margin: 0;
    padding: 2px 12px;
    color: ${props => props.highlighted ? 'white' : props.theme.candyPrimary};
    background-color: ${props => props.highlighted ? props.theme.candyPrimary : 'white'};
    border-bottom: 2px solid ${props => props.theme.proGrey};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    img {
      margin-right: 6px;
    }
  }

  section {
    display: flex;
    height: 110px;
  }

  .permit-wrapper {
    h3 {
      display: block;
      text-transform: capitalize;
      background-color: ${props => props.highlighted ? props.theme.candyYellow : 'white'};
    }
  }

  .permit {
    position: relative;
    width: 100%;
    height: ${props => props.permitOpen ? 'auto' : '26px'};
    background-color: ${props => props.theme.candyYellow};
    color: ${props => props.theme.candyPrimary};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: ${props => props.permitOpen ? '26px' : '26px'};
      margin: 0;
      background-color: transparent;
      border: none;

      img {
        height: 14px;
        margin-right: 12px;
      }

      span {
        font-size: 12px;
        font-weight: bold;
      }

      u {
        font-size: 12px;
        position: inherit;
        visibility: visible;
      }
    }

    section {
      height: 135px;
    }

    .permit-data {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 12px;
      background-color: #FDF4C0;
      line-height: 1;
      letter-spacing: -0.36px;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      div {
        display: flex;
        flex-direction: column;
      }

      strong {
        font-size: 14px;
        margin-bottom: 12px;
      }

      small {
        font-size: 12px;
      }

      #housing-number {
        margin-bottom: 12px;
      }
    }

    .image-container {
      height: 100%;
      border-bottom-left-radius: 6px;
      background-color: ${props => props.highlighted ? props.theme.candyYellow : 'white'};

      img {
        width: auto;
      }
    }

    i {
      position: absolute;
      border: solid ${props => props.theme.candyPrimary};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      cursor: pointer;
      top: 8px;
      right: 10px;
      transition: 300ms all ease;
      transform: rotate(${props => props.permitOpen ? '-135' : '45'}deg);
      -webkit-transform: rotate(${props => props.permitOpen ? '-135' : '45'}deg);
    }
  }

  &:hover {
    box-shadow: 0 0 8px 0 rgba(70, 73, 208, 0.3);
  }

  .image-container {
    border-bottom-left-radius: ${props => props.permits ? '0' : '6px'};
    height: 110px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.highlighted ? props.theme.candyPrimary : 'white'};

    img {
      width: 50%;
    }
  }

  @media ${device.mobileL} {
    h3 {
      height: '32px';
      font-size: 14px;
      line-height: 1;
      padding: 2px 12px 0 12px;
      display: flex;
      align-items: center;

      img {
        transform: scale(0.8);
        padding-top: 2px;
        margin-bottom: 4px;
      }
    }

    section {
      height: ${props => props.solo ? '110px' : '80px'};
    }

    .image-container {
      height: ${props => props.solo ? '110px' : '80px'};
      min-width: 80px;

      img {
        width: 54px;
      }
    }

    .permit-wrapper {
      h3 {
        display: flex;
      }
    }

    .permit {
      section {
        height: 130px;
      }

      button {
        justify-content: flex-start;
        padding: 0 12px;

        span {
          font-size: 10px;
        }
      }

      small {
        line-height: 1.2;
      }

      .permit-data {
        padding: 12px;
        border-radius: 0;
        border-bottom-right-radius: 6px;

        strong {
          margin-bottom: 6px;
        }

        #housing-number {
          margin: 0;
        }
      }

      .image-container {
        img {
          height: 32px;
        }
      }
    }
  }
`

export const MAP_BOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY2l0eWFuZHlvdSIsImEiOiJja2NkYnowYmUwZDl5MnFwNmdhcmFmYzhkIn0.rU_jbm7G2Nh7DsSSDfkNlQ'
