import React, { useEffect } from 'react'
import { useStep } from '../../context/step.context'
import { useForm } from '../../context/form.context'
import { AnswerProperty } from './answerproperty/answerproperty'
import { GooglePlaces } from './googleplace/googleplaces'
import { data } from '../../data/data'
import { AnswerWhoAreYou } from '../SimpleForm/AnswerWhoAreYou'
import { AnswerForm } from '../SimpleForm/AnswerForm'
import { AnswerSquareMeter } from './answersquaremeter/answersquaremeter'
import { ConditionProperty } from './conditionproperty/conditionproperty'
import { AnswerSaleReadiness } from './answersalereadiness/answersalereadiness'

import Estimation from '../SimpleForm/Estimation'
import { WaitingPage } from './waitingpage'

const ScenarioOne = React.memo(() => {
  const { step, increment } = useStep()
  const {
    setCategory, property, setCondition, setSquareMeter, setRooms, setArea, setTransactionRole, setSaleReadiness, setAcceptAppartment, user, setUser, incrementWithErrors, out, appartement
  } = useForm()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  if (step === 0) {
    return (<GooglePlaces />)
  }

  if (step === 1) {
    return (
      <AnswerProperty
        data={data}
        category={property.category}
        errors={[]}
        index={step}
        select={setCategory}
        increment={incrementWithErrors}
      />
    )
  }

  if (step === 2) {
    return (
      <ConditionProperty
        data={data}
        condition={property.condition}
        errors={[]}
        index={step}
        select={setCondition}
      />
    )
  }

  if (step === 3) {
    return (
      <AnswerSquareMeter
        testID="squaremeter"
        text="Quelle est la surface de votre bien (en m²) ?"
        squareMeter={property.squareMeter}
        setSquareMeter={setSquareMeter}
        area={0}
        step={step}
        type="squareMeter"
        placeholder="45"
        id="form-step-1-2"
      />
    )
  }
  if (step === 4) {
    return (
      <AnswerSquareMeter
        text="Quel est le nombre de pièces ?"
        squareMeter={property.rooms}
        setSquareMeter={setRooms}
        area={0}
        step={step}
        type="rooms"
        placeholder="5"
        id="form-step-1-3"
      />
    )
  }
  if (step === 5) {
    return (
      <AnswerSquareMeter
        text="Quelle est la surface de votre terrain (en m²) ?"
        squareMeter={0}
        setSquareMeter={setArea}
        errors={[]}
        area={property.area}
        step={step}
        isArea
        type="area"
        placeholder="450"
        id="form-step-1-4"
      />
    )
  }
  if (step === 6) {
    return (
      <AnswerWhoAreYou
        data={data}
        select={setTransactionRole}
        transactionRole={user.transactionRole}
        step={step}
        id="form-step-1-5"
      />
    )
  }

  if (step === 7) {
    return (
      <AnswerSaleReadiness
        data={data}
        saleReadiness={user.saleReadiness}
        step={step}
        id="form-step-1-2"
        select={setSaleReadiness}
        errors={[]}
      />
    )
  }

  if (step === 8) {
    return (
      <AnswerForm
        step={step}
        firstName={user.firstName}
        lastName={user.lastName}
        email={user.email}
        phone={user.phone}
        gender={user.gender}
        subscribedToNewsletter={user.subscribedToNewsletter}
        setGender={setUser}
        handleChange={setUser}
        id="form-step-1-7"
      />
    )
  }
  if (step === 9) {
    return (
      <WaitingPage increment={increment} />
    )
  }
  if (step === 10) {
    return <Estimation />
  }
})

const ScenarioTwo = React.memo(() => {
  const { step, increment } = useStep()
  const {
    setCategory, property, setArea, setTransactionRole, setSaleReadiness, user, setUser
  } = useForm()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  if (step === 0) {
    return (<GooglePlaces/>)
  }
  if (step === 1) {
    return (
      <AnswerProperty
        data={data}
        category={property.category}
        errors={[]}
        index={step}
        select={setCategory}
      />
    )
  }

  if (step === 2) {
    return (
      <AnswerSquareMeter
        text="Quelle est la surface de votre terrain (en m²) ?"
        squareMeter={0}
        setSquareMeter={setArea}
        errors={[]}
        area={property.area}
        step={step}
        isArea
        type="area"
        placeholder="450"
        id="form-step-2-1"
      />
    )
  }
  if (step === 3) {
    return (
      <AnswerWhoAreYou
        data={data}
        select={setTransactionRole}
        transactionRole={user.transactionRole}
        step={step}
        id="form-step-2-2"
      />
    )
  }

  if (step === 4) {
    return (
      <AnswerSaleReadiness
        id="form-step-2-3"
        data={data}
        saleReadiness={user.saleReadiness}
        step={step}
        select={setSaleReadiness}
        errors={[]}
      />
    )
  }

  if (step === 5) {
    return (
      <AnswerForm
        errors={[]}
        step={step}
        firstName={user.firstName}
        lastName={user.lastName}
        email={user.email}
        phone={user.phone}
        gender={user.gender}
        subscribedToNewsletter={user.subscribedToNewsletter}
        setGender={setUser}
        handleChange={setUser}
        id="form-step-2-4"
      />
    )
  }

  if (step === 6) {
    return (
      <WaitingPage increment={increment} />
    )
  }

  if (step === 7) {
    return (
        <Estimation />
    )
  }
})

export {
  ScenarioOne,
  ScenarioTwo
}
