export const data = [
  {
    question: 'La méthode de calcul utilisée',
    answer: 'Pour réaliser l’estimation en ligne nous nous basons sur les données historiques des transactions provenant de la base des données françaises des notaires et des annonces publiées récemment par les agences immobilières sur les différents portails informatiques. L’estimation se base sur un prix du mètre carré «théorique» par commune (et arrondissement pour les villes de Paris, Marseille et Lyon), auquel on applique des coefficients à la hausse ou à la baisse en fonction des caractéristiques du bien immobilier (adresse, surfaces, nombre de pièces,...).'
  },
  {
    question: 'L’opération de promotion immobilière',
    answer: 'La faisabilité d’une opération de promotion immobilière sur votre terrain dépend des règles d’urbanisme applicables à votre parcelle. Suite à votre inscription, nos experts analysent ces règles et réalisent une première étude de capacité afin de déterminer si votre terrain est éligible. À ce titre, les biens en copropriété tels que les appartements ne sont pas éligibles.'
  },
  {
    question: 'La mise en concurrence des promoteurs',
    answer: 'Si votre terrain permet la réalisation d’une opération de promotion immobilière, nous sélectionnons trois promoteurs adaptés aux caractéristiques de votre projet (localisation de votre bien, taille de l’opération et type de programme immobilier), organisons la mise en concurrence : vous obtenez plusieures offres pertinentes.'
  }
]
