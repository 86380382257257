import React from 'react'

export const FormContext = React.createContext()

const initialState = {
  loading: false,
  out: false,
  address: '',
  appartement: false,
  user: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    transactionRole: null,
    saleReadiness: 0,
    gender: 1,
    subscribedToNewsletter: false,
    thirdPartySharingOptOut: false
  },
  property: {
    category: null,
    condition: 0,
    squareMeter: '',
    rooms: '',
    area: ''
  },
  errors: {
    address: [],
    category: [],
    condition: [],
    squareMeter: [],
    rooms: [],
    area: [],
    email: [],
    firstName: [],
    lastName: [],
    phone: [],
    transactionRole: [],
    saleReadiness: [],
    bdv: []
  },
  estimation: null
}

function formReducer(state, action) {
  switch (action.type) {
    case 'EXIT_FORM':
      return {
        ...state,
        out: true
      }

    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.payload
      }

    case 'SET_PROPERTY_CATEGORY':
      return {
        ...state,
        property: {
          ...state.property,
          category: action.payload
        },
        out: false,
        appartement: action.payload === 1
      }

    case 'SET_ACCEPT_APPARTMENT':
      return {
        ...state,
        property: {
          ...state.property,
          area: 0
        },
        out: false
      }

    case 'SET_PROPERTY_CONDITION':
      return {
        ...state,
        property: {
          ...state.property,
          condition: action.payload
        }
      }

    case 'SET_SALE_READINESS':
      return {
        ...state,
        user: {
          ...state.user,
          saleReadiness: action.payload
        }
      }

    case 'SET_PROPERTY_SQUARE_METER':
      return {
        ...state,
        property: {
          ...state.property,
          squareMeter: action.payload
        }
      }

    case 'SET_PROPERTY_ROOMS':
      return {
        ...state,
        property: {
          ...state.property,
          rooms: action.payload
        }
      }

    case 'SET_PROPERTY_AREA':
      return {
        ...state,
        property: {
          ...state.property,
          area: action.payload
        }
      }

    case 'SET_TRANSACTION_ROLE':
      return {
        ...state,
        user: {
          ...state.user,
          transactionRole: action.payload
        }
      }

    case 'SET_USER':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.field]: action.payload.user
        }
      }

    case 'SET_ERRORS':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.errors
        }
      }

    case 'SET_ESTIMATION':
      return {
        ...state,
        estimation: action.payload
      }

    default:
      throw new Error('formReducer need a valid action')
  }
}

function FormProvider(props) {
  const [state, dispatch] = React.useReducer(formReducer, initialState)
  const value = React.useMemo(() => ({
    state,
    dispatch
  }), [state])

  return (
    <FormContext.Provider value={value} {...props} />
  )
}

function useForm() {
  const context = React.useContext(FormContext)
  if (!context) {
    throw new Error('useForm must be used within a FormProvider')
  }

  const { state, dispatch } = context
  const exitForm = React.useCallback(() => dispatch({ type: 'EXIT_FORM' }), [dispatch])
  const setErrors = React.useCallback((field, errors) => dispatch({
    type: 'SET_ERRORS',
    payload: { field, errors }
  }), [state.errors])
  const setAddress = React.useCallback(address => dispatch({ type: 'SET_ADDRESS', payload: address }), [state.address])
  const setCategory = React.useCallback((category) => dispatch({
    type: 'SET_PROPERTY_CATEGORY',
    payload: category
  }), [state.property.category])
  const setCondition = React.useCallback((condition) => dispatch({
    type: 'SET_PROPERTY_CONDITION',
    payload: condition
  }), [state.property.condition])
  const setSaleReadiness = React.useCallback((saleReadiness) => dispatch({
    type: 'SET_SALE_READINESS',
    payload: saleReadiness
  }), [state.user.saleReadiness])
  const setSquareMeter = React.useCallback((squareMeter) => dispatch({
    type: 'SET_PROPERTY_SQUARE_METER',
    payload: squareMeter
  }), [])
  const setRooms = React.useCallback((rooms) => dispatch({
    type: 'SET_PROPERTY_ROOMS',
    payload: rooms
  }), [])
  const setArea = React.useCallback((area) => dispatch({
    type: 'SET_PROPERTY_AREA',
    payload: area
  }), [])
  const setTransactionRole = React.useCallback((transactionRole) => dispatch({
    type: 'SET_TRANSACTION_ROLE',
    payload: transactionRole
  }), [state.property.transactionRole])
  const setUser = React.useCallback((field, user) => dispatch({
    type: 'SET_USER',
    payload: {field, user}
  }), [state.user])

  const setEstimate = React.useCallback((estimation) => dispatch({
    type: 'SET_ESTIMATION',
    payload: estimation
  }))

  const setAcceptAppartment = React.useCallback(() => dispatch({
    type: 'SET_ACCEPT_APPARTMENT',
    payload: null
  }))

  return {
    ...state,
    exitForm,
    setAddress,
    setCategory,
    setCondition,
    setSaleReadiness,
    setSquareMeter,
    setRooms,
    setArea,
    setTransactionRole,
    setUser,
    setErrors,
    setEstimate,
    setAcceptAppartment
  }
}

export {
  useForm,
  FormProvider
}
