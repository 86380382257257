import React, { Component } from 'react'

const TrustBox = ({ trustBoxRef, templateId, theme, width, height, id }) => (
  <div
    ref={trustBoxRef}
    data-template-id={templateId}
    data-businessunit-id="5d764426953f75000118b392"
    data-locale="fr-FR"
    data-style-width={width}
    data-style-height={height}
    data-theme={theme}
    className="trustpilot-widget"
    id={id}
// [ long list of data attributes...]
  >
    <a
      href="https://www.trustpilot.com/review/example.com"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      Trustpilot
    </a>
  </div>
)

class TrustBoxContainer extends Component {
  constructor(props) {
    super(props)
    this.trustBoxRef = React.createRef()
  }

  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true)
    }
  }

  render() {
    return (
      <TrustBox
        trustBoxRef={this.trustBoxRef}
        templateId={this.props.templateId}
        id={this.props.id}
        theme={this.props.theme}
        width={this.props.width}
        height={this.props.height}
      />
    )
  }
}

export default TrustBoxContainer
