function getDateInfo() {
  const date = new Date();
  return {
    date,
    hours: date.getHours(),
    day: date.toLocaleString('fr-FR', { weekday: 'long' })
  };
}

function getStepEventString(stepIndex) {
  const step = ["C'est parti !", 'Envoyer le formulaire', 'Fermer'];
  return {
    callToAction: step[stepIndex]
  };
}

function debounce(a, b, c) {
  let d;
  let e;
  return function () {
    function h() {
      d = null, c || (e = a.apply(f, g));
    }

    let f = this;
    let g = arguments;
    return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e;
  };
}

function getCookie(cookiename) {
  var cookiestring=RegExp(""+cookiename+"[^;]+").exec(document.cookie);
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
};

function addEvent(obj, evt, fn) {
  if (obj.addEventListener) {
   obj.addEventListener(evt, fn, false);
  }
  else if (obj.attachEvent) {
    obj.attachEvent("on" + evt, fn);
  }
}

function triggerCrisp(event) {
  if (typeof $crisp !== 'undefined') {
    $crisp.push(["set", "session:event", [[[event]]]])
  }
}

// From MDN
async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  })
  return response.json()
}

module.exports = {
  getDateInfo,
  getStepEventString,
  debounce,
  getCookie,
  addEvent,
  triggerCrisp,
  postData
}
