import Glide from '@glidejs/glide'

const options = {
  perView: 3,
  type: 'carousel'
}

const optionsDesktop = {
  perView: 3,
  type: 'carousel',
  autoplay: 3000,
  startAt: 0,
  breakpoints: {
    1110: {
      perView: 2
    },
    720: {
      perView: 1
    }
  }
}

const optionsAdvising = {
  perView: 1
}

function isMobileDevice() {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)
}


if (document.getElementsByClassName('glide').length > 0) {
  if (isMobileDevice()) {
    options.perView = 1
  }

  new Glide('.glide', options).mount()
}

if (document.getElementsByClassName('glide-advising').length > 0) {
  if (isMobileDevice()) {
    options.perView = 1
  }

  new Glide('.glide-advising', optionsAdvising).mount()
}

if (document.getElementsByClassName('glide-desktop').length > 0) {
  if (isMobileDevice()) {
    options.perView = 1
  }

  new Glide('.glide-desktop', optionsDesktop).mount()
}
