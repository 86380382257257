import React from 'react'
import { ScenarioOne, ScenarioTwo } from './scenarios'
import { useStep } from '../../context/step.context'
import { useForm } from '../../context/form.context'
import { Step } from './step'
import { FormDisclaimerMobile } from '../SimpleForm/FormDisclaimerMobile'

function scenarioType() {
  const { property } = useForm()
  if (property.category === 2) {
    return 'two'
  }
  return 'one'
}

function ScenariosManager() {
  const { step } = useStep()
  const type = scenarioType()

  return (
    <div data-testid="scenario-manager" id="SimpleFormContainer" style={{ boxShadow: 'none', width: '100%' }}>
      {type === 'two' && step > 1 ? <ScenarioTwo /> : <ScenarioOne />}
      {step > 0 && <Step type={type} />}
    </div>
  )
}

function classType(step, type) {
  switch (step) {
    case 0:
      return 'form-container--reversed'
    case 7: {
      if (type === 'two') return 'form-container-estimation'
      return ''
    }
    case 10:
      return 'form-container-estimation'
    default:
      return ''
  }
}

function Form() {
  const { step } = useStep()
  const { out } = useForm()
  const type = scenarioType()

  return (
    <div id="SimpleFormContainer" className={`${classType(step, type)} simple-form-step-${step}-type-${type} ${out && step === 2 && 'form-container--reversed appartment-rejection'}`}>
      { step >= 0 && <ScenariosManager /> }
      { step === 0 && <FormDisclaimerMobile /> }
    </div>
  )
}

export {
  Form
}
