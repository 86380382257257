import React from 'react'
import { Navbar } from '../../../pages/navbar/Navbar'
import { FriseForm } from '../../SimpleForm/FriseForm'
import { useForm } from '../../../context/form.context'
import { useStep } from '../../../context/step.context'

function getType(property) {
  if (property.category === 2) {
    return 'two'
  }
  return 'one'
}

function LayoutInscription({ children }) {
  const { property } = useForm()
  const { step } = useStep()

  return (
    <div id="SimpleForm" data-testid="container-form">
      <Navbar empty />
      <div className="container-onBoarding">
        <FriseForm step={step} type={getType(property)} />
        {children}
      </div>
    </div>
  )
}

export {
  LayoutInscription
}
