import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Range } from 'react-range'
import { BaseTable, CircleProgram, singleHead, col5TableProgram, Stickers, Asterich } from './Shared'

const DetailedProgram = ({ plot, city, program, programIndex, generalIndex, vefa }) => {
  const divCovers = []
  const max6 = (number) => (number >= 6 ? 6 : number > 0 ? number : 0)
  moment.locale('fr')

  const cityStat = [
    ['Moy. ville', max6(city.sales_per_month)],
    ['prog', max6(program.sales_per_month)],
  ].sort((a, b) => a[1] - b[1])

  const statLabels = cityStat.map((x) => x[0])
  const statNumbers = cityStat.map((x) => x[1])
  const lotsSize = program.stats_by_typos.all.moy.nbr

  const createScale = () => {
    const scale = []
    for (let i = 0; i < 6; i = i + 1) {
      scale.push(<Body2 key={`scale-${i}`}>{i}</Body2>)
    }
    scale.push(<Body2 key="scale-6">6+</Body2>)
    return scale
  }
  for (let i = 0; i < 6; i++) {
    divCovers.push(
      <div key={i} className='cover'>
        {(!vefa && program.covers_url[i]) ? <img src={program.covers_url[i]} alt="cover" /> : ''}
      </div>
    )
  }

  const RenderedRange = ({ statNumbers }) => (
    <RangeContainer>
      <Range
        values={statNumbers}
        min={0}
        max={6}
        step={0.00000000000000001}
        renderTrack={({ props, children }) => (
          <div id="range-box">
            <div id="range-track" ref={props.ref}>
              {children}
            </div>
            <div className="scale">{createScale()}</div>
          </div>
        )}
        renderThumb={({ index, props }) => (
          <div
            className={`${statNumbers[index] > 0 ? 'thumb-mark' : ''}`}
            id={(statNumbers[index] > 0 && statLabels[index] === 'prog') ? 'thumb-mark-prog' : ''}
            {...props}
            style={{ ...props.style }}
          >
            <Caption2 className={`${(statNumbers[index] > 0 && statLabels[index] !== 'prog') ? 'thumb-label' : 'no-display'}`}>
              {statLabels[index]}
            </Caption2>
          </div>
        )}
      />
    </RangeContainer>
  )

  return (
    <div className="align-start pdf-content-inside">
      {vefa ? (
        <div className="location-detailed-program bg-light-blue mb-12">
          <CircleProgram className="vefa-programs mr-30 mr-15 bold">{generalIndex + programIndex + 1}</CircleProgram>
          <strong>{program.full_address}</strong>
        </div>
      ) : (
        <div className="location-detailed-program bg-light-red mb-12">
          <CircleProgram className={program.progress_status === 99 ? "program-finished mr-30 mr-15 bold" : "program-ongoing color-white mr-15 bold"}>{programIndex + 1}</CircleProgram>
          <div className="ellipsis">
            <strong>{program.name}</strong> - {program.owner_name} - <strong>{program.full_address}</strong>
          </div>
        </div>
      )}
      <div className="page-2-col-stretch">
        <div className="d-flex flex-column">
          <BaseTable className="mb-12 height-200px">
            {singleHead('Synthèse de la commercialisation')}
            <tbody>
              <tr>
                <td className="pt-5">Typologie (%) :</td>
                <td className="d-flex gap-4 justify-content-between pt-5">
                  {
                    ['t1', 't2', 't3', 't4', 't5'].map((typ, i) => {
                      const ratio = program.stats_by_typos.all[typ]?.nbr / lotsSize
                      const roundedRatio = parseFloat(ratio * 100)?.toFixed(0)
                      return (
                        <div key={`${typ}-${i}`}>
                          <div className="no-bold color-light-grey">{typ === 't5' ? `${typ.toUpperCase()}+` : typ.toUpperCase()}</div>
                          <div>{roundedRatio === '0' ? '-' : `${roundedRatio}%`}</div>
                        </div>
                      )
                    })
                  }
                </td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>{vefa ? 'Date de vente 1er lot' : 'Début commercialisation'} :</td>
                <td>{program.first_appear ? moment(program.first_appear).format('MM/YYYY') : ''}</td>
              </tr>
              <tr>
                <td>{vefa ? 'Date de vente dernier lot' : 'Fin commercialisation'} :</td>
                <td>{(vefa || program.progress_status === 99) ? moment(program.last_appear).format('MM/YYYY') : 'En cours'}</td>
              </tr>
              <tr>
                <td>Nbre de lots en base :</td>
                <td className="no-bold">{lotsSize}</td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>Gare la plus proche :</td>
                <td className="no-bold">{program.station_distance ? `${program.station_distance} m` : '-'}</td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>Prix moyen (hors lots retirés) :</td>
                <td>{vefa ? program.mean_sqm_price_original : Math.round(program.sqm_price)} €/m²</td>
              </tr>
              <tr className={vefa ? 'color-candy-primary' : 'color-light-grey'}>
                <td>Redressement de prix * :</td>
                <td className="no-bold">{vefa ? `${program.percentage_sqm_price_corrected} % *` : '-'}</td>
              </tr>
              <tr className={vefa ? 'color-candy-primary' : 'color-light-grey'}>
                <td>Prix moyen actualisé :</td>
                <td className={vefa ? 'bold' : 'no-bold'}>{vefa ? `${program.program_price} €/m²` : '-'}</td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>Rythme de vente mensuel:</td>
                <td>{program.sales_per_month?.toFixed(2) || '-'}</td>
              </tr>
              <tr>
                <td colSpan="2"><RenderedRange statNumbers={statNumbers} /></td>
              </tr>
            </tbody>
          </BaseTable>
          <div className="d-flex justify-content-center">
            <div className="covers-container">
              {divCovers}
            </div>
          </div>
          <Asterich>
            <div>
              <span className="color-candy-primary">*</span>
              Le redressement de prix est réalisé pour chaque lot selon une estimation de l'évolution des prix de l'immobilier depuis la date de vente du lot.
            </div>
          </Asterich>
        </div>
        <div className="d-flex flex-column">
          <Stickers
            reducedVat={plot.reduced_vat}
            pinel={city.pinel}
          />
          <BaseTable className="mb-12 height-200px">
            {singleHead('Synthèse par typologie', '3')}
            {col5TableProgram(program.stats_by_typos.all, 'white')}
          </BaseTable>
          <BaseTable className="white-background mb-12 height-200px">
            {singleHead('Synthèse lots disponibles', '3', vefa)}
            {program.stats_by_typos.available && col5TableProgram(program.stats_by_typos.available)}
            <tbody />
          </BaseTable>
          <BaseTable className="white-background mb-12 height-200px">
            {singleHead('Synthèse lots réservés', '3', vefa)}
            {program.stats_by_typos.sold && col5TableProgram(program.stats_by_typos.sold)}
            <tbody />
          </BaseTable>
          <BaseTable className="white-background mb-12 height-200px">
            {singleHead('Synthèse lots retirés', '3', vefa)}
            {program.stats_by_typos.withdrawn && col5TableProgram(program.stats_by_typos.withdrawn)}
            <tbody />
          </BaseTable>
        </div>
      </div>
    </div>
  )
}
const RangeContainer = styled.div`
  width: 100%;
  padding: 10px 16px;
  margin-top: 8px;

  #range-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  #range-track {
    position: relative;
    height: 12px;
    width: 100%;
    padding: 0px;
    margin-bottom: 8px;
    background: linear-gradient(to right, #FDF7F7, #F3CBCC);
  }

  .thumb-mark {
    height: 20px;
    width: 2px;
    padding: 0;
    border-radius: 20px;
    background-color: #31325f;
  }
  #thumb-mark-prog {
    width: 2px;
    background-color: #d95d57;
  }

  .thumb-label {
    padding: 0px !important;
    position: absolute;
    bottom: 20px;
    font-size: 10px;
    width: 44px;
    left: -16px;
  }
  .no-display {
    display: none !important;
  }

  .scale {
    display: flex;
    justify-content: space-between;
  }
`
const Body2 = styled.div`
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: -0.017em;

  a {
    text-decoration-line: underline;
    font-weight: 500;
  }
`
const Caption1 = styled.div`
  font-family: "Gilroy";
  color: $candy-primary;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.017em;
`

const Caption2 = styled(Caption1)`
  font-size: 10px;
  line-height: 12px;
`

export default DetailedProgram
