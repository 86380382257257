import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Select from 'react-select'
import { Tooltip } from './Calculator'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import Cleave from 'cleave.js/react'
import { GoogleSearchInput } from '../../components/inscription/googleplace/googleplaces'
import { geocodeByAddress } from 'react-places-autocomplete'
import { selectStyle, selectStyleMobile } from './selectStyle'
import pinelData from './pinelData'
import { device } from '../../stylesheets/device/device'
import { isMobile } from 'react-device-detect'
import Logotype from '../../images/v2/logotype.svg'
import Icon3 from '../../images/desktop/property_development/faq-1.svg'
import Icon1 from '../../images/desktop/property_development/faq-2.svg'
import Icon2 from '../../images/desktop/property_development/faq-3.svg'

const options = {
  propertyType: [
    {
      value: 1,
      label: 'Maison ou Local'
    },
    {
      value: 2,
      label: 'Appartement'
    },
    {
      value: 3,
      label: 'Terrain à bâtir'
    }
  ],
  ownershipOrigin: [
    {
      value: 1,
      label: 'Achat'
    },
    {
      value: 2,
      label: 'Donation - succession'
    },
  ]
}

const tips = {
  a: "Si vous répondez oui, cela signifie que vous n'êtes pas affilié à un régime de sécurité sociale français.",
  b: "Pour la suite, indiquez les montants globaux d'acquisition et de cession. Votre quote part sera prise en compte dans le calcul final.",
  c: "Si la vente n'a pas eu lieu, indiquez une date estimée de l'acte de vente définitif",
  d: "<i>Pour les maison, appartements et autres batis : il s'agit des dépenses de construction, reconstruction, agrandissement ou amélioration. Pour les terrains à bâtir : il s'agit des notamment frais de voirie, réseaux et distribution, drainage etc.<br /></i> Si les dépenses de travaux ne sont pas justifiables et si la cession concerne un immeuble bâti plus de 5 ans après son acquisition, vous pouvez déclarer d'un forfait travaux de 15% de la valeur d'achat.",
  e: "Il s'agit de l'estimation de votre bien sur le marché de l'ancien si vous le vendiez à un particulier.",
  f: "Si vous n'avez pas signe de promesse de vente, il s'agit du montant estimé ou de la valeur indiquée dans une offre qui vous a été remise."
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const Main = ({ answers, handleAnswers, completed, handleSimulation, handleCompleted }) => {
  const [googlePlacesFocuses, setGooglePlacesFocused] = useState(false)
  const [buyingDateFocused, setBuyingDateFocused] = useState(false)
  const [sellingDateFocused, setSellingDateFocused] = useState(false)
  const [stopAutoScroll, setStopAutoScroll] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [buyingFeesFilled, setBuyingFeesFilled] = useState(false)
  const [constructionFeesFilled, setConstructionFeesFilled] = useState(false)
  const [isP3, setIsP3] = useState(false)
  const [loading, setLoading] = useState(false)
  const [intro, setIntro] = useState(true)
  moment.locale('fr')

  const getInseeCode = (postcode, address) => {
    fetch(`https://api-adresse.data.gouv.fr/search/?q=?postcode=${postcode}`)
      .then(r => r.json())
      .then((res) => {
        const code = parseInt(res.features[0].properties.citycode)
        const abattement_exceptionnel = typeof pinelData.find(c => c.code === code) !== 'undefined'
        handleAnswers({ ...answers, abattement_exceptionnel, address: { complete: true, content: address } })
      })
  }

  const handleSelect = address => geocodeByAddress(address)
    .then((results) => {
      getInseeCode(results[0].address_components.find(c => c.types.includes('postal_code')).short_name, address)
    })

  const returnYears = () => {
    const years = []
    for (let i = moment().year() - 100; i <= moment().year() + 20; i++) {
      years.push(<option value={i} key={i}>{i}</option>)
    }
    return years
  }

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
              <option value={value} key={value}>{capitalizeFirstLetter(label)}</option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {returnYears()}
        </select>
      </div>
    </div>
  )

  useEffect(() => {
    if (!stopAutoScroll && !completed && !intro) {
      document.getElementById('scrolltobottom').scrollIntoView({ behavior: 'smooth' })
    }

    if (answers.isPrimaryHouse !== 'true' && answers.propDevSelling === 'false' || answers.propertyType.value === 2 || answers.propDevSelling === 'false') {
      setIsP3(true)
    } else {
      setIsP3(false)
    }

    if ((answers.buyingDate && answers.sellingDate) && (answers.buyingDate >= answers.sellingDate)) {
      setDateError(true)
    } else {
      setDateError(false)
    }

    if (answers.propertyType.value !== null) {
      setIntro(false)
    }

  }, [answers])

  return (
    <MainWrapper completed={completed} intro={intro}>
      <nav>
        <a href="/">
          <img src={Logotype} alt="Logo City&You" />
        </a>
      </nav>
      <h1>Obtenez instantanément le calcul de l’impôt sur la plus-value</h1>
      <div className="calc-question">
        <label>
          Quel type de bien vendez-vous ?
        </label>
        <Select
          value={answers.propertyType.value ? answers.propertyType : null}
          onChange={e => handleAnswers({ ...answers, propertyType: e })}
          options={options.propertyType}
          isSearchable={false}
          placeholder="Choisir"
          styles={isMobile ? selectStyleMobile : selectStyle}
          id="calc-input-pulse"
        />
      </div>
      {
        answers.propertyType.value !== null && (
          <div className="calc-question">
            <label>
              Quelle est l'adresse de votre bien ?
            </label>
            <GoogleSearchInput
              address={answers.address.content}
              handleChangeAddress={(e) => {
                setGooglePlacesFocused(true)
                handleAnswers({ ...answers, address: { complete: false, content: e } })
              }}
              handleSelect={(e) => {
                setLoading(true)
                setGooglePlacesFocused(false)
                handleSelect(e)
                setLoading(false)
              }}
              errors={{ address: [] }}
            />
          </div>
        )
      }

      { loading && <Loader size={isMobile ? 24 : 32} /> }

      {
        answers.address.content !== '' && answers.address.complete && answers.propertyType.value !== 3 && !googlePlacesFocuses && (
          <div className="calc-question">
            <label>
              Est-ce votre résidence principale ?
            </label>
            <Radio
              active={answers.isPrimaryHouse}
              setActive={(e) => handleAnswers({ ...answers, isPrimaryHouse: e })}
              labels={['Oui', 'Non']}
            />
          </div>
        )
      }

      {
        ((answers.propertyType.value === 1 && answers.isPrimaryHouse !== null) || (answers.propertyType.value === 3 && answers.address.complete)) && (
          <div className="calc-question">
            <label>
              Votre projet est-il de vendre à un promoteur immobilier ?
            </label>
            <Radio
              active={answers.propDevSelling}
              setActive={(e) => handleAnswers({ ...answers, propDevSelling: e })}
              labels={['Oui', 'Non']}
            />
          </div>
        )
      }

      {
        ((answers.propertyType.value === 2 && answers.isPrimaryHouse === 'false') || (answers.propDevSelling !== null && answers.propertyType.value !== 2)) && (
          <div className="calc-question">
            <label>
              Êtes-vous résident ?
            </label>
            <Radio
              active={answers.frenchResident}
              setActive={(e) => handleAnswers({ ...answers, frenchResident: e })}
              labels={['De France', 'Hors de France']}
              large
            />
          </div>
        )
      }

      {
        answers.frenchResident !== null && answers.frenchResident === 'false' && (
          <div className="calc-question">
            <label>
              Etes-vous affilié à un régime de sécurité sociale de l'UE (hors France), EEE ou de Suisse ?
              <Tooltip tip={tips.a} />
            </label>
            <Radio
              active={answers.socialSecurityOutsideFrance}
              setActive={(e) => handleAnswers({ ...answers, socialSecurityOutsideFrance: e })}
              labels={['Oui', 'Non']}
              large
            />
          </div>
        )
      }

      {
        answers.frenchResident !== null && (
          <div className="calc-question">
            <label>
              Êtes-vous l'unique propriétaire ?
            </label>
            <Radio
              active={answers.soleOwner}
              setActive={(e) => handleAnswers({ ...answers, soleOwner: e })}
              labels={['Oui', 'Non']}
              large
            />
          </div>
        )
      }
      {
        answers.soleOwner === 'false' && (
          <div className="calc-question">
            <label>
              {
                isMobile ? 'Votre quote-part ?' : 'Quelle est votre quote-part du bien ?'
              }
              <Tooltip tip={tips.b} />
              <span>%</span>
            </label>
            <input
              className="input-focus"
              type="number"
              min={0}
              max={100}
              onChange={(e) => handleAnswers({ ...answers, share: e.target.value })}
              value={answers.share}
            />
          </div>
        )
      }

      {
        answers.soleOwner !== null && (
          <div className="calc-question">
            <label>
              Comment êtes-vous devenu propriétaire ?
            </label>
            <Select
              value={answers.ownershipOrigin.value ? answers.ownershipOrigin : null}
              onChange={(e) => handleAnswers({ ...answers, ownershipOrigin: e })}
              options={options.ownershipOrigin}
              isSearchable={false}
              placeholder="Choisir"
              styles={isMobile ? selectStyleMobile : selectStyle}
            />
          </div>
        )
      }

      {
        answers.ownershipOrigin.value !== null && (
          <div className="row">
            <div>
              <label>
                Date d’acquisition du bien ?
              </label>

              <SingleDatePicker
                id="date-picker-1"
                date={answers.buyingDate}
                onDateChange={(date) => handleAnswers({ ...answers, buyingDate: date })}
                focused={buyingDateFocused}
                onFocusChange={({ focused }) => setBuyingDateFocused(focused)}
                firstDayOfWeek={1}
                numberOfMonths={1}
                placeholder={moment().subtract(30, 'years').format('L')}
                isOutsideRange={(day) => moment().diff(day) < 0}
                hideKeyboardShortcutsPanel
                displayFormat={() => 'DD/MM/YYYY'}
                renderMonthElement={renderMonthElement}
              />
            </div>

            <div>
              <label>
                Date de cession du bien ?
                {
                  dateError && <div className="calc-error">La date de cession doit être postérieure à la date d'acquisition</div>
                }
                <Tooltip tip={tips.c} />
              </label>

              <SingleDatePicker
                id="date-picker-2"
                date={answers.sellingDate}
                onDateChange={(date) => handleAnswers({ ...answers, sellingDate: date })}
                focused={sellingDateFocused}
                onFocusChange={({ focused }) => setSellingDateFocused(focused)}
                firstDayOfWeek={1}
                numberOfMonths={1}
                placeholder={moment().format('L')}
                hideKeyboardShortcutsPanel
                isOutsideRange={day => !day}
                displayFormat={() => 'DD/MM/YYYY'}
                renderMonthElement={renderMonthElement}
              />
            </div>
          </div>
        )
      }

      {
        answers.sellingDate !== null && answers.buyingDate !== null && !dateError && (
         <div className="calc-question">
           <label>
             {answers.ownershipOrigin.value === 2 ? "Valeur d'acquisition retenue" : "Prix d’acquisition du bien ?" }
             <span>€</span>
           </label>
           <Cleave
             className="input-focus"
             options={ { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: '', numeralPositiveOnly: true } }
             numericOnly
             onFocus={() => setStopAutoScroll(true)}
             onBlur={() => setStopAutoScroll(false)}
             onChange={(e) => {
              handleAnswers({ ...answers, buyingPrice: e.target.value })
             }}
             value={answers.buyingPrice}
           />
         </div>
        )
      }

      {
        answers.buyingPrice !== '' && (
          <div className="calc-question">
            <label>
              Frais d’acquisition du bien ?
              <span>€</span>
            </label>
            <Cleave
              className="input-focus"
              options={ { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: '', numeralPositiveOnly: true } }
              numericOnly
              onFocus={() => setBuyingFeesFilled(true)}
              onBlur={() => {
                if (answers.buyingFees === '') {
                  setBuyingFeesFilled(false)
                }
              }}
              onChange={(e) => handleAnswers({ ...answers, buyingFees: e.target.value })}
              value={(answers.buyingFees === '' && parseInt(answers.buyingPrice, 10) > 0 && !buyingFeesFilled) ? parseInt((parseInt(answers.buyingPrice.replace(/ /g,''), 10) * 0.075), 10) : answers.buyingFees}
            />
          </div>
        )
      }

      {
        answers.buyingPrice !== '' && answers.propertyType.value !== 3 && (
          <div className="calc-question">
            <label>
              Montant des travaux ?
              <Tooltip tip={tips.d} />
              <span>€</span>
            </label>
            <Cleave
              className="input-focus"
              options={ { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: '', numeralPositiveOnly: true } }
              numericOnly
              onFocus={() => setConstructionFeesFilled(true)}
              onBlur={() => {
                if (answers.constructionFees === '') {
                  setConstructionFeesFilled(false)
                }
              }}
              onChange={(e) => handleAnswers({ ...answers, constructionFees: e.target.value })}
              value={(answers.constructionFees === '' && parseInt(answers.buyingPrice, 10) > 0 && !constructionFeesFilled) ? parseInt((parseInt(answers.buyingPrice.replace(/ /g,''), 10) * 0.15), 10) : answers.constructionFees}
            />
          </div>
        )
      }

      {
        answers.buyingPrice !== '' && isP3 && (
          <div className="calc-question">
            <label>
              Prix de vente
              <span>€</span>
            </label>
            <Cleave
              className="input-focus"
              options={ { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: '', numeralPositiveOnly: true } }
              numericOnly
              onFocus={() => setStopAutoScroll(false)}
              onChange={(e) => handleAnswers({ ...answers, sellingPrice: e.target.value })}
              value={answers.sellingPrice}
            />
          </div>
        )
      }

      {
        answers.buyingPrice !== '' && !isP3 && (
          <div className="calc-question">
            <label>
              {
                isMobile ? "Estimation marché de l'ancien" : "Estimation de la valeur sur le marché de l'ancien"
              }
              <Tooltip tip={tips.e} />
              <span>€</span>
            </label>
            <Cleave
              className="input-focus"
              options={ { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: '', numeralPositiveOnly: true } }
              numericOnly
              onFocus={() => setStopAutoScroll(false)}
              onChange={(e) => handleAnswers({ ...answers, sellingPriceEstimate: e.target.value })}
              value={answers.sellingPriceEstimate}
            />
          </div>
        )
      }

      {
        answers.sellingPriceEstimate !== '' && !isP3 && (
          <div className="calc-question">
            <label>
              {
                isMobile ? "Prix de vente promoteur" : "Prix de vente à un promoteur immobilier"
              }
              <Tooltip tip={tips.f} />
              <span>€</span>
            </label>
            <Cleave
              className="input-focus"
              options={ { numeral: true, numeralThousandsGroupStyle: 'thousand', delimiter: '', numeralPositiveOnly: true } }
              numericOnly
              onFocus={() => setStopAutoScroll(false)}
              onChange={(e) => handleAnswers({ ...answers, sellingPricePropDev: e.target.value })}
              value={answers.sellingPricePropDev}
            />
          </div>
        )
      }

      {
        ((answers.sellingPriceEstimate !== '' && answers.sellingPricePropDev !== '') || answers.sellingPrice !== '') && (
          <div className="calculator-btn-wrapper">
            <button className="service-cta" type="button" onClick={() => handleSimulation()}>
              <span>Calculez votre impôt</span>
            </button>
            { completed && <span className="arrow-bottom animated bounce" /> }
          </div>
        )
      }

      {
        intro && (
          <div className="calc-intro">
            <h2><img src={Icon1} />Qu’est-ce que la plus-value immobilière ? </h2>
            <p>La <a target="_blank" href="https://www.cityandyou.com/blog/2020/01/06/diminuer-taxe-sur-la-plus-value">plus-value immobilière</a> est la différence entre le prix d’achat (ou la valeur déclarée lors d’une donation) et le prix de vente d’un bien immobilier. C’est sur le montant de cette plus-value qu’est calculé l’impôt dont vous devrez vous acquitter lors de la signature de l’acte de vente définitif de votre bien. </p>

            <h2><img src={Icon2} />Comment se calcule le montant de l’impôt ou de la taxe sur la plus-value immobilière ? </h2>
            <p>Le montant de l’impôt sur la plus-value varie selon la durée de détention du bien selon trois barèmes distincts : l’impôt sur le revenu (19%), l’impôt sur les prélèvements sociaux (17,2%) et la surtaxe sur les plus-values imposées à l’IR supérieures à 50 000 euros. Ces trois impôts sont dégressifs avec la durée de détention.</p>

            <h2><img src={Icon3} />Comment optimiser le montant de votre plus-value immobilière ?</h2>
            <p>Votre imposition peut être optimisée dans certains cas lorsque vous vendez votre bien à un promoteur immobilier. Afin de libérer le foncier et de favoriser la construction de logements, l’état autorise des réductions de 70% - et dans certaines conditions exonère totalement – de l’impôt sur les plus-values immobilières.</p>
          </div>
        )
      }

    </MainWrapper>
  )
}

const Radio = ({ active, setActive, labels, large }) => (
  <RadioStyle active={active} large={large}>
    <button type="button" onClick={() => setActive('true')}>{labels[0]}</button>
    <button type="button" onClick={() => setActive('false')}>{labels[1]}</button>
  </RadioStyle>
)

const pulse = keyframes`
  0% {
    background-color: white;
    border-radius: 28px;
  }

  50% {
    background-color: #d8e5ef;
    border-radius: 28px;
  }

  100% {
    background-color: white;
    border-radius: 28px;
  }
`

const MainWrapper = styled.main`
  width: 100%;
  max-width: 736px;
  padding: 60px 12px ${props => props.completed ? '24px' : '20vh'} 12px;

  nav {
    display: none;
  }

  h1 {
    line-height: 1.13;
    letter-spacing: -1.2px;
    font-size: 40px;
    margin-bottom: 44px;
  }

  .calc-intro {
    margin-top: 100px;
    padding: 12px;
    border-radius: 6px;

    color: ${props => props.theme.candyPrimary};

    h2 {
      font-size: 18px;
      line-height: 1.2;
      display: flex;
      align-items: center;

      img {
        margin-right: 12px;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 24px;

      a {
        text-decoration: underline;
      }
    }
  }

  label {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.theme.candyPrimary};
    margin-bottom: 32px;
    width: fit-content;

    span {
      position: absolute;
      top: 72px;
      left: 221px;
    }

    .calc-tooltip {
      top: 0;
      left: auto;
      right: -22px;
    }

    .calc-error {
      position: absolute;
      color: red;
      top: 20px;
      font-size: 12px;
    }
  }

  .calc-question {
    margin-bottom: 40px;
  }

  #calc-input-pulse > div {
    animation: ${pulse} 1s linear ${props => props.intro ? '3' : '0'};
  }

  select option {
    background: white;
  }

  .input-focus {
    width: 250px;
    height: 50px;
    padding: 16px 24px;
    border: 1px solid ${props => props.theme.candyPrimary};
    color: ${props => props.theme.candyPrimary};
    border-radius: 28px;
  }

  .ctn-google-search-input {
    border: 1px solid ${props => props.theme.candyPrimary};
    background: white;
    width: 100%;
    height: 50px;
    max-width: 720px;
    border-radius: 28px;
    display: flex;
    margin-bottom: 40px;

    input {
      margin: 0;
      padding-left: 12px;
      background: white;
      width: 100%;
      color ${props => props.theme.candyPrimary};
      border-radius: 28px;
    }

    .autocomplete-dropdown-container {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;

      .suggestion-item {
        color: ${props => props.theme.candyPrimary};
      }
    }
  }

  .suggestion-item, .suggestion-item--active {
    height: 54px !important;
    background-color: white !important;
    font-size: 18px;
    padding-left: 12px;
    display: flex;
    align-items: center;
  }

  .suggestion-item--active {
    background-color: ${(props) => props.theme.candyPrimary} !important;
    color: white !important;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .SingleDatePicker {

    select {
      color: ${(props) => props.theme.candyPrimary};
      background: none;
      border: none;
      outline: none;
      font-family: 'Gilroy';
      font-size: 18px;
      cursor: pointer;

      option:hover {
        background: red;
        box-shadow: 0 0 10px 100px ${(props) => props.theme.candyPrimary} inset;
      }
    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
      background: ${props => props.theme.candyPrimary}
    }

    .SingleDatePickerInput__withBorder {
      border-radius: 28px;
      border: 1px solid ${props => props.theme.candyPrimary};
      height: 50px;
      margin-bottom: 40px;
    }

    .DateInput {
      width: 250px;
      height: 100%;
      background: transparent;
      display: flex;
      align-items: center;

      input {
        margin: 0;
        background: transparent;
        padding-left: 16px;
      }
    }

    .DateInput_input {
      padding: 2px;
      font-weight: normal;
      color: ${props => props.theme.candyPrimary} !important;
    }

    .DateInput_input__focused {
      border: none;
    }

    .DayPickerNavigation_button__horizontalDefault {
      top: 24px;
    }
  }

  .calculator-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .service-cta {
      z-index: 0;
    }

    .arrow-bottom {
      position: relative;
      display: inline-block;
      width: 21px;
      height: 21px;
      border: 2px solid ${props => props.theme.candyPrimary};
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 5px;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-top: 2px solid ${props => props.theme.candyPrimary};
        border-right: 2px solid ${props => props.theme.candyPrimary};
        -moz-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }
  }

  @media ${device.laptop} {
    padding: 0 18px 64px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;

    nav {
      width: 240px;
      padding-top: 24px;
      display: block;

      img {
        max-width: 42px;
      }
    }

    h1 {
      font-size: 28px;
      margin-top: 0;
      line-height: 1.43;
      width: 240px;
    }

    .calc-intro {
      width: 240px;
      margin: 0 auto;

      h2 {
        font-size: 16px;
      }
    }

    .input-email {
      font-size: 14px;
    }

    .SingleDatePicker {
      width: 100% !important;
    }

    .DateInput_input {
      font-size: 14px !important;
    }

    .calc-tooltip {
      display: none;
      // position: initial;
      // left: auto !important;
      // right: auto !important;
      // top: auto !important;
      // margin-left: 8px;
    }

    .calc-question {
      width: 240px;
      margin-bottom: 24px;

      label {
        font-size: 16px;
        flex-direction: row;

        span {
          left: 180px;
        }
      }

      input {
        width: 100%;
        font-size: 14px;
      }
    }

    .suggestion-item {
      min-height: 32px !important;
      font-size: 12px;
    }

    .row {
      flex-direction: column;

      label {
        font-size: 16px;
        flex-direction: row;
      }
    }
  }
`

const RadioStyle = styled.div`
  display: flex;
  height: 50px;
  width: ${(props) => props.large ? '300px' : '250px'};
  margin-bottom: 40px;

  button {
    width: 50%;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    background-color: white;
    color: ${props => props.theme.candyPrimary};
    border-color: ${props => props.theme.candyPrimary};;
    font-weight: normal;
  }


  button:first-of-type {
    background-color: ${props => props.active === 'true' ? props.theme.candyPrimary : 'white'};
    color: ${props => props.active === 'true' ? 'white' : props.theme.candyPrimary};
    border-color: ${props => props.active === 'true' ? props.theme.candyPrimary : '#dbe0e4'};
    font-weight: ${props => props.active === 'true' ? 'bold' : 'normal'};
    padding-left: 12px;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
  }

  button:last-of-type {
    background-color: ${props => props.active === 'false' ? props.theme.candyPrimary : 'white'};
    color: ${props => props.active === 'false' ? 'white' : props.theme.candyPrimary};
    border-color: ${props => props.active === 'false' ? props.theme.candyPrimary : '#dbe0e4'};
    font-weight: ${props => props.active === 'false' ? 'bold' : 'normal'};
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    padding-right: 12px;
  }

  @media ${device.laptop} {
    width: 100%;

    button {
      font-size: 14px;
    }
  }
`

const loaderSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.span`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: red;
  animation: ${loaderSpin} .8s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Main
