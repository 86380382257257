import React from 'react'
import PropTypes from 'prop-types'

export const Errors = ({ message }) => (
  <div className="ctn-errors" data-testid="error">
    <p className="is-danger">{message}</p>
  </div>
);

Errors.propTypes = {
  message: PropTypes.string
}

Errors.defaultProps = {
  message: ''
}
