import axios from 'axios'
import { getDateInfo } from '../../utils/utils'
import { housingData } from '../../data/housingMarketData'

const { data } = require('../../data/data')

const extractDepartementId = (address) => {
  if (address.match(/\d{5}/)) {
    const postalCode = address.match(/\d{5}/)[0]
    return postalCode.substring(0, 2)
  }

  return "94"
}

const saleReadinessFormat = (sr) => {
  if (sr.value === 6) {
    return 0
  }

  if (sr.value) {
    return sr.value
  }
  return null
}

export const greenfieldEstimation = (estimation, area, address) => {
  const departementCode = extractDepartementId(address)
  const squareMeterEstimation = estimation / 100
  const meanDepartementGreenfieldSquareMeter = housingData[departementCode].greenfield
  const meanDepartementHouseSquareMeter = housingData[departementCode].house

  return area * meanDepartementGreenfieldSquareMeter * (squareMeterEstimation / meanDepartementHouseSquareMeter)
}

export function formatBodyForSendingForm({
  errors = [], email, firstName, lastName, phone, category,
  gender, subscribedToNewsletter, thirdPartySharingOptOut, transactionRole, saleReadiness = 0, squareMeter,
  area, confidenceMin, confidenceMax, predictedPrice, confidenceIndex, slug, rooms, condition = 0
}) {
  if (errors.length > 0) {
    return
  }

  const addressObject = JSON.parse(sessionStorage.getItem('address'))

  if (data.dataForm.properties[category].value === 0) {
    confidenceMin = greenfieldEstimation(confidenceMin, area, addressObject[0].formatted_address)
    confidenceMax = greenfieldEstimation(confidenceMax, area, addressObject[0].formatted_address)
    predictedPrice = greenfieldEstimation(predictedPrice, area, addressObject[0].formatted_address)
    confidenceIndex = 2
  }

  return {
    user: {
      email,
      firstName,
      lastName,
      gender,
      phone: typeof phone === 'object' ? phone.number : phone,
      transactionRole: data.dataForm.whoAreYou[transactionRole].value,
      subscribedToNewsletter,
      thirdPartySharingOptOut,
      saleReadiness: saleReadinessFormat(saleReadiness)
    },
    property: {
      latitude: typeof addressObject[0].geometry.location.lat === 'function' ? addressObject[0].geometry.location.lat() : addressObject[0].geometry.location.lat,
      longitude: typeof addressObject[0].geometry.location.lng === 'function' ? addressObject[0].geometry.location.lng() : addressObject[0].geometry.location.lng,
      category: data.dataForm.properties[category].value,
      livingArea: squareMeter,
      area,
      condition: condition.value || null,
      rooms
    },
    valuation: {
      confidenceMin,
      confidenceMax,
      predictedPrice,
      confidenceIndex,
      slug
    },
    type: typeof addressObject[0].types[0] === 'function' ? addressObject[0].types[0]() : addressObject[0].types[0]
  }
}

export const getStatusErrors = response => response.status === 403 || response.status === 500

export function postForm(body) {
  return fetch('/api/v1/users', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(responseJson => responseJson)
}

export function sendFormContentToPipedrive({
  errors, email, firstName, lastName, phone, category, gender,
  subscribedToNewsletter, thirdPartySharingOptOut, transactionRole, squareMeter, analyticsActive, area
}) {
  const body = formatBodyForSendingForm({
    errors,
    email,
    firstName,
    lastName,
    phone,
    category,
    gender,
    subscribedToNewsletter,
    thirdPartySharingOptOut,
    transactionRole,
    squareMeter,
    area
  })

  try {
    const { analytics } = window
    const { hours, day } = getDateInfo()
    if (analyticsActive) {
      analytics.identify(body)
      analytics.track('Next step formulaire', {
        callToAction: 'Découvrir votre estimation',
        day,
        hours,
        data: body
      })
    }
    if (typeof gtag_report_conversion === "function") {
      gtag_report_conversion()
    }

    return Promise.resolve(postForm(body))
  } catch (e) {
    if (this.props.analyticsActive) {
      analytics.track('ERROR FORM', {errors: e, body})
    }
    return 'Ok'
  }
}

const formatBedrooms = rooms => rooms == 0 ? 0 : rooms - 1

const formatArea = area => area == 0 ? 1 : area

const houseURL = (lat, long, area, groundArea, rooms) => `/api/v1/valuations?lat=${lat}&long=${long}&surface=${area}&garden_surface=${groundArea}&nb_room=${rooms}`

const greenfieldUrl = (lat, long) => `/api/v1/valuations?lat=${lat}&long=${long}&surface=100&garden_surface=250&nb_room=5`

export function retrieveEstimation(address, area, groundArea, rooms, propertyType) {
  const addressObject = JSON.parse(sessionStorage.getItem('address'))
  const latitude = typeof addressObject[0].geometry.location.lat === 'function' ? addressObject[0].geometry.location.lat() : addressObject[0].geometry.location.lat
  const longitude = typeof addressObject[0].geometry.location.lng === 'function' ? addressObject[0].geometry.location.lng() : addressObject[0].geometry.location.lng

  const url = propertyType === 2
    ? greenfieldUrl(latitude, longitude)
    : houseURL(latitude, longitude, area, groundArea, rooms)

  return axios
    .post(url)
    .then(response => response.data.valuations)
    .catch((response) => {
      console.log(response)
    })
}

export function checkEmailIfExist(email) {
  return fetch(`/api/v1/users/check_email_uniqueness?email=${email}`)
    .then(response => response)
    .then(responseJson => responseJson.json())
}

export function checkIfAddressIsAlreadyTaken(streetAddress, postalCode, city, country) {
  return fetch(`/api/v1/properties/check_address_uniqueness?streetAddress=${streetAddress}&postalCode=${postalCode}&city=${city}&country=${country}`)
    .then(response => response)
    .then(responseJson => responseJson.json())
}

export function checkIfLatLongIsAlreadyTaken(lat, lng) {
  return fetch(`/api/v1/properties/check_address_uniqueness?lat=${lat}&long=${lng}`)
    .then(response => response)
    .then(responseJson => responseJson.json())
}

export function checkEmail(email) {
  return Promise.resolve(checkEmailIfExist(email))
}

export function checkAddress(address) {
  return Promise.resolve(checkIfAddressIsAlreadyTaken(address.streetAddress, address.postalCode, address.city, address.country))
}

export function checkLatLong(address) {
  return Promise.resolve(checkIfLatLongIsAlreadyTaken(address.lat, address.lng))
}
