const blogLink = document.getElementById('blog-link')
const cookiesBtn = document.getElementById('cookies-btn')

if (blogLink) {
  blogLink.addEventListener('click', () => {
    const { analytics } = window
    analytics.track('Home - Blog')
  })
}

if (cookiesBtn) {
  cookiesBtn.addEventListener('click', () => {
    const { analytics } = window
    analytics.track('Home - Cookies')
  })
}
