const button1 = document.getElementById('product-button-1')
const button2 = document.getElementById('product-button-2')
const button3 = document.getElementById('product-button-3')
const productButtons = document.getElementsByClassName('product-feature')
const screen = document.getElementById('product-screenshot')

function removeClassByPrefix(node, prefix) {
  var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
  node.className = node.className.replace(regx, '');
  return node;
}

const removeCurrentScreen = () => {
  removeClassByPrefix(screen, 'product-screenshot--')
}

const removeActiveButtons = () => {
  Array.prototype.forEach.call(productButtons, el => {
    removeClassByPrefix(el, 'product-feature--')
  })
}

if (button1 && button2 && button3) {
  button1.addEventListener('click', () => {
    if (!button1.classList.contains("product-feature--active")) {
      removeActiveButtons()
      button1.classList.add("product-feature--active")
      removeCurrentScreen()
      screen.classList.add('product-screenshot--1')
    }
  })

  button2.addEventListener('click', () => {
    if (!button2.classList.contains("product-feature--active")) {
      removeActiveButtons()
      button2.classList.add("product-feature--active")
      removeCurrentScreen()
      screen.classList.add('product-screenshot--2')
    }
  })

  button3.addEventListener('click', () => {
    if (!button3.classList.contains("product-feature--active")) {
      removeActiveButtons()
      button3.classList.add("product-feature--active")
      removeCurrentScreen()
      screen.classList.add('product-screenshot--3')
    }
  })

}
