import React from 'react';
import PropTypes from 'prop-types';

export const Burger = ({ open, onClick }) => (
  <button
    onClick={onClick}
    className={`hamburger hamburger--squeeze ${open ? 'is-active' : ''}`}
    type="button"
    aria-label="Navigation"
  >
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </button>
);

Burger.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

Burger.defaultProps = {
  open: false,
  onClick: () => null,
};
