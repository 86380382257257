import React, { useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../stylesheets/device/device'

const Extra = ({ img, content, cta, pink, last }) => {
  useEffect(() => {
    document.getElementById('calculator-extra').scrollIntoView({ behavior: 'smooth' })
  }, [])
  return (
    <ExtraWrapper pink={pink} last={last} id="calculator-extra">
      <div className="extra-container">
        <div className="extra-bloc">
          <div className="extra-bloc-text">
            <h3>{content.h3}</h3>
            <p>{content.p1}</p>
            <p><b>{content.p2}</b></p>
          </div>
          <div className="extra-bloc-img">
            <img src={img} />
            <span>{content.span}</span>
            <a href={content.a.href}>{content.a.text}</a>
          </div>
        </div>
        {
          cta && (
            <a className="service-cta" href="/simulation-projet">
              <span>Simulez votre projet</span>
            </a>
          )
        }
      </div>
    </ExtraWrapper>
  )
}

const ExtraWrapper = styled.div`
  font-family: Gilroy;
  color: ${props => props.theme.candyPrimary};
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.last ? '0' : '0'};
  background-color: ${props => props.pink ? props.theme.candyPink : 'transparent'};
  padding: 42px 0 ${props => props.last ? '42px' : '0'} 0;

  .service-cta {
    margin-top: 32px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.6px;
    line-height: 1.17;
    margin-top: 0;
  }

  p {
    line-height: 1;
    font-size: 18px;
  }

  b {
    font-weight: bold;
  }

  .extra-bloc {
    display: flex;
  }

  .extra-bloc-text {
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p:last-of-type {
      margin: 0;
    }
  }

  .extra-bloc-img {
    text-align: center;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      max-width: 150px;
      margin-bottom: 8px;
    }

    span {
      font-size: 14px;
      font-weight: bold;
    }

    a {
      font-size: 12px;
      text-decoration: underline;
      display: inline-block;
      margin-top: 12px;
    }
  }

  .extra-container {
    width: 100%;
    max-width: 736px;
    padding: 26px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media ${device.laptop} {
    padding: 0 24px;

    h3, p {
      font-size: 16px;
    }

    .extra-bloc {
      flex-direction: column;
    }

    .extra-bloc-text {
      padding: 0;
    }

    .extra-bloc-img {
      margin-top: 24px;
      align-items: center;
    }
  }
`

export default Extra
