import React from 'react'
import { TitleSimpleForm } from './TitleSimpleForm'
import { CardForm } from '../FormOnboarding/CardForm'
import { Errors } from './error'
import { useForm } from '../../context/form.context'
import { useStep } from '../../context/step.context'

export const AnswerWhoAreYou = ({
  data, transactionRole, select, step, id
}) => {
  const {errors} = useForm()
  const {increment} = useStep()

  return (
    <div
      className="container-answers fadeIn"
      id={id}
    >
      <TitleSimpleForm
        itemIndex={step}
        title="Qui êtes-vous ?"
      />
      <div className="ctn-card-list-form ctn-card-list-form--who">
        {data.dataForm.whoAreYou.map((e, i) => (
          <CardForm
            img={e.img}
            imgSelected={e.imgSelected}
            title={e.title}
            key={i}
            selected={transactionRole === i}
            select={() => {
              select(i === transactionRole ? null : i, 'transactionRole')
              setTimeout(() => increment(), 500)
            }}
            index={i}
          />
        ))}
      </div>
      {errors.transactionRole.includes('empty')
      && (
        <Errors
          message="Ce champ est requis."
        />
      )}
    </div>
  )
}
