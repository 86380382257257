import React from 'react'
import Select from 'react-select'
import { TitleSimpleForm } from '../../SimpleForm/TitleSimpleForm'
import { Errors } from '../../SimpleForm/error'
import { useForm } from '../../../context/form.context'

const options = [
  {
    value: 6,
    label: 'Non, je n’ai pas de projet de vente'
  },
  {
    value: 1,
    label: 'Oui, dans 1 an et plus'
  },
  {
    value: 2,
    label: 'Oui, dans 6 à 12 mois'
  },
  {
    value: 3,
    label: 'Oui, dans les 6 mois'
  },
  {
    value: 4,
    label: 'Oui, dans les 3 mois'
  },
  {
    value: 5,
    label: 'Oui, dès que possible'
  }
]

export const AnswerSaleReadiness = ({
  saleReadiness, select, step, id
}) => {
  const { errors } = useForm()
  return (
    <div
      className="container-answers fadeIn saleReadiness-container"
      data-testid="answer-property"
      id={id}
    >
      <TitleSimpleForm
        itemIndex={step}
        title="Envisagez-vous de vendre ce bien ?"
      />
      <div className="ctn-card-list-form with-select">
        <Select
          value={saleReadiness}
          onChange={select}
          options={options.reverse()}
          isSearchable={false}
          placeholder="Choisissez"
          styles={{
            control: base => ({
              ...base,
              border: errors.saleReadiness.length > 0 ? '1px solid #d95d57' : '1px solid #dbe0e4',
              borderRadius: '27px',
              display: 'flex',
              flexDirection: 'row',
              maxWidth: '282px',
              height: '53px',
              width: '282px !important',
              '&:hover': {
                borderColor: '#31325f',
                boxShadow: 'none !important'
              },
              '&:active': {
                borderColor: '#31325f'
              }
            }),
            placeholder: base => ({
              ...base,
              height: '24px',
              opacity: 0.8,
              fontFamily: 'Gilroy',
              fontSize: '18px',
              fontStyle: 'italic',
              letterSpacing: '-0.5px',
              textAlign: 'left',
              color: '#868898',
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center'
            }),
            singleValue: base => ({
              ...base,
              height: '24px',
              width: '100%',
              fontFamily: 'Gilroy',
              fontSize: '18px',
              fontStyle: 'normal',
              letterSpacing: '-0.5px',
              textAlign: 'left',
              color: '#000',
              lineHeight: '100%',
              display: 'flex',
              alignItems: 'center'
            }),
            indicatorSeparator: base => ({
              ...base,
              display: 'none'
            }),
            indicatorsContainer: base => ({
              ...base,
              svg: {
                fill: '#373737'
              }
            }),
            menu: base => ({
              ...base,
              width: '352px'
            }),
            option: (styles, {data, isDisabled, isFocused, isSelected}) => {
              return {
                ...styles,
                backgroundColor: 'white',
                color: '#000',
                fontWeight: isSelected ? 'bold' : 'normal',
                fontSize: '16px',
                cursor: isDisabled ? 'not-allowed' : 'default',
                width: '352px !important',
                ':active': {
                  ...styles[':active'],
                  backgroundColor: 'white'
                },
                ':hover': {
                  ...styles[':active'],
                  backgroundColor: 'white',
                  fontWeight: 'bold',
                }
              }
            }
          }}
        />
        {errors.saleReadiness.includes('empty')
        && (
          <Errors
            message="Ce champ est requis."
          />
        )}
      </div>
    </div>
  )
}
