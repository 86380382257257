export const pinelData = [
  {
    "code": 1071,
    "zone": "A"
  },
  {
    "code": 1109,
    "zone": "A"
  },
  {
    "code": 1143,
    "zone": "A"
  },
  {
    "code": 1160,
    "zone": "A"
  },
  {
    "code": 1173,
    "zone": "A"
  },
  {
    "code": 1281,
    "zone": "A"
  },
  {
    "code": 1288,
    "zone": "A"
  },
  {
    "code": 1313,
    "zone": "A"
  },
  {
    "code": 1354,
    "zone": "A"
  },
  {
    "code": 1399,
    "zone": "A"
  },
  {
    "code": 1419,
    "zone": "A"
  },
  {
    "code": 6004,
    "zone": "A"
  },
  {
    "code": 6006,
    "zone": "A"
  },
  {
    "code": 6007,
    "zone": "A"
  },
  {
    "code": 6010,
    "zone": "A"
  },
  {
    "code": 6011,
    "zone": "A"
  },
  {
    "code": 6012,
    "zone": "A"
  },
  {
    "code": 6015,
    "zone": "A"
  },
  {
    "code": 6018,
    "zone": "A"
  },
  {
    "code": 6025,
    "zone": "A"
  },
  {
    "code": 6026,
    "zone": "A"
  },
  {
    "code": 6027,
    "zone": "A"
  },
  {
    "code": 6029,
    "zone": "A"
  },
  {
    "code": 6030,
    "zone": "A"
  },
  {
    "code": 6031,
    "zone": "A"
  },
  {
    "code": 6032,
    "zone": "A"
  },
  {
    "code": 6033,
    "zone": "A"
  },
  {
    "code": 6034,
    "zone": "A"
  },
  {
    "code": 6035,
    "zone": "A"
  },
  {
    "code": 6038,
    "zone": "A"
  },
  {
    "code": 6039,
    "zone": "A"
  },
  {
    "code": 6044,
    "zone": "A"
  },
  {
    "code": 6046,
    "zone": "A"
  },
  {
    "code": 6048,
    "zone": "A"
  },
  {
    "code": 6054,
    "zone": "A"
  },
  {
    "code": 6059,
    "zone": "A"
  },
  {
    "code": 6060,
    "zone": "A"
  },
  {
    "code": 6064,
    "zone": "A"
  },
  {
    "code": 6065,
    "zone": "A"
  },
  {
    "code": 6067,
    "zone": "A"
  },
  {
    "code": 6068,
    "zone": "A"
  },
  {
    "code": 6069,
    "zone": "A"
  },
  {
    "code": 6075,
    "zone": "A"
  },
  {
    "code": 6079,
    "zone": "A"
  },
  {
    "code": 6083,
    "zone": "A"
  },
  {
    "code": 6084,
    "zone": "A"
  },
  {
    "code": 6085,
    "zone": "A"
  },
  {
    "code": 6088,
    "zone": "A"
  },
  {
    "code": 6089,
    "zone": "A"
  },
  {
    "code": 6090,
    "zone": "A"
  },
  {
    "code": 6095,
    "zone": "A"
  },
  {
    "code": 6104,
    "zone": "A"
  },
  {
    "code": 6105,
    "zone": "A"
  },
  {
    "code": 6108,
    "zone": "A"
  },
  {
    "code": 6109,
    "zone": "A"
  },
  {
    "code": 6112,
    "zone": "A"
  },
  {
    "code": 6113,
    "zone": "A"
  },
  {
    "code": 6114,
    "zone": "A"
  },
  {
    "code": 6117,
    "zone": "A"
  },
  {
    "code": 6121,
    "zone": "A"
  },
  {
    "code": 6122,
    "zone": "A"
  },
  {
    "code": 6123,
    "zone": "A"
  },
  {
    "code": 6126,
    "zone": "A"
  },
  {
    "code": 6128,
    "zone": "A"
  },
  {
    "code": 6136,
    "zone": "A"
  },
  {
    "code": 6137,
    "zone": "A"
  },
  {
    "code": 6138,
    "zone": "A"
  },
  {
    "code": 6140,
    "zone": "A"
  },
  {
    "code": 6147,
    "zone": "A"
  },
  {
    "code": 6148,
    "zone": "A"
  },
  {
    "code": 6149,
    "zone": "A"
  },
  {
    "code": 6150,
    "zone": "A"
  },
  {
    "code": 6152,
    "zone": "A"
  },
  {
    "code": 6155,
    "zone": "A"
  },
  {
    "code": 6157,
    "zone": "A"
  },
  {
    "code": 6159,
    "zone": "A"
  },
  {
    "code": 6161,
    "zone": "A"
  },
  {
    "code": 13001,
    "zone": "A"
  },
  {
    "code": 13002,
    "zone": "A"
  },
  {
    "code": 13005,
    "zone": "A"
  },
  {
    "code": 13007,
    "zone": "A"
  },
  {
    "code": 13012,
    "zone": "A"
  },
  {
    "code": 13013,
    "zone": "A"
  },
  {
    "code": 13014,
    "zone": "A"
  },
  {
    "code": 13015,
    "zone": "A"
  },
  {
    "code": 13016,
    "zone": "A"
  },
  {
    "code": 13019,
    "zone": "A"
  },
  {
    "code": 13020,
    "zone": "A"
  },
  {
    "code": 13021,
    "zone": "A"
  },
  {
    "code": 13022,
    "zone": "A"
  },
  {
    "code": 13023,
    "zone": "A"
  },
  {
    "code": 13025,
    "zone": "A"
  },
  {
    "code": 13026,
    "zone": "A"
  },
  {
    "code": 13028,
    "zone": "A"
  },
  {
    "code": 13031,
    "zone": "A"
  },
  {
    "code": 13033,
    "zone": "A"
  },
  {
    "code": 13039,
    "zone": "A"
  },
  {
    "code": 13040,
    "zone": "A"
  },
  {
    "code": 13041,
    "zone": "A"
  },
  {
    "code": 13042,
    "zone": "A"
  },
  {
    "code": 13043,
    "zone": "A"
  },
  {
    "code": 13046,
    "zone": "A"
  },
  {
    "code": 13047,
    "zone": "A"
  },
  {
    "code": 13054,
    "zone": "A"
  },
  {
    "code": 13055,
    "zone": "A"
  },
  {
    "code": 13056,
    "zone": "A"
  },
  {
    "code": 13060,
    "zone": "A"
  },
  {
    "code": 13062,
    "zone": "A"
  },
  {
    "code": 13063,
    "zone": "A"
  },
  {
    "code": 13070,
    "zone": "A"
  },
  {
    "code": 13071,
    "zone": "A"
  },
  {
    "code": 13072,
    "zone": "A"
  },
  {
    "code": 13073,
    "zone": "A"
  },
  {
    "code": 13075,
    "zone": "A"
  },
  {
    "code": 13077,
    "zone": "A"
  },
  {
    "code": 13081,
    "zone": "A"
  },
  {
    "code": 13085,
    "zone": "A"
  },
  {
    "code": 13086,
    "zone": "A"
  },
  {
    "code": 13087,
    "zone": "A"
  },
  {
    "code": 13088,
    "zone": "A"
  },
  {
    "code": 13092,
    "zone": "A"
  },
  {
    "code": 13095,
    "zone": "A"
  },
  {
    "code": 13098,
    "zone": "A"
  },
  {
    "code": 13101,
    "zone": "A"
  },
  {
    "code": 13102,
    "zone": "A"
  },
  {
    "code": 13104,
    "zone": "A"
  },
  {
    "code": 13106,
    "zone": "A"
  },
  {
    "code": 13107,
    "zone": "A"
  },
  {
    "code": 13109,
    "zone": "A"
  },
  {
    "code": 13110,
    "zone": "A"
  },
  {
    "code": 13112,
    "zone": "A"
  },
  {
    "code": 13113,
    "zone": "A"
  },
  {
    "code": 13117,
    "zone": "A"
  },
  {
    "code": 13119,
    "zone": "A"
  },
  {
    "code": "2A004",
    "zone": "A"
  },
  {
    "code": "2A041",
    "zone": "A"
  },
  {
    "code": "2A247",
    "zone": "A"
  },
  {
    "code": 34014,
    "zone": "A"
  },
  {
    "code": 34057,
    "zone": "A"
  },
  {
    "code": 34077,
    "zone": "A"
  },
  {
    "code": 34090,
    "zone": "A"
  },
  {
    "code": 34095,
    "zone": "A"
  },
  {
    "code": 34116,
    "zone": "A"
  },
  {
    "code": 34120,
    "zone": "A"
  },
  {
    "code": 34123,
    "zone": "A"
  },
  {
    "code": 34129,
    "zone": "A"
  },
  {
    "code": 34134,
    "zone": "A"
  },
  {
    "code": 34154,
    "zone": "A"
  },
  {
    "code": 34169,
    "zone": "A"
  },
  {
    "code": 34172,
    "zone": "A"
  },
  {
    "code": 34192,
    "zone": "A"
  },
  {
    "code": 34198,
    "zone": "A"
  },
  {
    "code": 34217,
    "zone": "A"
  },
  {
    "code": 34247,
    "zone": "A"
  },
  {
    "code": 34255,
    "zone": "A"
  },
  {
    "code": 34270,
    "zone": "A"
  },
  {
    "code": 34290,
    "zone": "A"
  },
  {
    "code": 34295,
    "zone": "A"
  },
  {
    "code": 34309,
    "zone": "A"
  },
  {
    "code": 34327,
    "zone": "A"
  },
  {
    "code": 34337,
    "zone": "A"
  },
  {
    "code": 34344,
    "zone": "A"
  },
  {
    "code": 59328,
    "zone": "A"
  },
  {
    "code": 59350,
    "zone": "A"
  },
  {
    "code": 59360,
    "zone": "A"
  },
  {
    "code": 59368,
    "zone": "A"
  },
  {
    "code": 59378,
    "zone": "A"
  },
  {
    "code": 59527,
    "zone": "A"
  },
  {
    "code": 59646,
    "zone": "A"
  },
  {
    "code": 60033,
    "zone": "A"
  },
  {
    "code": 60060,
    "zone": "A"
  },
  {
    "code": 60086,
    "zone": "A"
  },
  {
    "code": 60088,
    "zone": "A"
  },
  {
    "code": 60139,
    "zone": "A"
  },
  {
    "code": 60141,
    "zone": "A"
  },
  {
    "code": 60142,
    "zone": "A"
  },
  {
    "code": 60172,
    "zone": "A"
  },
  {
    "code": 60282,
    "zone": "A"
  },
  {
    "code": 60346,
    "zone": "A"
  },
  {
    "code": 60395,
    "zone": "A"
  },
  {
    "code": 60398,
    "zone": "A"
  },
  {
    "code": 60482,
    "zone": "A"
  },
  {
    "code": 60695,
    "zone": "A"
  },
  {
    "code": 69123,
    "zone": "A"
  },
  {
    "code": 69266,
    "zone": "A"
  },
  {
    "code": 74006,
    "zone": "A"
  },
  {
    "code": 74008,
    "zone": "A"
  },
  {
    "code": 74012,
    "zone": "A"
  },
  {
    "code": 74016,
    "zone": "A"
  },
  {
    "code": 74021,
    "zone": "A"
  },
  {
    "code": 74031,
    "zone": "A"
  },
  {
    "code": 74040,
    "zone": "A"
  },
  {
    "code": 74044,
    "zone": "A"
  },
  {
    "code": 74074,
    "zone": "A"
  },
  {
    "code": 74082,
    "zone": "A"
  },
  {
    "code": 74087,
    "zone": "A"
  },
  {
    "code": 74094,
    "zone": "A"
  },
  {
    "code": 74096,
    "zone": "A"
  },
  {
    "code": 74118,
    "zone": "A"
  },
  {
    "code": 74122,
    "zone": "A"
  },
  {
    "code": 74124,
    "zone": "A"
  },
  {
    "code": 74128,
    "zone": "A"
  },
  {
    "code": 74133,
    "zone": "A"
  },
  {
    "code": 74145,
    "zone": "A"
  },
  {
    "code": 74153,
    "zone": "A"
  },
  {
    "code": 74158,
    "zone": "A"
  },
  {
    "code": 74162,
    "zone": "A"
  },
  {
    "code": 74185,
    "zone": "A"
  },
  {
    "code": 74197,
    "zone": "A"
  },
  {
    "code": 74201,
    "zone": "A"
  },
  {
    "code": 74211,
    "zone": "A"
  },
  {
    "code": 74216,
    "zone": "A"
  },
  {
    "code": 74220,
    "zone": "A"
  },
  {
    "code": 74229,
    "zone": "A"
  },
  {
    "code": 74243,
    "zone": "A"
  },
  {
    "code": 74288,
    "zone": "A"
  },
  {
    "code": 74293,
    "zone": "A"
  },
  {
    "code": 74298,
    "zone": "A"
  },
  {
    "code": 74305,
    "zone": "A"
  },
  {
    "code": 74309,
    "zone": "A"
  },
  {
    "code": 74314,
    "zone": "A"
  },
  {
    "code": 77014,
    "zone": "A"
  },
  {
    "code": 77018,
    "zone": "A"
  },
  {
    "code": 77038,
    "zone": "A"
  },
  {
    "code": 77039,
    "zone": "A"
  },
  {
    "code": 77040,
    "zone": "A"
  },
  {
    "code": 77053,
    "zone": "A"
  },
  {
    "code": 77055,
    "zone": "A"
  },
  {
    "code": 77058,
    "zone": "A"
  },
  {
    "code": 77059,
    "zone": "A"
  },
  {
    "code": 77062,
    "zone": "A"
  },
  {
    "code": 77067,
    "zone": "A"
  },
  {
    "code": 77075,
    "zone": "A"
  },
  {
    "code": 77083,
    "zone": "A"
  },
  {
    "code": 77085,
    "zone": "A"
  },
  {
    "code": 77108,
    "zone": "A"
  },
  {
    "code": 77111,
    "zone": "A"
  },
  {
    "code": 77118,
    "zone": "A"
  },
  {
    "code": 77121,
    "zone": "A"
  },
  {
    "code": 77122,
    "zone": "A"
  },
  {
    "code": 77124,
    "zone": "A"
  },
  {
    "code": 77125,
    "zone": "A"
  },
  {
    "code": 77128,
    "zone": "A"
  },
  {
    "code": 77132,
    "zone": "A"
  },
  {
    "code": 77139,
    "zone": "A"
  },
  {
    "code": 77141,
    "zone": "A"
  },
  {
    "code": 77142,
    "zone": "A"
  },
  {
    "code": 77143,
    "zone": "A"
  },
  {
    "code": 77146,
    "zone": "A"
  },
  {
    "code": 77152,
    "zone": "A"
  },
  {
    "code": 77153,
    "zone": "A"
  },
  {
    "code": 77155,
    "zone": "A"
  },
  {
    "code": 77169,
    "zone": "A"
  },
  {
    "code": 77171,
    "zone": "A"
  },
  {
    "code": 77181,
    "zone": "A"
  },
  {
    "code": 77186,
    "zone": "A"
  },
  {
    "code": 77209,
    "zone": "A"
  },
  {
    "code": 77215,
    "zone": "A"
  },
  {
    "code": 77221,
    "zone": "A"
  },
  {
    "code": 77226,
    "zone": "A"
  },
  {
    "code": 77232,
    "zone": "A"
  },
  {
    "code": 77237,
    "zone": "A"
  },
  {
    "code": 77243,
    "zone": "A"
  },
  {
    "code": 77249,
    "zone": "A"
  },
  {
    "code": 77251,
    "zone": "A"
  },
  {
    "code": 77255,
    "zone": "A"
  },
  {
    "code": 77258,
    "zone": "A"
  },
  {
    "code": 77259,
    "zone": "A"
  },
  {
    "code": 77268,
    "zone": "A"
  },
  {
    "code": 77284,
    "zone": "A"
  },
  {
    "code": 77285,
    "zone": "A"
  },
  {
    "code": 77288,
    "zone": "A"
  },
  {
    "code": 77294,
    "zone": "A"
  },
  {
    "code": 77296,
    "zone": "A"
  },
  {
    "code": 77307,
    "zone": "A"
  },
  {
    "code": 77315,
    "zone": "A"
  },
  {
    "code": 77326,
    "zone": "A"
  },
  {
    "code": 77330,
    "zone": "A"
  },
  {
    "code": 77337,
    "zone": "A"
  },
  {
    "code": 77350,
    "zone": "A"
  },
  {
    "code": 77369,
    "zone": "A"
  },
  {
    "code": 77372,
    "zone": "A"
  },
  {
    "code": 77373,
    "zone": "A"
  },
  {
    "code": 77378,
    "zone": "A"
  },
  {
    "code": 77382,
    "zone": "A"
  },
  {
    "code": 77389,
    "zone": "A"
  },
  {
    "code": 77390,
    "zone": "A"
  },
  {
    "code": 77394,
    "zone": "A"
  },
  {
    "code": 77407,
    "zone": "A"
  },
  {
    "code": 77413,
    "zone": "A"
  },
  {
    "code": 77438,
    "zone": "A"
  },
  {
    "code": 77442,
    "zone": "A"
  },
  {
    "code": 77445,
    "zone": "A"
  },
  {
    "code": 77447,
    "zone": "A"
  },
  {
    "code": 77449,
    "zone": "A"
  },
  {
    "code": 77450,
    "zone": "A"
  },
  {
    "code": 77464,
    "zone": "A"
  },
  {
    "code": 77468,
    "zone": "A"
  },
  {
    "code": 77470,
    "zone": "A"
  },
  {
    "code": 77475,
    "zone": "A"
  },
  {
    "code": 77479,
    "zone": "A"
  },
  {
    "code": 77487,
    "zone": "A"
  },
  {
    "code": 77495,
    "zone": "A"
  },
  {
    "code": 77513,
    "zone": "A"
  },
  {
    "code": 77514,
    "zone": "A"
  },
  {
    "code": 77521,
    "zone": "A"
  },
  {
    "code": 77529,
    "zone": "A"
  },
  {
    "code": 77533,
    "zone": "A"
  },
  {
    "code": 78005,
    "zone": "A"
  },
  {
    "code": 78015,
    "zone": "A"
  },
  {
    "code": 78029,
    "zone": "A"
  },
  {
    "code": 78031,
    "zone": "A"
  },
  {
    "code": 78033,
    "zone": "A"
  },
  {
    "code": 78050,
    "zone": "A"
  },
  {
    "code": 78073,
    "zone": "A"
  },
  {
    "code": 78092,
    "zone": "A"
  },
  {
    "code": 78117,
    "zone": "A"
  },
  {
    "code": 78118,
    "zone": "A"
  },
  {
    "code": 78123,
    "zone": "A"
  },
  {
    "code": 78124,
    "zone": "A"
  },
  {
    "code": 78126,
    "zone": "A"
  },
  {
    "code": 78133,
    "zone": "A"
  },
  {
    "code": 78138,
    "zone": "A"
  },
  {
    "code": 78140,
    "zone": "A"
  },
  {
    "code": 78160,
    "zone": "A"
  },
  {
    "code": 78165,
    "zone": "A"
  },
  {
    "code": 78168,
    "zone": "A"
  },
  {
    "code": 78172,
    "zone": "A"
  },
  {
    "code": 78208,
    "zone": "A"
  },
  {
    "code": 78217,
    "zone": "A"
  },
  {
    "code": 78224,
    "zone": "A"
  },
  {
    "code": 78227,
    "zone": "A"
  },
  {
    "code": 78230,
    "zone": "A"
  },
  {
    "code": 78238,
    "zone": "A"
  },
  {
    "code": 78239,
    "zone": "A"
  },
  {
    "code": 78242,
    "zone": "A"
  },
  {
    "code": 78261,
    "zone": "A"
  },
  {
    "code": 78267,
    "zone": "A"
  },
  {
    "code": 78297,
    "zone": "A"
  },
  {
    "code": 78299,
    "zone": "A"
  },
  {
    "code": 78311,
    "zone": "A"
  },
  {
    "code": 78314,
    "zone": "A"
  },
  {
    "code": 78321,
    "zone": "A"
  },
  {
    "code": 78322,
    "zone": "A"
  },
  {
    "code": 78327,
    "zone": "A"
  },
  {
    "code": 78335,
    "zone": "A"
  },
  {
    "code": 78343,
    "zone": "A"
  },
  {
    "code": 78350,
    "zone": "A"
  },
  {
    "code": 78354,
    "zone": "A"
  },
  {
    "code": 78356,
    "zone": "A"
  },
  {
    "code": 78361,
    "zone": "A"
  },
  {
    "code": 78362,
    "zone": "A"
  },
  {
    "code": 78367,
    "zone": "A"
  },
  {
    "code": 78382,
    "zone": "A"
  },
  {
    "code": 78383,
    "zone": "A"
  },
  {
    "code": 78384,
    "zone": "A"
  },
  {
    "code": 78396,
    "zone": "A"
  },
  {
    "code": 78397,
    "zone": "A"
  },
  {
    "code": 78401,
    "zone": "A"
  },
  {
    "code": 78402,
    "zone": "A"
  },
  {
    "code": 78403,
    "zone": "A"
  },
  {
    "code": 78418,
    "zone": "A"
  },
  {
    "code": 78423,
    "zone": "A"
  },
  {
    "code": 78440,
    "zone": "A"
  },
  {
    "code": 78442,
    "zone": "A"
  },
  {
    "code": 78443,
    "zone": "A"
  },
  {
    "code": 78451,
    "zone": "A"
  },
  {
    "code": 78466,
    "zone": "A"
  },
  {
    "code": 78490,
    "zone": "A"
  },
  {
    "code": 78498,
    "zone": "A"
  },
  {
    "code": 78501,
    "zone": "A"
  },
  {
    "code": 78502,
    "zone": "A"
  },
  {
    "code": 78517,
    "zone": "A"
  },
  {
    "code": 78545,
    "zone": "A"
  },
  {
    "code": 78575,
    "zone": "A"
  },
  {
    "code": 78576,
    "zone": "A"
  },
  {
    "code": 78586,
    "zone": "A"
  },
  {
    "code": 78609,
    "zone": "A"
  },
  {
    "code": 78621,
    "zone": "A"
  },
  {
    "code": 78623,
    "zone": "A"
  },
  {
    "code": 78624,
    "zone": "A"
  },
  {
    "code": 78638,
    "zone": "A"
  },
  {
    "code": 78642,
    "zone": "A"
  },
  {
    "code": 78643,
    "zone": "A"
  },
  {
    "code": 78644,
    "zone": "A"
  },
  {
    "code": 78647,
    "zone": "A"
  },
  {
    "code": 78672,
    "zone": "A"
  },
  {
    "code": 78674,
    "zone": "A"
  },
  {
    "code": 78683,
    "zone": "A"
  },
  {
    "code": 78688,
    "zone": "A"
  },
  {
    "code": 83001,
    "zone": "A"
  },
  {
    "code": 83009,
    "zone": "A"
  },
  {
    "code": 83016,
    "zone": "A"
  },
  {
    "code": 83017,
    "zone": "A"
  },
  {
    "code": 83019,
    "zone": "A"
  },
  {
    "code": 83027,
    "zone": "A"
  },
  {
    "code": 83034,
    "zone": "A"
  },
  {
    "code": 83035,
    "zone": "A"
  },
  {
    "code": 83036,
    "zone": "A"
  },
  {
    "code": 83042,
    "zone": "A"
  },
  {
    "code": 83047,
    "zone": "A"
  },
  {
    "code": 83048,
    "zone": "A"
  },
  {
    "code": 83049,
    "zone": "A"
  },
  {
    "code": 83053,
    "zone": "A"
  },
  {
    "code": 83054,
    "zone": "A"
  },
  {
    "code": 83061,
    "zone": "A"
  },
  {
    "code": 83062,
    "zone": "A"
  },
  {
    "code": 83065,
    "zone": "A"
  },
  {
    "code": 83068,
    "zone": "A"
  },
  {
    "code": 83069,
    "zone": "A"
  },
  {
    "code": 83070,
    "zone": "A"
  },
  {
    "code": 83071,
    "zone": "A"
  },
  {
    "code": 83090,
    "zone": "A"
  },
  {
    "code": 83098,
    "zone": "A"
  },
  {
    "code": 83099,
    "zone": "A"
  },
  {
    "code": 83101,
    "zone": "A"
  },
  {
    "code": 83103,
    "zone": "A"
  },
  {
    "code": 83107,
    "zone": "A"
  },
  {
    "code": 83112,
    "zone": "A"
  },
  {
    "code": 83115,
    "zone": "A"
  },
  {
    "code": 83118,
    "zone": "A"
  },
  {
    "code": 83119,
    "zone": "A"
  },
  {
    "code": 83120,
    "zone": "A"
  },
  {
    "code": 83123,
    "zone": "A"
  },
  {
    "code": 83126,
    "zone": "A"
  },
  {
    "code": 83129,
    "zone": "A"
  },
  {
    "code": 83130,
    "zone": "A"
  },
  {
    "code": 83131,
    "zone": "A"
  },
  {
    "code": 83132,
    "zone": "A"
  },
  {
    "code": 83137,
    "zone": "A"
  },
  {
    "code": 83144,
    "zone": "A"
  },
  {
    "code": 83152,
    "zone": "A"
  },
  {
    "code": 83153,
    "zone": "A"
  },
  {
    "code": 91021,
    "zone": "A"
  },
  {
    "code": 91027,
    "zone": "A"
  },
  {
    "code": 91044,
    "zone": "A"
  },
  {
    "code": 91064,
    "zone": "A"
  },
  {
    "code": 91086,
    "zone": "A"
  },
  {
    "code": 91097,
    "zone": "A"
  },
  {
    "code": 91103,
    "zone": "A"
  },
  {
    "code": 91105,
    "zone": "A"
  },
  {
    "code": 91106,
    "zone": "A"
  },
  {
    "code": 91114,
    "zone": "A"
  },
  {
    "code": 91115,
    "zone": "A"
  },
  {
    "code": 91122,
    "zone": "A"
  },
  {
    "code": 91136,
    "zone": "A"
  },
  {
    "code": 91161,
    "zone": "A"
  },
  {
    "code": 91174,
    "zone": "A"
  },
  {
    "code": 91179,
    "zone": "A"
  },
  {
    "code": 91191,
    "zone": "A"
  },
  {
    "code": 91201,
    "zone": "A"
  },
  {
    "code": 91204,
    "zone": "A"
  },
  {
    "code": 91207,
    "zone": "A"
  },
  {
    "code": 91215,
    "zone": "A"
  },
  {
    "code": 91216,
    "zone": "A"
  },
  {
    "code": 91225,
    "zone": "A"
  },
  {
    "code": 91228,
    "zone": "A"
  },
  {
    "code": 91235,
    "zone": "A"
  },
  {
    "code": 91244,
    "zone": "A"
  },
  {
    "code": 91249,
    "zone": "A"
  },
  {
    "code": 91272,
    "zone": "A"
  },
  {
    "code": 91275,
    "zone": "A"
  },
  {
    "code": 91286,
    "zone": "A"
  },
  {
    "code": 91312,
    "zone": "A"
  },
  {
    "code": 91326,
    "zone": "A"
  },
  {
    "code": 91333,
    "zone": "A"
  },
  {
    "code": 91338,
    "zone": "A"
  },
  {
    "code": 91339,
    "zone": "A"
  },
  {
    "code": 91340,
    "zone": "A"
  },
  {
    "code": 91345,
    "zone": "A"
  },
  {
    "code": 91347,
    "zone": "A"
  },
  {
    "code": 91363,
    "zone": "A"
  },
  {
    "code": 91377,
    "zone": "A"
  },
  {
    "code": 91386,
    "zone": "A"
  },
  {
    "code": 91421,
    "zone": "A"
  },
  {
    "code": 91425,
    "zone": "A"
  },
  {
    "code": 91432,
    "zone": "A"
  },
  {
    "code": 91434,
    "zone": "A"
  },
  {
    "code": 91435,
    "zone": "A"
  },
  {
    "code": 91457,
    "zone": "A"
  },
  {
    "code": 91458,
    "zone": "A"
  },
  {
    "code": 91461,
    "zone": "A"
  },
  {
    "code": 91468,
    "zone": "A"
  },
  {
    "code": 91471,
    "zone": "A"
  },
  {
    "code": 91477,
    "zone": "A"
  },
  {
    "code": 91479,
    "zone": "A"
  },
  {
    "code": 91494,
    "zone": "A"
  },
  {
    "code": 91514,
    "zone": "A"
  },
  {
    "code": 91521,
    "zone": "A"
  },
  {
    "code": 91534,
    "zone": "A"
  },
  {
    "code": 91538,
    "zone": "A"
  },
  {
    "code": 91549,
    "zone": "A"
  },
  {
    "code": 91552,
    "zone": "A"
  },
  {
    "code": 91553,
    "zone": "A"
  },
  {
    "code": 91570,
    "zone": "A"
  },
  {
    "code": 91573,
    "zone": "A"
  },
  {
    "code": 91577,
    "zone": "A"
  },
  {
    "code": 91581,
    "zone": "A"
  },
  {
    "code": 91587,
    "zone": "A"
  },
  {
    "code": 91589,
    "zone": "A"
  },
  {
    "code": 91600,
    "zone": "A"
  },
  {
    "code": 91617,
    "zone": "A"
  },
  {
    "code": 91631,
    "zone": "A"
  },
  {
    "code": 91635,
    "zone": "A"
  },
  {
    "code": 91645,
    "zone": "A"
  },
  {
    "code": 91657,
    "zone": "A"
  },
  {
    "code": 91659,
    "zone": "A"
  },
  {
    "code": 91661,
    "zone": "A"
  },
  {
    "code": 91665,
    "zone": "A"
  },
  {
    "code": 91666,
    "zone": "A"
  },
  {
    "code": 91667,
    "zone": "A"
  },
  {
    "code": 91679,
    "zone": "A"
  },
  {
    "code": 91685,
    "zone": "A"
  },
  {
    "code": 91687,
    "zone": "A"
  },
  {
    "code": 91689,
    "zone": "A"
  },
  {
    "code": 91691,
    "zone": "A"
  },
  {
    "code": 91692,
    "zone": "A"
  },
  {
    "code": 92036,
    "zone": "A"
  },
  {
    "code": 92078,
    "zone": "A"
  },
  {
    "code": 93005,
    "zone": "A"
  },
  {
    "code": 93007,
    "zone": "A"
  },
  {
    "code": 93008,
    "zone": "A"
  },
  {
    "code": 93010,
    "zone": "A"
  },
  {
    "code": 93013,
    "zone": "A"
  },
  {
    "code": 93014,
    "zone": "A"
  },
  {
    "code": 93015,
    "zone": "A"
  },
  {
    "code": 93027,
    "zone": "A"
  },
  {
    "code": 93029,
    "zone": "A"
  },
  {
    "code": 93030,
    "zone": "A"
  },
  {
    "code": 93031,
    "zone": "A"
  },
  {
    "code": 93032,
    "zone": "A"
  },
  {
    "code": 93033,
    "zone": "A"
  },
  {
    "code": 93039,
    "zone": "A"
  },
  {
    "code": 93046,
    "zone": "A"
  },
  {
    "code": 93047,
    "zone": "A"
  },
  {
    "code": 93050,
    "zone": "A"
  },
  {
    "code": 93051,
    "zone": "A"
  },
  {
    "code": 93053,
    "zone": "A"
  },
  {
    "code": 93057,
    "zone": "A"
  },
  {
    "code": 93059,
    "zone": "A"
  },
  {
    "code": 93063,
    "zone": "A"
  },
  {
    "code": 93064,
    "zone": "A"
  },
  {
    "code": 93071,
    "zone": "A"
  },
  {
    "code": 93072,
    "zone": "A"
  },
  {
    "code": 93073,
    "zone": "A"
  },
  {
    "code": 93074,
    "zone": "A"
  },
  {
    "code": 93078,
    "zone": "A"
  },
  {
    "code": 93079,
    "zone": "A"
  },
  {
    "code": 94001,
    "zone": "A"
  },
  {
    "code": 94002,
    "zone": "A"
  },
  {
    "code": 94004,
    "zone": "A"
  },
  {
    "code": 94011,
    "zone": "A"
  },
  {
    "code": 94017,
    "zone": "A"
  },
  {
    "code": 94019,
    "zone": "A"
  },
  {
    "code": 94021,
    "zone": "A"
  },
  {
    "code": 94022,
    "zone": "A"
  },
  {
    "code": 94028,
    "zone": "A"
  },
  {
    "code": 94034,
    "zone": "A"
  },
  {
    "code": 94044,
    "zone": "A"
  },
  {
    "code": 94047,
    "zone": "A"
  },
  {
    "code": 94048,
    "zone": "A"
  },
  {
    "code": 94053,
    "zone": "A"
  },
  {
    "code": 94054,
    "zone": "A"
  },
  {
    "code": 94055,
    "zone": "A"
  },
  {
    "code": 94056,
    "zone": "A"
  },
  {
    "code": 94059,
    "zone": "A"
  },
  {
    "code": 94060,
    "zone": "A"
  },
  {
    "code": 94065,
    "zone": "A"
  },
  {
    "code": 94070,
    "zone": "A"
  },
  {
    "code": 94071,
    "zone": "A"
  },
  {
    "code": 94073,
    "zone": "A"
  },
  {
    "code": 94074,
    "zone": "A"
  },
  {
    "code": 94075,
    "zone": "A"
  },
  {
    "code": 94077,
    "zone": "A"
  },
  {
    "code": 94078,
    "zone": "A"
  },
  {
    "code": 94079,
    "zone": "A"
  },
  {
    "code": 94081,
    "zone": "A"
  },
  {
    "code": 95014,
    "zone": "A"
  },
  {
    "code": 95018,
    "zone": "A"
  },
  {
    "code": 95019,
    "zone": "A"
  },
  {
    "code": 95039,
    "zone": "A"
  },
  {
    "code": 95051,
    "zone": "A"
  },
  {
    "code": 95052,
    "zone": "A"
  },
  {
    "code": 95058,
    "zone": "A"
  },
  {
    "code": 95060,
    "zone": "A"
  },
  {
    "code": 95063,
    "zone": "A"
  },
  {
    "code": 95074,
    "zone": "A"
  },
  {
    "code": 95088,
    "zone": "A"
  },
  {
    "code": 95091,
    "zone": "A"
  },
  {
    "code": 95120,
    "zone": "A"
  },
  {
    "code": 95127,
    "zone": "A"
  },
  {
    "code": 95134,
    "zone": "A"
  },
  {
    "code": 95176,
    "zone": "A"
  },
  {
    "code": 95183,
    "zone": "A"
  },
  {
    "code": 95197,
    "zone": "A"
  },
  {
    "code": 95199,
    "zone": "A"
  },
  {
    "code": 95203,
    "zone": "A"
  },
  {
    "code": 95205,
    "zone": "A"
  },
  {
    "code": 95212,
    "zone": "A"
  },
  {
    "code": 95218,
    "zone": "A"
  },
  {
    "code": 95219,
    "zone": "A"
  },
  {
    "code": 95229,
    "zone": "A"
  },
  {
    "code": 95252,
    "zone": "A"
  },
  {
    "code": 95256,
    "zone": "A"
  },
  {
    "code": 95257,
    "zone": "A"
  },
  {
    "code": 95268,
    "zone": "A"
  },
  {
    "code": 95277,
    "zone": "A"
  },
  {
    "code": 95280,
    "zone": "A"
  },
  {
    "code": 95288,
    "zone": "A"
  },
  {
    "code": 95306,
    "zone": "A"
  },
  {
    "code": 95313,
    "zone": "A"
  },
  {
    "code": 95323,
    "zone": "A"
  },
  {
    "code": 95369,
    "zone": "A"
  },
  {
    "code": 95388,
    "zone": "A"
  },
  {
    "code": 95392,
    "zone": "A"
  },
  {
    "code": 95394,
    "zone": "A"
  },
  {
    "code": 95424,
    "zone": "A"
  },
  {
    "code": 95426,
    "zone": "A"
  },
  {
    "code": 95427,
    "zone": "A"
  },
  {
    "code": 95428,
    "zone": "A"
  },
  {
    "code": 95436,
    "zone": "A"
  },
  {
    "code": 95446,
    "zone": "A"
  },
  {
    "code": 95450,
    "zone": "A"
  },
  {
    "code": 95476,
    "zone": "A"
  },
  {
    "code": 95480,
    "zone": "A"
  },
  {
    "code": 95487,
    "zone": "A"
  },
  {
    "code": 95488,
    "zone": "A"
  },
  {
    "code": 95489,
    "zone": "A"
  },
  {
    "code": 95491,
    "zone": "A"
  },
  {
    "code": 95500,
    "zone": "A"
  },
  {
    "code": 95510,
    "zone": "A"
  },
  {
    "code": 95527,
    "zone": "A"
  },
  {
    "code": 95529,
    "zone": "A"
  },
  {
    "code": 95539,
    "zone": "A"
  },
  {
    "code": 95555,
    "zone": "A"
  },
  {
    "code": 95563,
    "zone": "A"
  },
  {
    "code": 95572,
    "zone": "A"
  },
  {
    "code": 95574,
    "zone": "A"
  },
  {
    "code": 95582,
    "zone": "A"
  },
  {
    "code": 95585,
    "zone": "A"
  },
  {
    "code": 95598,
    "zone": "A"
  },
  {
    "code": 95607,
    "zone": "A"
  },
  {
    "code": 95612,
    "zone": "A"
  },
  {
    "code": 95628,
    "zone": "A"
  },
  {
    "code": 95633,
    "zone": "A"
  },
  {
    "code": 95637,
    "zone": "A"
  },
  {
    "code": 95678,
    "zone": "A"
  },
  {
    "code": 95680,
    "zone": "A"
  },
  {
    "code": 75056,
    "zone": "Abis"
  },
  {
    "code": 78146,
    "zone": "Abis"
  },
  {
    "code": 78158,
    "zone": "Abis"
  },
  {
    "code": 78190,
    "zone": "Abis"
  },
  {
    "code": 78358,
    "zone": "Abis"
  },
  {
    "code": 78372,
    "zone": "Abis"
  },
  {
    "code": 78481,
    "zone": "Abis"
  },
  {
    "code": 78551,
    "zone": "Abis"
  },
  {
    "code": 78640,
    "zone": "Abis"
  },
  {
    "code": 78646,
    "zone": "Abis"
  },
  {
    "code": 78650,
    "zone": "Abis"
  },
  {
    "code": 78686,
    "zone": "Abis"
  },
  {
    "code": 92002,
    "zone": "Abis"
  },
  {
    "code": 92004,
    "zone": "Abis"
  },
  {
    "code": 92007,
    "zone": "Abis"
  },
  {
    "code": 92009,
    "zone": "Abis"
  },
  {
    "code": 92012,
    "zone": "Abis"
  },
  {
    "code": 92014,
    "zone": "Abis"
  },
  {
    "code": 92019,
    "zone": "Abis"
  },
  {
    "code": 92020,
    "zone": "Abis"
  },
  {
    "code": 92022,
    "zone": "Abis"
  },
  {
    "code": 92023,
    "zone": "Abis"
  },
  {
    "code": 92024,
    "zone": "Abis"
  },
  {
    "code": 92025,
    "zone": "Abis"
  },
  {
    "code": 92026,
    "zone": "Abis"
  },
  {
    "code": 92032,
    "zone": "Abis"
  },
  {
    "code": 92033,
    "zone": "Abis"
  },
  {
    "code": 92035,
    "zone": "Abis"
  },
  {
    "code": 92040,
    "zone": "Abis"
  },
  {
    "code": 92044,
    "zone": "Abis"
  },
  {
    "code": 92046,
    "zone": "Abis"
  },
  {
    "code": 92047,
    "zone": "Abis"
  },
  {
    "code": 92048,
    "zone": "Abis"
  },
  {
    "code": 92049,
    "zone": "Abis"
  },
  {
    "code": 92050,
    "zone": "Abis"
  },
  {
    "code": 92051,
    "zone": "Abis"
  },
  {
    "code": 92060,
    "zone": "Abis"
  },
  {
    "code": 92062,
    "zone": "Abis"
  },
  {
    "code": 92063,
    "zone": "Abis"
  },
  {
    "code": 92064,
    "zone": "Abis"
  },
  {
    "code": 92071,
    "zone": "Abis"
  },
  {
    "code": 92072,
    "zone": "Abis"
  },
  {
    "code": 92073,
    "zone": "Abis"
  },
  {
    "code": 92075,
    "zone": "Abis"
  },
  {
    "code": 92076,
    "zone": "Abis"
  },
  {
    "code": 92077,
    "zone": "Abis"
  },
  {
    "code": 93001,
    "zone": "Abis"
  },
  {
    "code": 93006,
    "zone": "Abis"
  },
  {
    "code": 93045,
    "zone": "Abis"
  },
  {
    "code": 93048,
    "zone": "Abis"
  },
  {
    "code": 93049,
    "zone": "Abis"
  },
  {
    "code": 93055,
    "zone": "Abis"
  },
  {
    "code": 93061,
    "zone": "Abis"
  },
  {
    "code": 93062,
    "zone": "Abis"
  },
  {
    "code": 93066,
    "zone": "Abis"
  },
  {
    "code": 93070,
    "zone": "Abis"
  },
  {
    "code": 93077,
    "zone": "Abis"
  },
  {
    "code": 94003,
    "zone": "Abis"
  },
  {
    "code": 94015,
    "zone": "Abis"
  },
  {
    "code": 94016,
    "zone": "Abis"
  },
  {
    "code": 94018,
    "zone": "Abis"
  },
  {
    "code": 94033,
    "zone": "Abis"
  },
  {
    "code": 94037,
    "zone": "Abis"
  },
  {
    "code": 94038,
    "zone": "Abis"
  },
  {
    "code": 94041,
    "zone": "Abis"
  },
  {
    "code": 94042,
    "zone": "Abis"
  },
  {
    "code": 94043,
    "zone": "Abis"
  },
  {
    "code": 94046,
    "zone": "Abis"
  },
  {
    "code": 94052,
    "zone": "Abis"
  },
  {
    "code": 94058,
    "zone": "Abis"
  },
  {
    "code": 94067,
    "zone": "Abis"
  },
  {
    "code": 94068,
    "zone": "Abis"
  },
  {
    "code": 94069,
    "zone": "Abis"
  },
  {
    "code": 94076,
    "zone": "Abis"
  },
  {
    "code": 94080,
    "zone": "Abis"
  },
  {
    "code": 95210,
    "zone": "Abis"
  }
]

export default pinelData
